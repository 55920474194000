import { find, filter, uniqBy } from "lodash";
import {
  win_conditions,
  compare_price_withLocaleObject,
  compare_grouping,
  mapProductSpecsForComparison
} from "../helpers/WinLogic";
import jsonLogic from "../helpers/CustomJSONLogic";
import { localizeNumber } from "../helpers/Functions";
import {
  prepareJSONLogicData,
  validateTCOSavingsLogicData
} from "../helpers/TCO";

const MIN_CURRENCY_VALUE = 0.01;

/**
 * Returns true if a is similar to b
 * Parameters:
 * - a: float
 * - b: float
 * - similarity: float
 */
const default_similarity = 1;
function valueIsSimilar(a, b, similarity = default_similarity) {
  return Math.abs(b - a) <= similarity;
}

function findHighest(list) {
  return list.reduce(
    (acc, next) => (next > acc ? next : acc),
    Number.MIN_SAFE_INTEGER
  );
}

function closestVaLueinObjectList(list, target_value) {
  return list.reduce((acc, next) => {
    if (Math.abs(target_value - next) <= Math.abs(target_value - acc))
      return next;
    return acc;
  }, Number.MAX_SAFE_INTEGER);
}

const number_of_years = 3;
function collectGraphData_TCO(comparison_data, audience_key, selectedLocale) {
  const { primary_product, secondary_products } = comparison_data;
  const all_products = [primary_product, ...secondary_products].filter(
    (p) => !!p
  );

  // Determine which CPP feature to use
  const cpp_color_selected = !!find(
    comparison_data.attributes ||
      comparison_data.selected_features(audience_key),
    (feature) => feature.name.indexOf("CPP (Color)") > -1
  );
  const tco_key = cpp_color_selected ? "tco_color" : "tco_black";

  let graph_data = [
    { name: "" },
    { name: "Year 1" },
    { name: "Year 2" },
    { name: "Year 3" }
  ];

  let seen_msrps = [];
  let seen_tcos = [];
  let closest_msrp_counts = {};
  let closest_tco_counts = {};

  let products_data = all_products
    .map((product, product_idx) => {
      // Get MSRPs and TCOs
      const prices = product.getLocalized("prices", selectedLocale?.language);
      let msrp = prices?.[0]?.amount;
      let tco = product.getLocalized(tco_key, selectedLocale?.language);
      // Pricing not set
      if (isNaN(parseFloat(msrp)) || isNaN(parseFloat(tco))) return {};

      if (!msrp || !tco) return {};
      return { msrp: msrp, tco: tco };
    })
    .filter((v) => !!v);

  if (products_data.length === 0 || products_data.every(({ tco }) => !tco))
    return null;

  const highest_tco = findHighest(products_data.map((product) => product.tco));

  const tco_match_amount = highest_tco * 0.02;
  products_data.forEach((product, product_idx) => {
    let { msrp, tco } = product;
    if (!msrp || !tco) return;

    // Offset MSRP and TCO if values already seen
    if (product_idx > 0) {
      const reversed_seen_msrps = [...seen_msrps].reverse();
      const reversed_seen_tcos = [...seen_tcos].reverse();

      let prev_matching_msrp = 0;
      let prev_matching_tco = 0;
      const closest_matching_msrp = closestVaLueinObjectList(seen_msrps, msrp);
      if (
        valueIsSimilar(
          msrp,
          closest_matching_msrp,
          tco_match_amount + default_similarity
        )
      )
        prev_matching_msrp = closest_matching_msrp;

      const closest_matching_tco = closestVaLueinObjectList(seen_tcos, tco);
      if (
        valueIsSimilar(
          tco,
          closest_matching_tco,
          tco_match_amount + default_similarity
        )
      )
        prev_matching_tco = closest_matching_tco;

      if (!closest_msrp_counts[prev_matching_msrp])
        closest_msrp_counts[prev_matching_msrp] = 0;
      if (!closest_tco_counts[prev_matching_tco])
        closest_tco_counts[prev_matching_tco] = 0;
      closest_msrp_counts[prev_matching_msrp] += 1;
      closest_tco_counts[prev_matching_tco] += 1;

      if (prev_matching_msrp > 0 && prev_matching_tco > 0) {
        msrp =
          prev_matching_msrp +
          tco_match_amount * closest_msrp_counts[prev_matching_msrp];
        tco =
          prev_matching_tco +
          tco_match_amount * closest_tco_counts[prev_matching_tco];
      }
    }

    // Bookkeeping
    seen_msrps.push(msrp);
    seen_tcos.push(tco);

    let slope = (tco - msrp) / number_of_years;

    graph_data[0][product_idx] = msrp;

    // Year 1
    graph_data[1][product_idx] = msrp + slope;

    // Year 2
    graph_data[2][product_idx] = msrp + slope * 2;

    // Year 3 (TCO)
    graph_data[3][product_idx] = tco;
  });

  return graph_data;
}

function getRoundedImagesPerDayFromSpeed(table, target_speed) {
  const max_speed = table[table.length - 1][0];
  const min_speed = table[0][0];
  let element = table[0];
  if (target_speed <= min_speed) {
    // element = table[0];
  } else if (target_speed >= max_speed) {
    element = table[table.length - 1];
  } else {
    element = table.find(
      ([
        speed,
        jobs_day,
        unrounded_images_job,
        rounded_images_job,
        rounded_images_day
      ]) => speed === target_speed
    );
  }

  // Return rounded images/day, the last column
  return element[element.length - 1];
}
function collectGraphData_TEC(comparison_data, audience_key, selectedLocale) {
  const { primary_product, secondary_products } = comparison_data;
  const all_products = [primary_product, ...secondary_products];
  const { images_per_day_table } = comparison_data;
  // We will use TEC (kWh/week) and maximum print speed (ppm)

  let graph_data = [
    {
      name: ""
    },
    {
      name: "Year 1"
    },
    {
      name: "Year 2"
    },
    {
      name: "Year 3"
    }
  ];

  let seen_tecs_per_page = [];
  let seen_final_tecs = [];
  let closest_tecs_per_page_counts = {};
  let closest_final_tec_counts = {};

  const getProductTECPerPage = (tec, speed, images_per_day_table) => {
    const pages_per_day = getRoundedImagesPerDayFromSpeed(
      images_per_day_table,
      speed
    );
    // 5 days per workweek
    const pages_per_week = pages_per_day * 5;

    // in kWh
    return tec / pages_per_week;
  };

  const getProductFinalTEC = (tec_per_page, mpv, months) => {
    return tec_per_page * mpv * months;
  };

  // TODO: find a better home for the following constants
  const mpv = 500;
  const months = 36;
  const tec_per_page_similarity = 0.00001;

  const unfiltered_products_data = all_products.map((product, product_idx) => {
    // Get TEC and Maximum print speed
    const tec_feature = product
      .getLocalized("features_and_specifications", selectedLocale.language)
      .find(({ specification: { id } }) => id === 25);
    const speed_features = product
      .getLocalized("features_and_specifications", selectedLocale.language)
      .filter(({ specification: { id } }) => id === 12);
    const max_speed_value = Math.max(
      ...speed_features.map(({ specification_numeric_value: v }) => v)
    );

    // monthly print volume
    // const mpv_feature = product.features.find(
    //   ({ specification: { id } }) => id === 6
    // );

    const tec_value = tec_feature?.specification_numeric_value;
    // const mpv_value = parseFloat((mpv_feature?.choice || '').replace(/[^0-9]/, ''));
    const invalid_speed =
      isNaN(max_speed_value) ||
      max_speed_value === 0 ||
      !isFinite(max_speed_value);

    if (isNaN(tec_value) || invalid_speed /* || isNaN(mpv_value)*/) {
      return null;
    }

    return {
      tec: tec_value,
      speed: max_speed_value
      // mpv:mpv_value
    };
  });

  // Primary product has no value
  if (!unfiltered_products_data[0]) return null;

  const products_data = unfiltered_products_data.filter((v) => !!v);

  if (products_data.length === 0) return null;

  const tecs_per_page = products_data.map(({ tec, speed }) => {
    return getProductTECPerPage(tec, speed, images_per_day_table);
  });

  const highest_tec = findHighest(tecs_per_page);

  const highest_tec_offset_amount = highest_tec * 0.02;

  const highest_final_tec = findHighest(
    tecs_per_page.map((tec_per_page) =>
      getProductFinalTEC(tec_per_page, mpv, months)
    )
  );
  const final_tec_match_amount = highest_final_tec * 0.02;

  products_data.forEach((product, product_idx) => {
    let { tec, speed /*, mpv*/ } = product;
    if (!tec || !speed /* || !mpv*/) return;

    // in kWh
    let tec_per_page = getProductTECPerPage(tec, speed, images_per_day_table);

    let final_tec = getProductFinalTEC(tec_per_page, mpv, months);

    let tec_offset = 0;

    // Lookup pages per day from table, based on speed, and get rounded images/day
    // Columns: [Speed, Jobs/Day, Unrounded Images/Job, Rounded Images/Job, Rounded Images/day]

    // Offset tec_per_page and final_tec if values too close to a previous one
    if (product_idx > 0) {
      const reversed_seen_tecs_per_page = [...seen_tecs_per_page].reverse();
      const reversed_seen_final_tecs = [...seen_final_tecs].reverse();

      let prev_matching_tec_per_page = 0;
      let prev_matching_final_tec = 0;
      const closest_matching_tec_per_page = closestVaLueinObjectList(
        seen_tecs_per_page,
        tec_per_page
      );
      if (
        valueIsSimilar(
          tec_per_page,
          closest_matching_tec_per_page,
          tec_per_page_similarity
        )
      )
        prev_matching_tec_per_page = closest_matching_tec_per_page;

      const closest_matching_final_tec = closestVaLueinObjectList(
        seen_final_tecs,
        final_tec
      );
      if (
        valueIsSimilar(
          final_tec,
          closest_matching_final_tec,
          final_tec_match_amount
        )
      )
        prev_matching_final_tec = closest_matching_final_tec;

      if (!closest_tecs_per_page_counts[prev_matching_tec_per_page])
        closest_tecs_per_page_counts[prev_matching_tec_per_page] = 0;
      if (!closest_final_tec_counts[prev_matching_final_tec])
        closest_final_tec_counts[prev_matching_final_tec] = 0;
      closest_tecs_per_page_counts[prev_matching_tec_per_page] += 1;
      closest_final_tec_counts[prev_matching_final_tec] += 1;

      if (prev_matching_tec_per_page > 0 && prev_matching_final_tec > 0) {
        tec_offset =
          final_tec_match_amount *
          closest_final_tec_counts[prev_matching_final_tec];
      }
    }

    // Bookkeeping
    seen_tecs_per_page.push(tec_per_page);
    seen_final_tecs.push(final_tec);

    // let slope = (final_tec - tec_per_page) / number_of_years;

    graph_data[0][product_idx] = tec_per_page * mpv * 1 + tec_offset;

    // // Year 1
    graph_data[1][product_idx] = tec_per_page * (mpv * 12) + tec_offset;

    // // Year 2
    // graph_data[2][product_idx] = tec_per_page + slope * 2;
    graph_data[2][product_idx] = tec_per_page * (mpv * 24) + tec_offset;

    // // Year 3 (final_tec)
    // graph_data[3][product_idx] = final_tec;
    graph_data[3][product_idx] = final_tec + tec_offset;
  });

  return graph_data;
}

/**
 * Collects audienced comparison data.
 *
 * type - either "tec" or "tco"
 */
export function collectGraphData(
  comparison_data,
  audience_key,
  selectedLocale,
  type = "tco"
) {
  switch (type) {
    case "tec":
      return collectGraphData_TEC(
        comparison_data,
        audience_key,
        selectedLocale
      );

    case "tco":
      return collectGraphData_TCO(
        comparison_data,
        audience_key,
        selectedLocale
      );

    default:
      console.error(`Unrecognized graph type: ${type}`);
      return null;
  }
}

const buildHighlightable = (value, class_name = "") => {
  if (!value) return "";
  const class_name_str = `class="${class_name} highlight-target"`;
  return `<span ${class_name_str}>${value}</span>`;
};

let buildString = (single_facet, previous_facet_highlighted) => {
  if (single_facet.force_render_value) return single_facet.value;

  const value_style = single_facet.value_style || "regular";
  const unit_style = single_facet.unit_style || "regular";
  const facet_style = single_facet.facet_style || "regular";
  // Prepare value
  // let formatted_value = `<span class="${value_style} highlight-target">${single_facet.value}</span>`;
  let formatted_value = buildHighlightable(single_facet.value, value_style);

  // Prepare unit with separator
  if (single_facet.unit) {
    const unit_separator = buildHighlightable(single_facet.unit_separator);
    formatted_value += `${single_facet.unit_separator}<span class="${unit_style} highlight-target">${single_facet.unit}</span>`;
  }

  if (single_facet.facet) {
    const facet_display = buildHighlightable(single_facet.facet, facet_style);
    const facet_separator = buildHighlightable(single_facet.facet_separator);
    switch (single_facet.facet_placement) {
      case "before":
        formatted_value = `${facet_display}${facet_separator}${formatted_value}`;
        break;
      case "after":
        formatted_value = `${formatted_value}${facet_separator}${facet_display}`;
        break;

      case "hidden":
      default:
    }
  }

  return formatted_value;
};
function getDisclaimerSuperscripts(disclaimers, disclaimer_processor) {
  // Retain valid values
  return disclaimers
    .filter((d) => !!d)
    .map((d) => disclaimer_processor.processDisclaimer(d));
}

// Format product values into spans with classnames coming from database
export function formatTableData(table, disclaimer_processor) {
  table.rows.forEach((row, idx_row) => {
    const { feature = {}, win_logic } = row;
    const highlight_as_group = win_logic?.match_facets === "as-group";
    let collected_facet_disclaimers = [];

    // Column values
    row.values = row.values.map((cell, idx_cell) => {
      // Highlight all attributes as a group if at least one matches
      const at_least_one_match = cell.some((c) => c.highlight);
      const force_highlight = highlight_as_group && at_least_one_match;
      const separator = cell?.[0]?.value_separator;
      // Format table cell values
      const cell_values = cell.map((individual_value_facet, idx_facet) => {
        const facet_value = buildString(individual_value_facet);

        const should_highlight =
          individual_value_facet.highlight || force_highlight;

        return {
          value: facet_value,
          highlight: should_highlight,
          disclaimer: individual_value_facet.disclaimer
        };
      });

      // Intersperse separators with appropriate highlighting
      let cell_values_separated = [];
      for (var i = 0; i < cell_values.length; ++i) {
        if (i > 0) {
          // highlight separator based on surrounding highlighting
          const prev_highlighted = cell_values[i - 1].highlight;
          const next_highlighted = cell_values[i].highlight;
          const highlight_separator = prev_highlighted && next_highlighted;
          let separator_obj = {
            value: "",
            highlight: highlight_separator,
            disclaimer: []
          };
          if (highlight_separator)
            separator_obj.value = buildHighlightable(separator);
          else separator_obj.value = separator;

          cell_values_separated.push(separator_obj);
        }
        cell_values_separated.push(cell_values[i]);
      }

      return cell_values_separated;
      // end for each cell's value
    });
    // end for each column
  });
  // end for each row
}

function uniqueDisclaimers(obj, idx, self) {
  return self.indexOf(obj) === idx;
}

export function makeTableCellValue(
  value,
  disclaimer,
  unit,
  facet,
  feature,
  highlight_value,
  selected_locale
) {
  const { id, place_symbol_after_each_spec, place_symbol_after_all_specs } =
    feature;

  const value_separator =
    feature?.getLocalized?.("value_separator", selected_locale) || "";
  const unit_separator =
    feature?.getLocalized?.("unit_separator", selected_locale) || "";
  const facet_separator =
    feature?.getLocalized?.("facet_separator", selected_locale) || "";
  const facet_placement =
    feature?.getLocalized?.("facet_placement", selected_locale) || "";
  const value_style =
    feature?.getLocalized?.("value_style", selected_locale) || "";
  const unit_style =
    feature?.getLocalized?.("unit_style", selected_locale) || "";
  const facet_style =
    feature?.getLocalized?.("facet_style", selected_locale) || "";

  let disclaimer_array = [];

  if (place_symbol_after_each_spec || place_symbol_after_all_specs) {
    if (Array.isArray(disclaimer)) {
      disclaimer_array = disclaimer;
    }
    // Convert single disclaimer to array
    else if (!!disclaimer) {
      disclaimer_array = [disclaimer];
    }
  }
  // Use unique values
  disclaimer_array = disclaimer_array.filter(uniqueDisclaimers);

  let cellValue = {
    specification: feature,
    value,
    disclaimer: disclaimer_array,
    unit,
    facet,
    value_separator,
    unit_separator,
    facet_separator,
    facet_placement,

    value_style: value_style?.value,
    unit_style: unit_style?.value,
    facet_style: facet_style?.value,

    highlight_value
  };
  return cellValue;
}

const emptyPlaceholder = "—";

export function collectTableData(
  comparison_data,
  selectedLocale,
  collections,
  audience_key
) {
  const { primary_product, secondary_products } = comparison_data;
  const all_products = [primary_product, ...secondary_products];
  const download_spec_sheet_text = collections.global_settings.getLocalized(
    "download_spec_sheet",
    selectedLocale.language
  );
  const selected_locale =
    selectedLocale || collections.global_settings.default_locale;
  return {
    headers: all_products.map(
      (product) =>
        `${product.brand?.brand} ${product.model} ${product.variant || ""}`
    ),
    thumbnails: all_products.map((product) => product.image_thumbnail_url),
    short_descriptions: collectTableProductShortDescriptions(
      all_products,
      selected_locale
    ),
    rows: [
      // Collect prices
      ...collectTablePrices(
        all_products,
        comparison_data,
        selected_locale,
        audience_key
      ),

      // Collect features
      ...collectTableFeatures(
        all_products,
        comparison_data,
        collections,
        selected_locale,
        audience_key
      ),

      // Collect product spec sheet links
      ...collectTableProductSpecSheetLinks(
        all_products,
        comparison_data,
        download_spec_sheet_text
      )
    ]
  };
}

function collectTableProductShortDescriptions(all_products, selected_locale) {
  let short_description_list = [];
  all_products.forEach((product) => {
    let short_description =
      selected_locale !== undefined && selected_locale !== null
        ? product.getLocalized("short_description", selected_locale.language) ||
          product.short_description
        : product.short_description;
    if (typeof short_description === "string") {
      short_description = short_description.split("\n");
    }
    if (short_description.length === 0) {
      short_description_list.push("");
    } else if (short_description === 1) {
      short_description_list.push(`<p>${short_description}</p>`);
    } else {
      const list_items = short_description.map((text) => `<li>${text}</li>`);
      short_description_list.push(`<ul>${list_items.join("")}</ul>`);
    }
  });
  return short_description_list;
}
function collectTableProductSpecSheetLinks(
  all_products,
  comparison_data,
  download_spec_sheet_text
) {
  const { template } = comparison_data;
  if (template !== "primary-vs-primaries") return [];

  const feature = {};
  const links = all_products.map((product) => {
    const { spec_sheet_url } = product;
    if (!spec_sheet_url) return [];

    const value = `<a href="${spec_sheet_url}" class="product-cta cta-label">${download_spec_sheet_text}</a>`;
    return [{ value, force_render_value: true }];
  });

  // Create a row of links only when at least one product has that information defined
  if (links.every((link) => link.length === 0)) return [];

  return [makeTableRow("", [], links, false, true)];
}

function getRelevantWinLogic(win_logic_list, template, spec_id) {
  const found_config = find(win_logic_list, (conf) => {
    if (conf.applicable_templates.indexOf(template) == -1 && !!template)
      return false;
    return conf.specification === spec_id || conf.specification?.id === spec_id;
  });
  return found_config;
}
function should_highlight_spec(
  primary_features,
  secondaries_features,
  feature,
  features_win_logic,
  template
) {
  let specs_primary = mapProductSpecsForComparison(primary_features, feature);

  if (specs_primary.length === 0) return false;

  const primary_spec = specs_primary[0].specification;
  const primary_spec_id = primary_spec.id;

  const short_features_win_logic = getRelevantWinLogic(
    features_win_logic,
    template,
    primary_spec_id
  );

  if (!short_features_win_logic) return false;

  let specs_secondary = [];
  secondaries_features.forEach((secondary_features) => {
    if (!secondary_features) return;

    let mapped_specs_for_comparison = mapProductSpecsForComparison(
      secondary_features,
      feature
    );
    specs_secondary.push(mapped_specs_for_comparison);
  });
  let highlight_spec = compare_grouping(
    [short_features_win_logic],
    specs_primary,
    specs_secondary
  );
  return highlight_spec;
}

function should_highlight_pricing(
  pricing_info,
  primary_product,
  secondary_products,
  selected_locale,
  treat_empty_as_inferior,
  template
) {
  const applicable_templates =
    selected_locale?.currency?.applicable_templates || [];
  if (applicable_templates.indexOf(template) === -1 && !!template) return false;

  // Highlight primary product
  const primary_price =
    product_price(primary_product, pricing_info)?.amount || "";
  const secondary_prices = secondary_products.map(
    (product) => product_price(product, pricing_info)?.amount || ""
  );
  // Make sure product prices are set before checking whether to highlight
  if (primary_price /*&& secondary_prices.some((price) => price > 0)*/) {
    return compare_price_withLocaleObject(
      primary_price,
      secondary_prices,
      selected_locale,
      treat_empty_as_inferior && secondary_prices.length > 0
    );
  }
  return false;
}

function collectTableFeatures(
  all_products,
  comparison_data,
  collections,
  selected_locale,
  audience_key
) {
  const { global_settings } = collections;
  const decimal_separator = global_settings.getLocalized(
    "decimal_separator",
    selected_locale
  );
  const currency = selected_locale?.currency;
  const primary_product = all_products[0];
  const secondary_products = all_products.slice(1);
  const selected_features =
    comparison_data.attributes ||
    comparison_data.selected_features(audience_key) ||
    [];
  return selected_features.map((feature, feature_idx) => {
    let default_feature = {
      ...feature,
      value_style: "regular"
    };
    const relevant_win_logic = getRelevantWinLogic(
      collections.win_logic,
      comparison_data.template,
      feature.id
    );

    const defaultTableCellValue = {
      ...makeTableCellValue(
        feature.default_value || emptyPlaceholder,
        null,
        null,
        null,
        default_feature,
        null,
        selected_locale
      ),
      is_default: true
    };
    let collected_disclaimers = [];
    let product_values = all_products.map((product, product_idx) => {
      const local_attrs = product.getLocalized(
        "attributes",
        selected_locale.language
      );

      let found_specs = filter(local_attrs, {
        specification: { id: feature.id }
      });

      // No product spec - use default value
      if (found_specs.length === 0) return [defaultTableCellValue];

      return found_specs.map((found_spec, spec_idx) => {
        const related_spec = found_spec.specification;
        let unit = found_spec.specification_unit;
        let facet = found_spec?.facet || found_spec?.facet_display || "";
        let disclaimer = null;

        // Use existing spec
        if (related_spec.place_symbol_after_each_spec) {
          disclaimer = found_spec.disclaimer;
        }
        // Collect previous disclaimers and preprend to this one's
        // ("the last spec")
        else if (
          related_spec.place_symbol_after_all_specs &&
          // Collect disclaimers only from first product
          product_idx === 0 &&
          // Attempt to include all collected features "at the end" (last feature)
          spec_idx === found_specs.length - 1
        ) {
          disclaimer = [...collected_disclaimers, found_spec.disclaimer]
            // Filter out invalid values
            .filter((d) => !!d);
        }
        // Collect existing disclaimer for later use (either after all specs
        // or after row heading label)
        else if (
          !related_spec.place_symbol_after_each_spec &&
          found_spec.disclaimer
        ) {
          collected_disclaimers.push(found_spec.disclaimer);
        }
        switch (related_spec.input_type) {
          case "numeric":
            if (
              found_spec.specification_numeric_value === "" ||
              found_spec.specification_numeric_value == 0 ||
              found_spec.specification_numeric_value == undefined ||
              found_spec.specification_numeric_value == null
            ) {
              return defaultTableCellValue;
            }
            return makeTableCellValue(
              localizeNumber(
                found_spec.specification_numeric_value_raw,
                selected_locale,
                decimal_separator
              ),
              disclaimer,
              unit,
              facet,
              related_spec,
              null,
              selected_locale
            );

          case "currency-display":
            if (found_spec.currency_value === "") return defaultTableCellValue;

            if (!related_spec.value_is_approximation || !currency) {
              return makeTableCellValue(
                // Here we show display_value instead of currency_value to retain symbol, if present
                found_spec.display_value,
                disclaimer,
                unit,
                facet,
                related_spec,
                null,
                selected_locale
              );
            }

            // Round value since it is marked as approximation
            let value_to_show =
              Math.round(found_spec.currency_value * 100) / 100;
            // Show a miminum when necessary
            if (value_to_show < MIN_CURRENCY_VALUE)
              value_to_show = MIN_CURRENCY_VALUE;
            if (currency.symbol_placement === "left")
              value_to_show = currency.symbol + value_to_show;
            else value_to_show = value_to_show + currency.symbol;

            // Prefix with a tilde (~)
            value_to_show = `~${value_to_show}`;

            return makeTableCellValue(
              value_to_show,
              disclaimer,
              unit,
              facet,
              related_spec,
              null,
              selected_locale
            );

          case "text-input":
            if (found_spec.specification_value === "")
              return defaultTableCellValue;
            return makeTableCellValue(
              found_spec.specification_value,
              disclaimer,
              unit,
              facet,
              related_spec,
              null,
              selected_locale
            );

          case "checkboxes":
            if ((found_spec.choices || []).length === 0)
              return defaultTableCellValue;
            return makeTableCellValue(
              found_spec.choices?.join?.(related_spec.value_separator),
              disclaimer,
              null,
              null,
              related_spec,
              null,
              selected_locale
            );

          case "dropdown":
            if (!found_spec.choice) return defaultTableCellValue;
            return makeTableCellValue(
              found_spec.choice,
              disclaimer,
              null,
              facet,
              related_spec,
              null,
              selected_locale
            );

          default:
            console.error(
              "Input type not supported: " + related_spec.input_type
            );
            return defaultTableCellValue;
        }
      });
    });

    // Highlight values based on win logic
    if (product_values.length > 1) {
      // Number of columns that can be highlighted
      let highlight_column_count =
        comparison_data.template === "primary-vs-primaries"
          ? // Highlight all columns for primary-vs-primaries
            all_products.length
          : // Highlight only the first column for other templates (currently just primary-vs-competitors)
            1;

      const feature_facets =
        feature.getLocalized("facets", selected_locale) || [];

      // Test columns as if primary
      for (var i = 0; i < highlight_column_count; ++i) {
        // const secondaries = product_values.filter((v, idx) => idx !== i);
        // const primary_features = product_values[i].filter((f) => f.specification.id === feature.id);
        if (!all_products[i]) continue;

        const secondaries = all_products.filter((v, idx) => idx !== i);
        const primary_features = all_products[i]
          .getLocalized("attributes", selected_locale)
          .filter((f) => f.specification.id === feature.id);
        const secondaries_features = secondaries.map((p) =>
          p
            .getLocalized("attributes", selected_locale)
            .filter((f) => f.specification.id === feature.id)
        );

        let offset = 0;
        let primary_values = [];
        let secondary_values = [];
        // extract values by facet for comparison
        if (feature_facets.length > 0) {
          primary_values = feature_facets.map(({ value: facet }) =>
            primary_features.filter((v) => v.facet === facet)
          );
          secondary_values = feature_facets.map(({ value: facet }) =>
            secondaries_features.map((sfs) =>
              sfs.filter((v) => v.facet === facet)
            )
          );
        } else {
          // Count the number of values within every "cell"
          const values_count = [
            primary_features.length,
            ...secondaries_features.map((f) => f.length)
          ];
          // Identify the largest number of values
          const max_value = Math.max(...values_count);
          const dummy_list = new Array(max_value).fill(0);
          // Collect values by index
          primary_values = dummy_list.map((_, val_idx) =>
            primary_features.filter((v, attr_idx) => val_idx === attr_idx)
          );
          secondary_values = dummy_list.map((_, val_idx) =>
            secondaries_features.map((sfs) =>
              sfs.filter((v, attr_idx) => val_idx === attr_idx)
            )
          );
        }

        // highlight values as necessary
        for (var j = 0; j < primary_values.length; ++j) {
          const primaries_of_facet = primary_values[j];
          const secondaries_of_facet = secondary_values[j];

          if (primaries_of_facet.length === 0) ++offset;

          if (relevant_win_logic) {
            let highlight_spec = should_highlight_spec(
              primaries_of_facet,
              secondaries_of_facet,
              feature,
              [relevant_win_logic],
              comparison_data.template
            );

            if (highlight_spec && product_values[i][j - offset]) {
              product_values[i][j - offset].highlight = highlight_spec;
            }
          }
        }
      }
    }

    // Build list of disclaimers to show
    let row_disclaimers = [];

    if (feature.place_symbol_after_grouping_label) {
      row_disclaimers = [
        feature.qualifying_disclaimer,
        ...collected_disclaimers
      ]
        // Remove invalid values
        .filter((disclaimer) => !!disclaimer);
    }

    return makeTableRow(
      feature.getLocalized("label", selected_locale.language) || feature.label,
      row_disclaimers,
      product_values,
      false,
      false,
      feature,
      relevant_win_logic
    );
  });
}

function collectTablePrices(
  all_products,
  comparison_data,
  selected_locale,
  audience_key
) {
  const treat_empty_as_inferior =
    selected_locale?.currency?.treat_empty_values_as === "inferior";
  return (
    comparison_data.price_types ||
    comparison_data.pricing(audience_key) ||
    []
  ).map((pricing_info) => {
    let product_prices = all_products.map((product) => {
      const local_attrs = product.getLocalized(
        "prices",
        selected_locale.language
      );

      let product_price_obj = find(local_attrs, {
        type: { id: pricing_info.id }
      });
      let product_price_value =
        product_price_obj?.currency_display || emptyPlaceholder;
      let product_price_style = product_price_obj?.type?.display_style;
      let product_price_feature_data = { value_style: product_price_style };
      return [
        makeTableCellValue(
          product_price_value,
          null,
          null,
          null,
          product_price_feature_data,
          null,
          selected_locale
        )
      ];
    });

    if (comparison_data.template === "primary-vs-primaries") {
      for (var i = 0; i < all_products.length; ++i) {
        const secondaries = all_products.reduce(
          (acc, product, idx) => (idx === i ? acc : [...acc, product]),
          []
        );
        const should_highlight = should_highlight_pricing(
          pricing_info,
          all_products[i],
          secondaries,
          selected_locale,
          treat_empty_as_inferior,
          comparison_data.template
        );

        if (should_highlight) product_prices[i][0].highlight = should_highlight;
      }
    } else {
      // if (comparison_data.template === "primary-vs-competitors") {
      const should_highlight = should_highlight_pricing(
        pricing_info,
        all_products[0],
        all_products.slice(1),
        selected_locale,
        treat_empty_as_inferior,
        comparison_data.template
      );
      if (should_highlight) product_prices[0][0].highlight = should_highlight;
    }

    return {
      header: pricing_info.label,
      values: product_prices,
      header_disclaimer: []
    };
  });
}

function makeTableRow(
  header,
  header_disclaimer = [],
  values,
  extract_header_disclaimer_from_footer,
  transparent_bg,
  feature,
  win_logic
) {
  return {
    header,
    header_disclaimer: header_disclaimer.filter(uniqueDisclaimers),
    values,
    extract_header_disclaimer_from_footer,
    transparent_bg,
    feature,
    win_logic
  };
}

function product_price(product, price_type) {
  return find(product?.prices || [], { type: { id: price_type?.id } });
}

// Calculate gapTCO Savings using jsonLogic
export function gapTCOSavings(
  third_layer_object,
  product_compare,
  table,
  collections,
  maps,
  audience_key,
  selected_locale
) {
  // Select configuration for Black or Color
  let gaptco_savings_configuration =
    third_layer_object.gaptco_savings(audience_key) === "black"
      ? collections.global_settings?.getLocalized(
          "gaptco_black_savings_configuration",
          selected_locale
        )?.id
      : collections.global_settings?.getLocalized(
          "gaptco_color_savings_configuration",
          selected_locale
        )?.id;

  let tco_row_header_disclaimer =
    collections.global_settings?.gaptco_disclaimer;

  if (!gaptco_savings_configuration) return;

  // Set default value
  let gaptco_savings_default_value = collections.global_settings?.getLocalized(
    "gaptco_savings_default_value",
    selected_locale
  );

  // Process variables
  let formula_variables = prepareJSONLogicData(
    product_compare,
    gaptco_savings_configuration.variables
  );

  // Prevent TCO calculation
  if (!validateTCOSavingsLogicData(formula_variables)) return;

  // Evaluate formula
  let tco_savings = jsonLogic.apply(
    gaptco_savings_configuration.formula,
    formula_variables
  );

  // Round
  tco_savings = parseInt(tco_savings);

  const { display_style, highlight_condition, highlight_condition_value } =
    gaptco_savings_configuration;

  // Include TCO data in table only when positive
  if (tco_savings <= 0) return;

  const show_tco_savings = third_layer_object.show_tco_savings(audience_key);

  if (!show_tco_savings) return;

  let tco_row_header = "gapTCO TCO Savings";

  if (!tco_savings) return;

  // Store TCO Savings amount
  third_layer_object.set_tco_savings(audience_key, tco_savings);

  // Create string to render
  const gap_tco_savings_content = `UP TO<br/><span>${tco_savings}%</span><br/>LOWER PRINTING COSTS`;
  third_layer_object.set_gap_tco_savings_content(
    audience_key,
    gap_tco_savings_content
  );

  // Prepare TCO savings cell
  let tco_cell_value = [
    {
      value: `up to ${tco_savings}%`,
      value_style: display_style?.value || ""
    }
  ];

  // Prepare default values to fill cells in remaining columns
  let tco_default_value_cells = (table.rows[0]?.values?.slice?.(1) || []).map(
    (_) => {
      return [{ value: gaptco_savings_default_value }];
    }
  );

  // Highlight TCO savings?
  if (
    highlight_condition &&
    win_conditions[highlight_condition] &&
    win_conditions[highlight_condition](tco_savings, highlight_condition_value)
  ) {
    tco_cell_value.highlight = true;
  }

  // Prepare row
  let tco_row_values = [tco_cell_value, ...tco_default_value_cells];

  if (product_compare.template === "primary-vs-competitors") {
    let tco_row = makeTableRow(
      tco_row_header,
      [tco_row_header_disclaimer],
      tco_row_values
    );
    table.rows.push(tco_row);
  }
}
