import { cloneDeep } from "lodash";

/**
 *
 * @param collectionPages
 * create a new collection of pages
 * if pages contain dynamic content, create all possible instances of how dynamic content can be displayed
 * make a new copy of each page for each instance of dynamic content and then this instance will be used to dictate how modules should be rendered
 */
export function generateFlatPages(collectionPages, project) {
  let flatPages = [];
  for (let page of collectionPages) {
    const dynamicModules = page.modules.filter(
      (module) =>
        module.type === "carousel" ||
        module.type === "tabbed-content" ||
        module.type === "hotspots" ||
        module.type === "config-tool"
    );
    if (dynamicModules.length > 0) {
      let instances = {};
      for (const module of dynamicModules) {
        instances[module.type] = moduleInstanceFnMap[module.type](
          module,
          project
        );
      }
      for (const key of Object.keys(instances)) {
        const isConfig = key === "config-tool";
        const numInstances = !isConfig ? instances[key] : instances[key].length;
        //make a copy of a page for each instance
        for (let i = 0; i < numInstances; i++) {
          let pageCopy = Object.assign({}, page);
          pageCopy.instance = !isConfig
            ? { module: key, index: i }
            : { module: key, index: i, config: instances[key][i] };
          flatPages.push(pageCopy);
        }
      }
    } else flatPages.push(page);
  }
  return flatPages;
}

// takes elements and maps them to an index
export const filterIndicesMap = (array) =>
  array.reduce(
    (acc, { key }, idx) => ({
      ...acc,
      [key]: idx
    }),
    {}
  );

// takes indices and maps them to an element
export const filterIndicesMapReverse = (array) =>
  array.reduce(
    (acc, { key }, idx) => ({
      ...acc,
      [idx]: key
    }),
    {}
  );

// gets all unique operations of an index for all formulas in logic
export const getFormulas = (idx, logic) => {
  let formulas = new Set();
  for (const l of logic) {
    formulas.add(l[idx]);
  }
  return formulas;
};

// get all products for a specific filter using the index
export const getProducts = (key, logic, filters) => {
  const array = getFormulas(key, logic).has("= 0") ? [0] : [];
  const textKey = filterIndicesMapReverse(filters)[key];
  return array.concat(filters.find((e) => e.key === textKey)?.products);
};

// returns all combinations of checkbox layout (including/excluding the relevant ones that affect calculations)
export const getCheckboxCombos = (key, logic, filters) => {
  const relevantCheckboxes = [...getFormulas(key, logic)]
    .map((e) => parseInt(e.split(" ")[1]))
    .filter((e) => e != 0);
  let arrayCombinations = [];
  const irrelevantCheckboxes = getProducts(key, logic, filters)
    .map((e) => e.id)
    .filter((e) => e && !relevantCheckboxes.includes(e));
  let baseArray = irrelevantCheckboxes;
  arrayCombinations.push(baseArray);
  for (const c of relevantCheckboxes) {
    let newCombos = [];
    for (const a of arrayCombinations) {
      newCombos.push([c].concat(a));
    }
    arrayCombinations = arrayCombinations.concat(newCombos);
  }
  return arrayCombinations;
};

// execute appropriate function based on module type
const moduleInstanceFnMap = {
  carousel: (module) => module.module_carousel.slides.length,
  "tabbed-content": (module) => module.module_tabbed_content.slides.length,
  hotspots: (module) => module.module_hotspots.hotspots.length,
  "config-tool": (module, project) => {
    //blank config page should be first
    let configs = [{}];
    const { module_config_tool } = module;
    const { product_filters, settings } = module_config_tool;
    const expanded_settings = expandSettings(settings);
    const relevantFilters = product_filters.map(
      ({ key, products, show_as_checkboxes }) => ({
        key,
        show_as_checkboxes,
        products: products.map(({ id }) => id)
      })
    );
    for (const { formula } of expanded_settings) {
      const config = createConfigFromNewLogic(formula, relevantFilters);
      if (!!config) configs.push(config);
    }
    return configs;
  }
};

// set of operations used inside new logic
function either(a, b) {
  if (!Array.isArray(b) || b.length !== 2) return false;
  return a == b[0] || a == b[1];
}

function includes(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  const as = new Set(a);
  return b.every((x) => as.has(x));
}

function excludes(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  const as = new Set(a);
  return b.every((x) => !as.has(x));
}

function any(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  const as = new Set(a);
  return b.some((x) => as.has(x));
}
//

//applies operations above to formula to determine if conditions are satisfied
export function applyNewLogic(values, formula) {
  const evald = new Set();
  for (const [k, v] of formula) {
    if (k === "includes") evald.add(includes(values, v));
    if (k === "excludes") evald.add(excludes(values, v));
    if (k === "any") evald.add(any(values, v));
  }
  return evald.has(true) && evald.size === 1;
}

//returns an array of all the product IDs in a built config
//{printer-model:94, additional-options:[98] => [94,98]
export function getConfigProductIds(config) {
  if (!config) return;
  let arr = [];
  for (const [k, v] of Object.entries(config)) {
    if (typeof v === "object") {
      arr = arr.concat(v);
    } else {
      const numV = Number.parseInt(v);
      if (!isNaN(numV)) arr.push(Number.parseInt(v));
    }
  }
  return arr;
}

//set of functions to create congig from newLogic for expose content
//{printer-model: {products:[1,2,3], show_as_checkboxes: false}}
function getRelevantFilterMap(filters) {
  const res = {};
  for (const e of filters) {
    for (const p of e.products) {
      // array of product ids
      res[p] = { key: e.key, multiple: e.show_as_checkboxes };
    }
  }
  return res;
}

//given a product id, get the info for its associated filter (key and if its multiple)
function getFilterInfo(relevantFilterMap, pid) {
  return relevantFilterMap[pid];
}

//add a product id under the correct key to the config: return true if successful
function addToConfig(config, relevantFilterMap, pid) {
  const keyObj = getFilterInfo(relevantFilterMap, pid);
  const { key, multiple } = keyObj || {};
  if (!key) return false;
  if (multiple) {
    if (config[key]) config[key].push(pid);
    else config[key] = [pid];
  } else config[key] = pid;
  return true;
}

//loop through config operations and add the product ids to their correct filter key
function createConfigFromNewLogic(formula, filters) {
  const relevantFilterMap = getRelevantFilterMap(filters);
  const config = {};
  for (const [k, v] of formula.newLogic[1]) {
    if (k === "any") {
      const addition = addToConfig(config, relevantFilterMap, v[0]);
      if (!addition) return null;
    }
    if (k === "includes") {
      for (const vv of v) {
        const addition = addToConfig(config, relevantFilterMap, vv);
        if (!addition) return null;
      }
    }
  }
  return config;
}
//end of set

//takes list of attr values and returns array of objects [{text,span}]
//determines how many columns a value should span (for merging when values are equal in adjacent cols)
/**
 *
 * @param {*} attrList [{text,span}]
 * @returns [{text,span}]
 */
export function getColSpansForDataTable(attrList, mergeValues) {
  if (!mergeValues) return attrList;
  const spanList = [];
  let { text, span } = attrList[0];
  for (let i = 1; i < attrList.length; i++) {
    if (attrList[i].text === text) span++;
    else {
      spanList.push({ text, span });
      text = attrList[i].text;
      span = attrList[i].span;
    }
  }
  spanList.push({ text, span });
  return spanList;
}

//config tool: gets the images for default configs and maps them by product id
//value_flag: if "index", return settings index, or if "image" return image object
export function getDefaultImages(settings, value_type = "image") {
  const default_settings = settings.filter(
    ({ is_product_default }) => !!is_product_default
  );
  const default_img_map = default_settings.reduce((acc, setting, idx) => {
    const product_ids = getNewLogicProducts(setting);
    for (const product_id of product_ids) {
      if (product_id)
        acc[product_id] = value_type === "image" ? setting.image : idx;
    }
    return acc;
  }, {});
  return default_img_map;
}

/**
 * ex: if any=[1,2,3] is the part of the formula (the primary products),
 * return 3 new settings where any=[1] any=[2] any=[3]
 * and give them their appropriate default image
 * @param {*} settings
 * @param {*} project
 */
export function expandSettings(settings) {
  const missingImages = settings.filter(({ image }) => !image);
  //if nothing is missing an image, don't expand
  if (missingImages.length > 0) {
    const default_image_map = getDefaultImages(settings, "image");
    const newSettings = [];
    for (const s of settings) {
      const primary_products = getNewLogicProducts(s);
      for (const p of primary_products) {
        const newSetting = cloneDeep(s);
        setNewLogicProducts(newSetting, [p]);
        if (!newSetting.image) newSetting.image = default_image_map[p];
        newSettings.push(newSetting);
      }
    }
    return newSettings;
  }
  return settings;
}

//sample "new logic" formula
//primary products are always in the first clause
// {
//   "newLogic": [
//     {
//       "var": "product_ids"
//     },
//     [["any", [1, 2, 4], ["includes", [6]]]]
//   ]
// }
//[1] is the list of clauses, [0] is the first clause [1] is the product ids
function getNewLogicProducts(setting) {
  return setting?.formula?.newLogic?.[1]?.[0]?.[1] || [];
}
function setNewLogicProducts(setting, newProducts) {
  if (setting?.formula?.newLogic?.[1]?.[0]?.[1])
    setting.formula.newLogic[1][0][1] = newProducts;
}

//determines if the primary products all have the same dimensions
export function determineSameDimensions(settings) {
  const dimensionSet = new Set();
  const default_settings = settings.filter(
    ({ is_product_default }) => !!is_product_default
  );
  default_settings.forEach((setting) => {
    const { width_value, height_value, depth_value, weight_value } = setting;
    dimensionSet.add(
      JSON.stringify({ width_value, height_value, depth_value, weight_value })
    );
  });
  return dimensionSet.size === 1;
}

//for config tool: get the product ids that must be part of the config (AKA impact dimensions)
export function getRelevantProducts(setting) {
  const relevantIds = [];
  const ops = new Set(["any", "includes", "excludes"]);
  if (!setting?.formula?.newLogic) return relevantIds;
  for (const [k, v] of setting.formula.newLogic[1]) {
    if (ops.has(k)) {
      relevantIds.push(...v);
    }
  }
  return relevantIds;
}

export function getAllRelevantProducts(settings) {
  return Array.from(new Set(settings.flatMap((s) => getRelevantProducts(s))));
}

// Shorten SKUs such as "XX/YY/ZZ" into "XX*", with a disclaimer
export function shrinkProductSku(
  sku,
  multiple_skus_separator,
  multiple_skus_disclaimer,
  disclaimer_processor
) {
  if (
    !!multiple_skus_separator &&
    !!multiple_skus_disclaimer &&
    !!disclaimer_processor
  ) {
    const skus = sku.split(multiple_skus_separator);
    if (skus.length > 1) {
      return (
        skus[0] +
        disclaimer_processor.processDisclaimer(multiple_skus_disclaimer)
      );
    }
  }
  return sku;
}

//finds the correct setting for the config tool
export function findMatchedSetting(config, settings, jsonLogic) {
  const product_ids = getConfigProductIds(config);
  return settings.find(({ formula }) => {
    return jsonLogic.apply(formula, { ...config, product_ids });
  });
}

/**
 * adjusts table body for part number index
 * if product has multiple skus, it will add multiple rows (per sku)
 * part name will be "main" name with product that the sku corresponds to in ()
 */
export function adjustTableBodyForMultipleSkus(
  table_body,
  table_contents_logic,
  collections,
  project
) {
  const isPartsNumberIndex =
    table_contents_logic?.[0]?.[0] === "Part Name" &&
    table_contents_logic?.[0]?.[1] === "Part Number";
  const partNumberIndexCollection = table_contents_logic?.[1]?.map?.[0]?.var;
  const partNameDataKey = table_contents_logic?.[1]?.map?.[1]?.[0]?.var;
  const partNumberDataKey = table_contents_logic?.[1]?.map?.[1]?.[1]?.var;
  const multiple_skus_separator =
    collections.module_config_tool[0]?.multiple_skus_separator;
  const isAMA3 = project === "wf-enterprise-am-a3";
  const isAmA4 = project === "wf-enterprise-am-a4";
  const needsAdjustment = isPartsNumberIndex && (isAMA3 || isAmA4);
  if (!needsAdjustment) {
    return table_body;
  }
  const adjustedTableBody = [];
  const seenPartNumbers = new Set();
  for (const row of table_body) {
    const partName = row[0];
    const partNumber = row[1];
    const partNumbers = partNumber.split(multiple_skus_separator);
    //no need to add extra name; add as normal
    if (partNumbers.length === 1) {
      if (seenPartNumbers.has(partNumber)) {
        continue;
      } else {
        seenPartNumbers.add(partNumber);
        adjustedTableBody.push([partName, partNumber]);
      }
      continue;
    }
    for (const partNumber of partNumbers) {
      // product with this sku (if we don't find this, then just use bundle's name)
      const skuProduct = findProductBySku(
        collections[partNumberIndexCollection],
        partNumberDataKey,
        partNumber,
        partNameDataKey,
        partName
      );
      const skuProductName =
        skuProduct?.shortname || skuProduct?.[partNameDataKey] || "";

      if (seenPartNumbers.has(partNumber)) {
        continue;
      } else {
        seenPartNumbers.add(partNumber);
        adjustedTableBody.push([skuProductName || partName, partNumber]);
      }
    }
  }
  return adjustedTableBody;
}

//id key is property used to ensure you don't match with the bundle product
export function findProductBySku(products, skuKey, skuValue, idKey, idValue) {
  return products.find(
    (p) =>
      (p[skuKey] === skuValue || p["individual_sku"] === skuValue) &&
      p[idKey] !== idValue
  );
}
