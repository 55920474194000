// import ReactGA from 'react-ga';

const dataLayer = window.dataLayer || [];
var pageviewReported = false;
/**
 * Report pageviews
 */
export const report_pageview = () => {
  if (pageviewReported)
    return;
  pageviewReported = true;

  if (process.env.NODE_ENV !== 'production') {
    console.log('Analytics event not pushed due to being in development mode.')
    return;
  }

  dataLayer.push({
   'event': 'page_view',
   'page_title': document.title,
  });
}

// /**
//  * PDF generation - begin
//  */
// export const report_pdf_generate = (project_name) => {
//   ReactGA.event({
//     category: 'PDF Generation',
//     action: 'Click',
//     label: project_name
//   });
// }

// /**
//  * PDF generation - successful
//  */
// export const report_pdf_success = (project_name) => {
//   ReactGA.event({
//     category: 'PDF Generation',
//     action: 'Success',
//     label: project_name,
//     nonInteractive: true
//   });
// }

// /**
//  * PDF generation - error
//  */
// export const report_pdf_error = (error) => {
//   ReactGA.exception({
//     description: 'Pdf Generation error: ' + error,
//     fatal: false
//   });
// }
