const scripts = `

try {
  // Expected to be in the following form:
  // {
    // 'module-name': {
      // product_names_sorted,
      // dropdown_products,
      // checkbox_products,
      // product_names_map,
      // all_product_names_by_sku_map,
      // related_models_map,
      // product_ids_name_map,
      // logic_field_names,
      // logic_cfg,
      // logic_fields_map,
      // project,
      // same_dimensions
      // logic_field_names_v2
      // max_products
    // }
  // }
  var cfgTool_vars = {{script_vars}};
  //{module-name:[]}
  var cfgTool_disabled_state = {};
  var cfgTool_deselected_state = {};
  //{module-name:[]}
  var cfgTool_dropdown_options_state = {};
  //keep track of the idx of the matched setting
  //{module-name:-1}
  var cfgTool_match_idx = {};
  // Previous variables by module name
  // e.g. { 'module-1_dropdown-1' : '2-4 Hole Punch Kit', ... }
  var cfgTool_prev_vars = {};


  function cfgTool_enumerateFieldsByPrefix(prefix) {
    var fields = [];
    var idx = 0;
    var found;
    do {
      // Build name: <pfx><idx>
      var field_name = prefix + idx;
      var field = getField(field_name);
      found = !!field;
      if (found) {
        fields.push(field);
      }
      ++idx;
    } while (found);
    return fields;
  }
  
  function cfgTool_initCheckboxListener(module_name_pfx, field_name) {
    var field = getField(field_name);
    if (!field) return false;
    field.setAction(
      "MouseUp",
      "cfgTool_fieldChanged('" + module_name_pfx + "', '" + field_name + "')"
    );
  
    // Cache current value
    cfgTool_prev_vars[field_name] = field.value;
  
    return true;
  }
  
  function cfgTool_enumerateCheckboxes(module_name_pfx) {
    var cbox_container_idx = 0;
    var checkboxes_found;
    var found_items = [];
    do {
      checkboxes_found = false;
      // Build name: <pfx>_checkboxes-<container_idx>- (without the last idx, for later)
      var field_name_base =
        module_name_pfx + "_content_checkboxes-" + cbox_container_idx + "-";
  
      const checkboxes = cfgTool_enumerateFieldsByPrefix(field_name_base);
      if (checkboxes.length > 0) checkboxes_found = true;
  
      found_items = found_items.concat(checkboxes);
  
      ++cbox_container_idx;
    } while (checkboxes_found);
    return found_items;
  }
  
  // Checkboxes are named as follows:
  // <module_name_pfx>_checkboxes-<cbox_container_idx>-<cbox_idx>
  function cfgTool_initCheckboxListeners(module_name_pfx) {
    const checkboxes = cfgTool_enumerateCheckboxes(module_name_pfx);
    checkboxes.forEach((f) => {
      cfgTool_initCheckboxListener(module_name_pfx, f.name);
    });
  }
  
  function cfgTool_initDropdownListener(module_name_pfx, field_name) {
    var field = getField(field_name);
    if (!field) return false;
  
    field.commitOnSelChange = true;
    field.setAction(
      "Calculate",
      "cfgTool_fieldChanged('" + module_name_pfx + "', '" + field_name + "')"
    );
  
    // Cache current value
    cfgTool_prev_vars[field_name] = field.value;
  
    const options = [];
    for (var i = 0; i < field.numItems; i++) {
      const optionValue = field.getItemAt(i);
      const optionLabel = field.getItemAt(i, false);
      options.push([optionLabel, optionValue]);
    }
    // store original options of the the dropdown
    cfgTool_dropdown_options_state[module_name_pfx][field_name] = options;
    return true;
  }
  
  function cfgTool_enumerateDropdowns(module_name_pfx) {
    return cfgTool_enumerateFieldsByPrefix(
      module_name_pfx + "_content_dropdown-"
    );
  }
  
  // Dropdowns are named as follows:
  // <module_name_pfx>_dropdown-<dropdown_idx>
  function cfgTool_initDropdownListeners(module_name_pfx) {
    const dropdown_fields = cfgTool_enumerateDropdowns(module_name_pfx);
    dropdown_fields.forEach((f) => {
      cfgTool_initDropdownListener(module_name_pfx, f.name);
    });
  }

  //for product's x skus, add product x times with the corresponding sku
  //only applicable for am-a3 and am-a4 (set on frontend)
  function cfgTool_addProductToSelectedProducts(module_name_pfx, selected_products, product) {
    if (!product) return;
    const productName = product.model;
    const shortName = product.shortname || productName;
    const { all_product_names_by_sku_map } = cfgTool_vars[module_name_pfx];
    for (var i = 0; i < product.skus.length; i++) {
      const skuProductName = all_product_names_by_sku_map[product.skus[i]] || productName;
      selected_products.push({
        model: skuProductName === productName? productName: skuProductName + " (for " + shortName + ")",
        id: product.id,
        sku: product.skus[i],
      });
    }
  }

  function cfgTool_getSortedSelectedProducts(module_name_pfx) {
    var selected_products = [];
    const dropdowns = cfgTool_enumerateDropdowns(module_name_pfx);
    const checkboxes = cfgTool_enumerateCheckboxes(module_name_pfx);
    const cfgTool_fields = [...dropdowns, ...checkboxes];
  
    const { related_models_map, product_names_map, product_ids_name_map } =
      cfgTool_vars[module_name_pfx];
  
    cfgTool_fields.forEach((f) => {
      const selected_product = cfgTool_getProduct(module_name_pfx, f);
      if (selected_product) {
        cfgTool_addProductToSelectedProducts(module_name_pfx, selected_products, selected_product);
  
        // Also include related models
        const related_models = related_models_map[selected_product.id];
        if (related_models && related_models.length > 0) {
          related_models.forEach((id) => {
            const related_model_name = product_ids_name_map[id];
            const related_model = product_names_map[related_model_name];
            cfgTool_addProductToSelectedProducts(module_name_pfx, selected_products, related_model);
          });
        }
      }
    });
  
    return selected_products;
  }
  
  // Update overline, title and part numbers
  function cfgTool_updateFieldValues(module_name_pfx) {
    // Skip overline since it is hardcoded to read "WorkForce Enterprise"
    var primary_dropdown = getField(module_name_pfx + "_content_dropdown-0");
    var primary_product = cfgTool_getProduct(module_name_pfx, primary_dropdown);
    if (primary_product) {
      const { dropdown_products } = cfgTool_vars[module_name_pfx] || {};
      const first_key = Object.keys(dropdown_products)[0];
      const products = dropdown_products[first_key];
      products.forEach((id) => {
        const show_layer = id === primary_product.id;
        toggleLayer(module_name_pfx + "_content_title-" + id, show_layer);
      });
    }
  
    // Keep track of row index to update
    const sorted_selected_products =
      cfgTool_getSortedSelectedProducts(module_name_pfx);
    var row_idx = sorted_selected_products.length;
  
    cfgTool_showTableValues(module_name_pfx);
  
    const max_products = cfgTool_getMaxProducts(module_name_pfx);
  
    // Hide remaining rows
    for (row_idx; row_idx < max_products; ++row_idx) {
      cfgTool_toggleRow(module_name_pfx, row_idx, false);
    }
  }
  
  // Set table values by showing dropdown values
  // followed by checkbox ones, both in order.
  function cfgTool_showTableValues(module_name_pfx) {
    const sorted_selected_products =
      cfgTool_getSortedSelectedProducts(module_name_pfx);
    cfgTool_toggleTableValues(module_name_pfx, sorted_selected_products, true);
  }
  // const multiline_threshold = 25;
  function cfgTool_setCellValue(field_name, value, is_bold) {
    console.println("Setting value for " + field_name + " to " + value);
    var field = getField(field_name);
    const value_str = value || "";
    // field.multiline = value_str.length > multiline_threshold;
    // Always treat as multiline
    field.multiline = true;
    field.richText = true;
  
    field.richValue = [
      {
        fontWeight: is_bold ? 900 : 400,
        fontFamily: ["Helvetica"],
        text: value,
      },
    ];
  }
  function cfgTool_setRowValues(module_name_pfx, row_idx, v_th, v_td) {
    const th_name = module_name_pfx + "_content_th-" + row_idx;
    // setFieldValue(th_name, v_th);
    cfgTool_setCellValue(th_name, v_th, true);
    const td_name = module_name_pfx + "_content_td-" + row_idx;
    // setFieldValue(td_name, v_td);
    cfgTool_setCellValue(td_name, v_td);
  }
  
  // Also remove field values so that when the field is shown it is empty.
  // Otherwise, on slower systems we may sometimes briefly see the field with old content.
  function cfgTool_hideTableValues(module_name_pfx) {
    const empty_values = makeEmptyArray(cfgTool_getMaxProducts(module_name_pfx), {});
    cfgTool_toggleTableValues(module_name_pfx, empty_values, false);
  }
  // Toggle visibility
  function cfgTool_toggleTableValues(module_name_pfx, table_values_list, state) {
    const max_table_elements = cfgTool_getMaxProducts(module_name_pfx);
    table_values_list.forEach((obj, row_idx) => {
      // TODO: should limit be 15 or based on products?
      if (row_idx >= max_table_elements) return;
      const { model, sku } = obj || {};
      const v_th = (state && model) || "";
      const v_td = (state && sku) || "";
      cfgTool_setRowValues(module_name_pfx, row_idx, v_th, v_td);
      cfgTool_toggleRow(module_name_pfx, row_idx, state);
    });
  }
  
  function cfgTool_toggleRow(module_name_pfx, row_idx, state) {
    // Hide table BG & border
    toggleLayer(module_name_pfx + "_content_row-" + row_idx, state);
    // Hide header field
    toggleFieldVisibility(module_name_pfx + "_content_th-" + row_idx, state);
    // Hide value field
    toggleFieldVisibility(module_name_pfx + "_content_td-" + row_idx, state);
  }
  
  // Enable/disable image layers for this module as appropriate.
  function cfgTool_showImageFromLogic(module_name_pfx) {
    if (!cfgTool_vars[module_name_pfx]) return 0;
  
    const { logic_cfg } = cfgTool_vars[module_name_pfx];
  
    cfgTool_match_idx[module_name_pfx] = cfgTool_getImageIndexToShow(module_name_pfx);
    const max_images = logic_cfg.length;
    for (var i = 0; i < max_images; ++i) {
      const show_layer = i === cfgTool_match_idx[module_name_pfx];
      const image_layer_name = module_name_pfx + "_content_image-" + i;
      toggleLayer(image_layer_name, show_layer);
    }
  }
  
  // Returns whether a portion of configuration logic "matches"
  // a field's value.
  // config is expected to be a string such as "> 0", "= 0", "= 10"
  //   - A value of 0 would mean no product is selected for the field
  function cfgTool_fieldMatchesConfig(module_name_pfx, field, config) {
    const field_product = cfgTool_getProduct(module_name_pfx, field);
  
    // Here we expect the following format:
    //   <operator><space><value>
    // e.g. "> 10", "= 0"
    const [operator, comparing_value] = config.split(" ");
  
    // Make inexistent products have a value of 0
    if (!field_product) {
      return compareValues(0, comparing_value, operator);
    }
  
    const value = field_product.id;
    return compareValues(value, comparing_value, operator);
  }
  
  // Return the matching index from logic
  // We expect logic to be in the following form from logic_cfg:
  //   { 0: "> 0", 1: "= 0", 2: "= 0", 3: "= 0" }
  // where each key maps to a field name by logic_fields_map
  // equivalent of finding matched setting on frontend
  function cfgTool_getImageIndexToShow(module_name_pfx) {
    if (!cfgTool_vars[module_name_pfx]) return 0;
  
    const { logic_cfg, logic_fields_map, reverse_products } =
      cfgTool_vars[module_name_pfx];
  
    for (var i = 0; i < logic_cfg.length; ++i) {
      const cfg = logic_cfg[i];
      // e.g. [0,1,2,3]
      const keys = Object.keys(cfg);
      const all_match = keys.every((key) => {
        if (key === "newLogic") return true;
        if (cfg.newLogic) {
          const op = key.split("_")[0];
          const idList = cfg[key];
          /**
           * disabled: just says which ids should be disabled; disregard here
           * deselected: just says which ids should be deselected; disregard here
           * any: find the field associaed with the first element.
           * * if the field equals any of those, return true
           * includes: for each, find the field and return if they're equal
           * excludes: for each, find the field and return true if they're not equal
           */
          if (op === "disabled" || op === "deselected") return true;
          if (op === "any") {
            const any_satisfied = idList.some((x_any) => {
              const field_name_any = reverse_products[x_any];
              const field_any = getField(field_name_any);
              const field_product_any = cfgTool_getProduct(
                module_name_pfx,
                field_any
              );
              if (!field_product_any) {
                return compareValues(0, x_any, "=");
              }
              const value_any = field_product_any.id;
              return compareValues(value_any, x_any, "=");
            });
            return any_satisfied;
          } else {
            return idList.every((x_includes) => {
              const operator = op === "includes" ? "=" : "!=";
              const field_name_includes = reverse_products[x_includes];
              const field_includes = getField(field_name_includes);
              const field_product_includes = cfgTool_getProduct(
                module_name_pfx,
                field_includes
              );
              if (!field_product_includes) {
                return compareValues(0, x_includes, operator);
              }
              const value_includes = field_product_includes.id;
              return compareValues(value_includes, x_includes, operator);
            });
          }
        } else {
          const field_name = logic_fields_map[key];
          const field = getField(field_name);
          return cfgTool_fieldMatchesConfig(module_name_pfx, field, cfg[key]);
        }
      });
  
      if (all_match) {
        if (cfg.newLogic) {
          cfgTool_disabled_state[module_name_pfx] = cfg.disabled;
          cfgTool_deselected_state[module_name_pfx] = cfg.deselected;

        }
        return i;
      }
    }
    //set the disabled options associated with the matched setting
    cfgTool_disabled_state[module_name_pfx] = [];
    cfgTool_deselected_state[module_name_pfx] = [];
    return -1;
  }
  
  function cfgTool_getMaxProducts(module_name_pfx) {
    const { max_products } = cfgTool_vars[module_name_pfx];
    return max_products;
  }
  
  const title_field_names = ["_overline", "_title", "_email-btn"];
  // These are **parts** of the name. For example, <module>_th-<idx>
  const table_field_names = ["_th-", "_td-"];
  function cfgTool_toggleConfigSection(module_name_pfx, state) {
    const pfx = module_name_pfx + "_content";
    toggleLayer(pfx, state);
  
    // Export button visibility
    const export_visibility_lyr_name =
      module_name_pfx + "_helper_export-btn-visibility";
    toggleLayer(export_visibility_lyr_name, !state);
  
    // Toggle fields
    title_field_names.forEach((n) => {
      const field_name = pfx + n;
      toggleFieldVisibility(field_name, state);
    });
  
    // Hide table cells
    if (!state) {
      cfgTool_hideTableValues(module_name_pfx);
    }
  }
  
  function cfgTool_hideConfigSection(module_name_pfx) {
    cfgTool_toggleConfigSection(module_name_pfx, false);
  }
  
  function cfgTool_showConfigSection(module_name_pfx, id) {
    cfgTool_toggleConfigSection(module_name_pfx, true);
  }
  
  function cfgTool_fieldIsUsedInLogic(module_name_pfx, name) {
    if (!cfgTool_vars[module_name_pfx]) {
      console.println(
        'ERROR: Module config for "' + module_name_pfx + '" not found'
      );
      return null;
    }
    return cfgTool_vars[module_name_pfx].logic_field_names[name];
  }
  
  // New line character. We do this because
  // scripts get processed prior to adding to the PDF,
  // and newlines are removed at that stage.
  const nl = String.fromCharCode(10);
  function cfgTool_emailConfig(module_name_pfx) {
    const export_url = cfgTool_getExportUrl(module_name_pfx);
    if (export_url == "") return;
    const { product_family_model_variants_map, product_ids_sku_map } =
    cfgTool_vars[module_name_pfx];

    // Collect selected product names & skus
    const sorted_selected_products =
      cfgTool_getSortedSelectedProducts(module_name_pfx);
    const seenProductIds = new Set();
    const products_msg = sorted_selected_products
      .map(({id,  model }, idx) => {
        // might be duplicates if product has multiple skus
        if (seenProductIds.has(id)) return "";
        seenProductIds.add(id);
        const sku = product_ids_sku_map[id];
        if (idx === 0) {
          const name = product_family_model_variants_map[id];
          return name + " (" + sku + ")";
        } else {
          return " + " + model + " (" + sku + ")";
        }
      })
      .join(nl);
  
    // const header_msg = 'WorkForce Enterprise Series' + nl + nl;
    //const link_msg = nl + nl + export_url;
    // const msg = header_msg + products_msg;
    const msg = products_msg;
  
    app.mailMsg({
      bUI: true,
      cTo: "",
      cSubject: "Epson WorkForce Enterprise Configuration",
      cMsg: msg,
    });
  }
  
  // Product IDs are all greater than 0
  function cfgTool_getProduct(module_name_pfx, field) {
    if (
      !cfgTool_vars[module_name_pfx] ||
      !cfgTool_vars[module_name_pfx].product_names_map ||
      !field
    ) {
      return null;
    }
  
    const product_name = cfgTool_getFieldValue(module_name_pfx, field);
    const map = cfgTool_vars[module_name_pfx].product_names_map;
    if (!map[product_name]) return null;
  
    return map[product_name];
  }
  
  function cfgTool_getFieldValue(module_name_pfx, field) {
    switch (field.type) {
      case "checkbox":
        if (field.value === "Off") return "";
        const vars = cfgTool_vars[module_name_pfx];
  
        const product_id = vars.checkbox_products[field.name];
        const product_name = vars.product_ids_name_map[product_id];
        if (!product_name) {
          console.println(
            "ERROR: product not found [" + field.name + "]: " + product_id
          );
          return "";
        }
        return product_name;
  
      default:
        return field.value;
    }
  }
  
  function cfgTool_fieldChanged(module_name_pfx, field_name) {
    var field = getField(field_name);
    // Skip processing if current value matches previous one
    if (field.value == cfgTool_prev_vars[field_name]) {
      return;
    }
  
    cfgTool_prev_vars[field_name] = field.value;
    const { dropdown_products } = cfgTool_vars[module_name_pfx];
    const dropdownIdx = Object.keys(dropdown_products).indexOf(field_name);
    if (dropdownIdx !== -1) {
      // adjust whether child dropdowns are enabled/disabled
      cfgTool_dropdownChanged(module_name_pfx, dropdownIdx, dropdownIdx + 1);
    }
    cfgTool_reprocessLogic(module_name_pfx);
  }

  function cfgTool_disableDropdown(module_name_pfx, field_name, disabled_state) {
    const layerPrefix = module_name_pfx + "_helper_";
    const layer = layerPrefix + field_name + "_visibility";
    const field = getField(field_name);
    field.readonly = disabled_state;
    if (!!disabled_state) hideField(field_name);
    else showField(field_name);
    toggleLayer(layer, disabled_state);
  }

  // if current dropdown options aren't the original, reset them
  function cfgTool_resetDropdownOptions(module_name_pfx, field_name) {
    const originalOptions = cfgTool_dropdown_options_state[module_name_pfx][field_name];
    const field = getField(field_name);
    //if lengths are different, right away switch
    var updateOptions = field.numItems !== originalOptions.length;
    //otherwise compare the labels and values to be sure
    if (!updateOptions) {
      originalOptions.forEach((option, idx) => {
        const [label, value] = option;
        if (field.getItemAt(idx) !== value || field.getItemAt(idx, false) !== label) {
          updateOptions = true;
          return;
        }
      });
    }
    if (updateOptions) field.setItems(originalOptions);
  }
  
  // force disable and reset the "child" dropdown if parent changes
  // if childIndex is null use parentIndex+1
  function cfgTool_dropdownChanged(module_name_pfx, parentIndex, childIndex) {
    const { project, checkbox_products, logic_field_names_v2, same_dimensions } =
      cfgTool_vars[module_name_pfx];
    const dropdownName = module_name_pfx + "_content_dropdown-";
    const isPrimaryDropdown = parentIndex === 0;
    const parentDropdownName = dropdownName + parentIndex;
    const parentDropdown = getField(parentDropdownName);
    if (!parentDropdown) return;
    const parentReset = parentDropdown.value === parentDropdown.getItemAt(0);
    var childDropdownName = dropdownName + childIndex;
    var childDropdown = getField(childDropdownName);
    // reset and disable all children dropdowns
    //only condition when this shouldn't happen is when it's primary and same dimensions
    if (!isPrimaryDropdown || !same_dimensions || parentReset) {
      var i = 0;
      while (!!childDropdown) {
        childDropdown.value = childDropdown.getItemAt(0);
        cfgTool_prev_vars[childDropdownName] = childDropdown.value;
        cfgTool_disableDropdown(module_name_pfx, childDropdownName, true);
        cfgTool_resetDropdownOptions(module_name_pfx, childDropdownName);
        i += 1;
        childDropdownName = dropdownName + (childIndex + i);
        childDropdown = getField(childDropdownName);
      }
    }
    // if the parent dropdown wasn't reset, re-enable the direct child dropdown
    if (!parentReset) {
      childDropdownName = dropdownName + childIndex;
      childDropdown = getField(childDropdownName);
      if (!!childDropdown) {
        cfgTool_disableDropdown(module_name_pfx, childDropdownName, false);
        cfgTool_resetDropdownOptions(module_name_pfx, childDropdownName);
      }
    } 
    var match_idx = cfgTool_getImageIndexToShow(module_name_pfx);
    // if there's no match, reset options that affect dimensions
    if (match_idx < 0) {
      // try reset additional options one by one to only deal with the problematic one
      const checkboxFieldsSet = new Set(Object.keys(checkbox_products));
      logic_field_names_v2.every((field_name) => {
        const field = getField(field_name);
        if (checkboxFieldsSet.has(field_name)) {
          if (field.type === "checkbox") {
            const oldValue = field.value;
            if (field.value !== "Off") {
              cfgTool_resetField(field, field_name);
              match_idx = cfgTool_getImageIndexToShow(module_name_pfx);
              if (match_idx > -1) return false;
              else {
                field.value = oldValue;
                cfgTool_prev_vars[field_name] = field.value;
              }
            }
          }
        }
        return true;
      });
      if (match_idx < 0) {
        //if more than one is a problem, reset all of them
        logic_field_names_v2.forEach((field_name) => {
          const field = getField(field_name);
          if (checkboxFieldsSet.has(field_name)) {
            if (field.type === "checkbox") {
              cfgTool_resetField(field, field_name);
            }
            // TODO: INSERT WHAT TO DO IF DROPDOWN WHEN ADJUSTING WF PRO HC
            // since additional options for wf pro hc has dropdowns too
            cfgTool_prev_vars[field_name] = field.value;
          }
        })
      }
    }
  }
  
  function cfgTool_isPrimaryModelSelected(module_name_pfx) {
    var primary_name = module_name_pfx + "_content_dropdown-0";
    var field = getField(primary_name);
    var primary_product = cfgTool_getProduct(module_name_pfx, field);
    return !!primary_product;
  }
  
  // Review provided logic, toggling layers and fields as necessary
  function cfgTool_reprocessLogic(module_name_pfx) {
    // Hide "Selected Model" information until a "Printer Model" is Selected
  
    const is_primary_selected = cfgTool_isPrimaryModelSelected(module_name_pfx);
    if (!is_primary_selected) {
      cfgTool_disableAllFields(module_name_pfx, true);
      cfgTool_hideConfigSection(module_name_pfx);
      return;
    }
  
    // Show config section and selected options
    cfgTool_disableAllFields(module_name_pfx, false);
    cfgTool_showConfigSection(module_name_pfx);
    cfgTool_showImageFromLogic(module_name_pfx);
    cfgTool_disableOptions(module_name_pfx);
    cfgTool_deselectOptions(module_name_pfx);
    cfgTool_updateFieldValues(module_name_pfx);
  }
  
  function cfgTool_disableAllFields(module_name_pfx, disable) {
    const { all_products } = cfgTool_vars[module_name_pfx];
    const primary_name = module_name_pfx + "_content_dropdown-0";
    Object.keys(all_products).forEach((k, idx) => {
      if (k !== primary_name) {
        const field = getField(k);
        if (disable) cfgTool_resetField(field, k);
        field.readonly = disable;
      }
    });
  }

  // reset to initial values if not already the case
  function cfgTool_resetField(field, field_name) {
    if (field.type === "checkbox" && field.value !== "Off") field.value = "Off";
    if (field.type === "combobox" && field.value !== field.getItemAt(0)) field.value = field.getItemAt(0);
    cfgTool_prev_vars[field_name] = field.value;
  }

  // deselect all options associated with deselected IDs
  function cfgTool_deselectOptions(module_name_pfx) {
    if (!cfgTool_deselected_state[module_name_pfx]) return;
    const { reverse_products, product_names_map } = cfgTool_vars[module_name_pfx];
    cfgTool_deselected_state[module_name_pfx].forEach((id, idx) => {
      const field_name = reverse_products[id];
      const field = getField(field_name);
      cfgTool_resetField(field, field_name);
    });
  }
  
  // remove all options associated with disabled IDs
  function cfgTool_disableOptions(module_name_pfx) {
    if (!cfgTool_disabled_state[module_name_pfx]) return;
    const { reverse_products, product_names_map, dropdown_products } = cfgTool_vars[module_name_pfx];
    cfgTool_disabled_state[module_name_pfx].forEach((id, idx) => {
      const field_name = reverse_products[id];
      const field = getField(field_name);
      if (field.type === "checkbox") {
        field.readonly = true;
      } else {
        // get all options excluding the disabled ones
        const originalOptions = cfgTool_dropdown_options_state[module_name_pfx][field_name];
        const newOptions = [originalOptions[0]];
        originalOptions.slice(1).forEach((option, idx) => {
          const [label, value] = option;
          const productId = product_names_map[value].id;
          if (cfgTool_disabled_state[module_name_pfx].indexOf(productId) === -1) {
            newOptions.push([label, value]);
          }
        });
        var updateOptions = newOptions.length !== field.numItems;
        if (!updateOptions) {
          newOptions.forEach((opt, idx) => {
            const [label, value] = opt;
            if (label != field.getItemAt(idx, false) || value != field.getItemAt(idx)) {
              updateOptions = true;
            }
          });
        }
        if (updateOptions) {
          field.setItems(newOptions);
        }
        // if the only option left is the first one, just disable and hide the dropdown
        const isDropdown = Object.keys(dropdown_products).indexOf(field_name) !== -1;
        if (newOptions.length === 1 && isDropdown) {
          cfgTool_disableDropdown(module_name_pfx, field_name, true);
        }
      }
    });
  }
  
  function cfgTool_getExportUrl(module_name_pfx) {
    // Require primary product to be selected
    const is_primary_selected = cfgTool_isPrimaryModelSelected(module_name_pfx);
    if (!is_primary_selected) return "";
  
    const dropdowns = cfgTool_enumerateDropdowns(module_name_pfx);
    const checkboxes = cfgTool_enumerateCheckboxes(module_name_pfx);
  
    var query_key_values = [];
    dropdowns.forEach((d, idx) => {
      var selected_product = cfgTool_getProduct(module_name_pfx, d);
      if (!selected_product) return;
      const { id } = selected_product;
      query_key_values.push("d" + idx + "=" + id);
    });
    var selected_checkbox_product_ids = [];
    checkboxes.forEach((c, idx) => {
      var selected_product = cfgTool_getProduct(module_name_pfx, c);
      if (!selected_product) return;
      const { id } = selected_product;
      selected_checkbox_product_ids.push(id);
    });
  
    if (selected_checkbox_product_ids.length > 0) {
      query_key_values.push("c0=" + selected_checkbox_product_ids.join(","));
    }
  
    const { api_url, project, s3_key } = globals;
    const base_target_url = api_url + "/spe/" + project + "/" + s3_key + "/k?";
    const query_string = query_key_values.join("&");
    const export_url = base_target_url + "cache_key=" + escape(query_string);
    return export_url;
  }
  
  function cfgTool_export(module_name_pfx) {
    const export_url = cfgTool_getExportUrl(module_name_pfx);
    if (export_url !== "") app.launchURL(export_url);
  }
  
  // Initial configuration - attach on_change event to existing levers
  function cfgTool_init() {
    var module_names = Object.keys(cfgTool_vars);
    module_names.forEach((module_name) => {
      cfgTool_dropdown_options_state[module_name] = [];
      cfgTool_disabled_state[module_name] = [];
      cfgTool_deselected_state[module_name] = [];
      cfgTool_match_idx[module_name] = -1;

      cfgTool_initCheckboxListeners(module_name);
      cfgTool_initDropdownListeners(module_name);
  
      // Initialize
      const { dropdown_products } = cfgTool_vars[module_name];
      for (var i = 0; i < Object.keys(dropdown_products).length - 1; i++) {
        cfgTool_dropdownChanged(module_name, i, i + 1);
      }
      // Reprocess logic
      cfgTool_reprocessLogic(module_name);
    });
  }  
  cfgTool_init();
} catch (e) {
  console.println('ERROR - Config tool: ' + e);
}
`;

export default scripts;
