import {
  createBG,
  createCarouselTab,
  createHorizontalBorder,
  createImage,
  createInactiveTabs,
  createTextElementsFromContainer,
  getElementPosition
} from "../util";
import scripts from "./scripts";

function createTabbedSlide(slide, configs, slide_idx) {
  let elements = [];
  const {
    page: { idx: page_idx, height },
    element,
    tab_element,
    module_base_name
  } = configs;
  const content_prefix = `slide-${slide_idx}-`;

  // Active & inactive tabs
  const carousel_tab_options = {
    action: {
      mouseClick: `tabbedContainer_goToSlide("${module_base_name}", ${slide_idx})`
    }
  };
  const tab_active = createCarouselTab(
    tab_element,
    configs,
    slide_idx,
    true,
    carousel_tab_options
  );
  tab_active.alwaysVisible = slide_idx == 0;
  elements.push(tab_active);

  // Table rows
  const table_rows = [...element.querySelectorAll("tr")];
  table_rows.forEach((table_row, row_idx) => {
    // if (row_idx > 1 && slide_idx == 2) {
    //   debugger;
    // }
    const table_row_styles = window.getComputedStyle(table_row);
    const row_bg = createBG(
      table_row,
      configs,
      `${content_prefix}row-bg-${row_idx}`
    );

    // Skip transparent backgrounds
    if (row_bg) {
      elements.push(row_bg);
    }

    const row_divider = createHorizontalBorder(
      table_row,
      configs,
      "borderBottom",
      `${content_prefix}row-divider-${row_idx}`
    );
    if (row_divider) {
      elements.push(row_divider);
    }
  });

  const text_elements = createTextElementsFromContainer(
    element,
    content_prefix,
    configs
  );
  elements = elements.concat(text_elements);

  // Images
  const image_elements = [...element.querySelectorAll(".dynamic-image")];
  image_elements.forEach((image_element, image_idx) => {
    const slide_image = createImage(
      configs,
      image_element,
      `${content_prefix}image-${image_idx}`
    );
    elements.push(slide_image);
  });

  return elements;
}

function createTabbedSlideElements(
  slides,
  configs,
  slide_elements,
  tab_elements
) {
  const mappedSlides = slides.map((slide, slide_idx) => {
    const slide_element = slide_elements[slide_idx];
    if (!slide_element) return null;
    const enhanced_configs = {
      ...configs,
      element: slide_element,
      element_rect: slide_element.getBoundingClientRect(),
      tab_element: tab_elements[slide_idx]
    };

    return {
      name: String(slide_idx),
      // All slides except the first are initially hidden
      hidden: slide_idx > 0,
      elements: createTabbedSlide(slide, enhanced_configs, slide_idx)
    };
  });
  return mappedSlides.filter((e) => e !== null);
}

function loadTabbedContainerConfig(carousel_module, configs) {
  const { page, fonts, containing_element } = configs;
  const { idx: page_idx } = page;
  const name = `tabbed-container_${page_idx}`;
  const enhanced_configs = {
    ...configs,
    module_base_name: name
  };
  // Disregard background image and arrow icons since those are for EvoPDF
  const { html_id, content = {} } = carousel_module;
  const { slides = [], show_arrows } = content;

  const tab_elements = [...containing_element.querySelectorAll(".tab")];

  const slide_elements = [...containing_element.querySelectorAll(".slide")];
  const created_slides = createTabbedSlideElements(
    slides,
    enhanced_configs,
    slide_elements,
    tab_elements
  );
  const ianctive_tab_options = {
    action: {
      mouseClick: (name, idx) => `tabbedContainer_goToSlide("${name}", ${idx})`
    }
  };
  const inactive_tabs = createInactiveTabs(
    tab_elements,
    enhanced_configs,
    ianctive_tab_options
  );

  const elements = [...inactive_tabs, ...created_slides];
  return {
    config: {
      name: name,
      page: page_idx,
      position: getElementPosition(containing_element),
      // Contain all content of a slide within the same element (and PDF layer)
      elements
    }
  };
}
export { loadTabbedContainerConfig, scripts };
