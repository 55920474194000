// Polyfills
import 'react-app-polyfill/ie11';
import 'core-js/modules/esnext.string.match-all';

import React from "react";
import ReactDOM from "react-dom";
// import ReactGA from 'react-ga';
import ProjectRouter from "./ProjectRouter";

// ReactGA.initialize('UA-127419275-4');

ReactDOM.render(<ProjectRouter />, document.getElementById("root"));
