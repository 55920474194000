
import React, { useEffect, useState } from 'react';
import {
  useParams,
  BrowserRouter as Router,
  Link,
  useLocation } from "react-router-dom";
import { css, Global } from '@emotion/core';
import { report_pageview } from '../helpers/GA';


import { spe } from '../helpers/generator/Amplify';

// Removes the href of unnecessary anchors (for single page exports)
const removeUnnecessaryAnchorHrefs = (html) => {
  const matching_links = html.match(/<[a-zA-Z-]+[^>]+removeHrefForSPE[^>]*>/g);
  if (!matching_links)
    return html;

  // Sanitize found anchors
  matching_links.forEach((anchor_str) => {
    const replacement = anchor_str.replace(/href=".*"/, '');
    html = html.replace(anchor_str, replacement);
  });

  return html;
}

// Pre-process HTML
// - Remove promo links that link another page in the full comparison
const processHTML = (html) => {
  html = removeUnnecessaryAnchorHrefs(html);

  return html;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function HTMLRenderer(props) {
  let query = useQuery();
  // Collect route information
  const params = useParams();
  const {
    project, s3Key, objectKey
  } = params;

  const [html, setHTML] = useState('');
  const setError = () => {
    props.setToolbarData({
      name: '',
      page_title: 'Comparison Tool',
      hideDownloadBtn: true,
      hideToolbar: true
    });
  }

  const speLoaded = (res) => {
    // Set styles
    let styles = document.createElement('style');
    styles.id = 'single-page-export';
    styles.innerHTML = res.styles || '';
    document.head.prepend(styles);

    // Process & set HTML
    const processedHTML = processHTML(res.html);
    setHTML(processedHTML);

    props.setToolbarData({
      name: res.page_title,
      page_title: res.page_title,
      hideDownloadBtn: true,
      exportOnInit: query.get("generate") === "1",
      isStandaloneSinglePage: true,
      other: {
        parentBundle: s3Key,
        sliceId: objectKey
      }
    });
  }

  useEffect(() => {
    spe(s3Key, objectKey, project)
      .then((res) => {
        if (res)
          speLoaded(res);
        else
          setError();
        report_pageview();
      })
      // Error! Hide download button
      .catch((error) => {
        setError();
      });
      
  }, [])


  // Project not valid, key not valid, or HTML not ready or invalid
  if (!project || !objectKey || !html) {
    return <React.Fragment/>;
  }

  // Render received HTML
  return (
    <React.Fragment>
      <Global
        styles={css`
          html:not(.export) .spe td, th {
            height: auto !important;
          }

          .spe .sheet {
            margin: 0;
          }
        `}
      />
      <div id="html-container" className="sheet spe" dangerouslySetInnerHTML={{ __html: html }}/>
    </React.Fragment>
  );
}