import React from "react";

const CollectionsContext = React.createContext({});
const DisclaimerProcessorContext = React.createContext({});
const SelectedLocaleContext = React.createContext({});
const ExposeContentContext = React.createContext({});
const PageInstanceContext = React.createContext({});
const PagesToExportContext = React.createContext({});
const UrlParamsContext = React.createContext({});
const ProjectContext = React.createContext({});

export {
  CollectionsContext,
  DisclaimerProcessorContext,
  SelectedLocaleContext,
  ExposeContentContext,
  PageInstanceContext,
  PagesToExportContext,
  UrlParamsContext,
  ProjectContext
};
