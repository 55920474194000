import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import RepeatIcon from "@material-ui/icons/Repeat";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import CascadingMenu from "./CascadingMenu";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

// import { report_pdf_success, report_pdf_error } from '../../helpers/GA';
import { poll } from "../../helpers/generator/Amplify";
import {
  cleanupHtmlForExport,
  submitHtml
} from "../../helpers/generator/Exporter";
import { set_cte_cookie, get_cte_cookie } from "../../helpers/access_code";

import DropdownArrowWithPopover from "./DropdownArrowWithPopover";
import { useHistory, useLocation } from "react-router-dom";
import { updateSearchParam } from "../../helpers/ScannersBijFunctions";
import { UrlParamsContext } from "../context";
import EmailInputDialog from "../components/dialogs/EmailInputDialog";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex"
    },
    appBar: {
      height: "64px"
    },
    toolbarButtons: {
      marginLeft: "auto"
    },
    snackbarError: {
      backgroundColor: "red"
    },
    snackbarSuccess: {
      backgroundColor: "green"
    },
    snackbarNormal: {
      backgroundColor: "#122448"
    },
    progress: {
      width: "100%",
      position: "fixed",
      top: "64px",
      zIndex: "50",
      transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms"
    },
    backToPreview: {
      padding: 0,
      marginRight: theme.spacing(2)
    }
  }),
  { meta: "removeForExport" }
);

function HideOnScroll(props) {
  const { children, window, waiting } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const classes = useStyles();

  return (
    <React.Fragment>
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
      {waiting && (
        <LinearProgress
          classes={{ root: classes.progress }}
          style={{ transform: !trigger ? "none" : "translateY(-64px)" }}
        />
      )}
    </React.Fragment>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const snackbar_states = {
  hidden: {},
  error: {
    text: "Error: ",
    style: "snackbarError"
  },

  preparing: {
    text: {
      default: "Preparing HTML",
      spe: "Preparing to generate PDF"
    },
    delay: {
      default: 8000,
      spe: 2000
    }
  },
  submitting: {
    text: "Submitting HTML",
    delay: {
      default: 3000,
      spe: 2000
    }
  },
  waiting: {
    text: "Generating PDF"
  },
  security: {
    text: "Adding security settings",
    delay: 2000
  },
  appending: {
    text: "Appending PDFs",
    delay: 3000
  },
  interactivity: {
    text: "Adding interactivity",
    delay: 3000
  },

  received: {
    text: "PDF Generated Successfully",
    style: "snackbarSuccess"
  },

  background: {
    text: "PDF bundle will be emailed to the provided address.",
    style: "snackbarSuccess"
  },

  "background-in-progress": {
    text: "PDF bundle already submitted. It will be emailed to the provided address once completed.",
    style: "snackbarSuccess"
  },

  "checking-cached-pdf": {
    text: "Checking for cached PDF"
  },

  // To be displayed momentarily after PDF is received
  "caching-pdf": {
    text: "Caching PDF",
    flashDelay: 500
  },

  "generating-hash": {
    text: "Generating PDF hash",
    delay: 1000
  },

  "cache-404": {
    text: "No cached PDF found",
    delay: 1000
  },

  "pdf-exists": {
    text: "PDF already generated",
    style: "snackbarSuccess"
  }
};
const priority_states = {
  received: true,
  "pdf-exists": true,
  background: true,
  "background-in-progress": true,
  error: true,
  hidden: true
};
const priority_states_array = Object.keys(priority_states);

function stateIsPriority(state) {
  return !!priority_states[state];
}
const pdf_states_download = {
  received: true,
  "pdf-exists": true
};
function stateSupportsDownloadButton(state) {
  return !!pdf_states_download[state];
}

const pdf_states_dismissSnackbar = {
  error: true,
  background: true,
  "background-in-progress": true
};
function stateSupportsDismissButton(state) {
  return !!pdf_states_dismissSnackbar[state];
}

const DEFAULT_SNACKBAR_VISIBILITY_PERIOD = 2000;
const DEFAULT_STATE = "hidden";
const POLL_DELAY = 1 * 1000;
const POPUP_DELAY = 100;

export default function AppToolbar(props) {
  const [waiting, setWaiting] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ state: DEFAULT_STATE });
  const [addedState, setAddedState] = useState(null);
  const [queuedSnackbarStates, setQueuedSnackbarStates] = useState([]);
  const [downloaded, setDownloaded] = useState(false);
  const [timeoutId, setTimeoutId] = useState(-1);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [forcedExport, setForcedExport] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState(get_cte_cookie());

  const {
    pdfExportData,
    setIdOfSinglePageElementToExport,
    idOfSinglePageElementToExport,
    globalSettings,
    exposeContent,
    pagesToExport
  } = props;

  const dialogConfirm = (email) => {
    setEmailDialogOpen(false);
    set_cte_cookie(email);
    setSelectedEmail(email);
    initiatePdfGeneration(forcedExport, email);
  };
  const dialogCancel = () => {
    setEmailDialogOpen(false);
  };

  const isBackgroundExport = pagesToExport?.length > 0;

  const export_config = {
    ...(pdfExportData || {}),
    idOfSinglePageElementToExport: idOfSinglePageElementToExport || ""
  };

  const classes = useStyles();

  // "SPE" alone is different from "standalone" in that "SPE" has a back button
  const is_spe = !!idOfSinglePageElementToExport;
  const is_standalone = props.pdfExportData?.isStandaloneSinglePage;
  const page_type = is_standalone || is_spe ? "spe" : "default";

  let stateInfo = snackbar_states[snackbarState.state] || {};
  let snackbarData = snackbarState.data;
  let snackbarText = stateInfo?.text?.[page_type] || stateInfo?.text;
  let snackbarStyle = classes[stateInfo?.style || "snackbarNormal"];

  // Snackbar states handler
  useEffect(
    function () {
      let prevState = queuedSnackbarStates[queuedSnackbarStates.length - 1];
      if (
        addedState &&
        prevState !== addedState &&
        addedState !== snackbarState
      ) {
        if (stateIsPriority(addedState.state)) {
          setQueuedSnackbarStates([addedState]);
          setAddedState(null);
        } else setQueuedSnackbarStates([...queuedSnackbarStates, addedState]);
      }
    },
    [addedState, queuedSnackbarStates]
  );

  function nextSnackbarState() {
    let nextState = queuedSnackbarStates[0];
    setQueuedSnackbarStates(queuedSnackbarStates.slice(1));
    setSnackbarState(nextState);
    return nextState;
  }

  // Skip waiting when the added state is received
  useEffect(() => {
    if (stateIsPriority(addedState?.state)) {
      setWaiting(false);
    }
  }, [addedState]);

  // Update current state by toggling "waiting"
  useEffect(() => {
    if (queuedSnackbarStates.length > 0) {
      if (!waiting) {
        let nextState = nextSnackbarState();
        // select a delay for this message
        let state_name = nextState.state;
        let delay = DEFAULT_SNACKBAR_VISIBILITY_PERIOD;
        if (snackbar_states[state_name]?.delay > 0)
          delay = snackbar_states[state_name].delay;
        else if (snackbar_states[state_name]?.delay?.[page_type] > 0)
          delay = snackbar_states[state_name]?.delay?.[page_type];

        if (!stateIsPriority(nextState?.state)) {
          setWaiting(true);
          clearTimeout(timeoutId);
          var nextTimeoutId = setTimeout(() => {
            setWaiting(false);
          }, delay);

          setTimeoutId(nextTimeoutId);
        }
      }
    }
  }, [queuedSnackbarStates, waiting, addedState, snackbarState]);

  if (snackbarState.state === "error") {
    if (snackbarData.toLowerCase().startsWith("error: "))
      snackbarText = snackbarData;
    else snackbarText += snackbarData;
  }

  let exportDisabled = !stateIsPriority(snackbarState.state);

  const queue_state = (state, data) => {
    setAddedState({ state: state, data: data });
  };

  const { urlParams, setUrlParams } = React.useContext(UrlParamsContext);
  const backToPreview = () => {
    const { location } = urlParams;
    const params = new URLSearchParams(location.search);
    const isQclSpe = location?.pathname?.includes("qcl-spe");
    const oldPath = `${location.pathname.replace(
      "qcl-spe",
      "landing"
    )}?lang=${params.get("lang")}`;
    const old_id = idOfSinglePageElementToExport;
    updateSearchParam(urlParams, setUrlParams, "spe", null);
    setIdOfSinglePageElementToExport("");
    // if from quick compare landing, go back there
    if (isQclSpe) window.location.href = oldPath;
    // Return user back to the previously-isolated sheet
    else if (old_id) window.location.href = `#${old_id}`;
  };

  const setState_checkingCachedPdf = () => queue_state("checking-cached-pdf");
  const setState_cache404 = () => queue_state("cache-404");
  const setState_generatingHash = () => queue_state("generating-hash");
  const setState_cachingPdf = () => queue_state("caching-pdf");
  const setState_hidden = () => queue_state("hidden");
  const setState_preparing = () => queue_state("preparing");
  const setState_submitting = () => queue_state("submitting");
  // const setState_bundleExists = () => queue_state('bundle-exists');

  const setState_background = () => queue_state("background");
  const setState_backgroundAlreadySubmitted = () =>
    queue_state("background-in-progress");
  const setState_received = (url) => queue_state("received", url);
  const setState_pdfExists = (url) => queue_state("pdf-exists", url);
  const setState_error = (error) => queue_state("error", error);

  const queue_waiting_states = () => {
    queue_state("security");
    queue_state("appending");
    queue_state("interactivity");
    // queue_state('download');
  };

  function initiatePdfGeneration(forced, email) {
    setState_checkingCachedPdf();
    try {
      cleanupHtmlForExport()
        .then(function (clonedDoc) {
          // Submit PDF
          return submitHtml(
            clonedDoc,
            export_config,
            props.projectType,
            props.selectedContact,
            props.selectedLocale,
            forced,
            exposeContent?.value || false,
            pagesToExport || [],
            email
          );
        })
        .then(function (submitResponse) {
          const data = JSON.parse(submitResponse.body);
          const {
            s3Key,
            rejected,
            bundleExists,
            pdfExists,
            existingPdfUrl,
            message
          } = data;
          if (rejected) {
            console.error("PDF generation request rejected.", data);
            // report_pdf_error(e.message || e);
            setState_error("PDF generation request rejected.");
            // } else if (bundleExists) {
            //   setState_bundleExists();
          } else if (isBackgroundExport && bundleExists) {
            setState_backgroundAlreadySubmitted();
          } else if (isBackgroundExport) {
            setState_background();
          } else if (pdfExists) {
            setState_pdfExists(existingPdfUrl);
          } else if (!s3Key) {
            setState_error(message);
          } else {
            // Add illusory messages for exports
            setState_cache404();
            setState_generatingHash();
            setState_preparing();
            setState_submitting();

            // "Full" (not single page) exports
            if (
              props.projectType !== "projector-lenses" &&
              !idOfSinglePageElementToExport &&
              !is_standalone
            )
              queue_waiting_states();

            queue_state("waiting");

            // Present different message for existing bundles?
            // if (bundleExists) { }

            // Begin polling
            _poll(s3Key, props.projectType);
          }
        })
        .catch((e) => {
          console.error(e);
          // report_pdf_error(e.message || e);
          setState_error(e.message || e);
        });
    } catch (e) {
      console.error(e);
      // report_pdf_error(e.message || e);
      setState_error(e.message || e);
    }
  }

  let submitClicked = (forced) => {
    if (isBackgroundExport) {
      setEmailDialogOpen(true);
    } else {
      initiatePdfGeneration(forced);
    }
  };

  const pollForGenerationResult = (s3Key, type) => {
    // Request PDF by name (s3Key)
    return poll(s3Key, type).then((res) => {
      if (res.body.error) {
        // Report to GA
        // report_pdf_error(res.body.error);

        // Retain error
        setState_error(res.body.error);
      } else if (!res.body.url) {
        // Poll again - PDF not ready
        _poll(s3Key, type);
      } else {
        // Report to GA
        // report_pdf_success(props.projectType);

        // Update state after flashing message
        setState_cachingPdf();

        // Retain PDF URL
        const cachingState = snackbar_states["caching-pdf"];
        setTimeout(
          () => setState_received(res.body.url),
          cachingState.flashDelay
        );
      }
    });
  };

  const _poll = (s3Key, type) => {
    setTimeout(() => pollForGenerationResult(s3Key, type), POLL_DELAY);
  };

  const openPDFInNewTab = (url) => {
    // Pop-up generated PDF
    setTimeout(function () {
      window.open(url, "_blank");
    }, POPUP_DELAY);
  };

  const forceCloseSnackbar = (url) => {
    openPDFInNewTab(url);
    dismissSnackbar();
  };

  const dismissSnackbar = () => {
    setState_hidden();
    setWaiting(false);
  };

  // Automatically initiate download after a short delay
  const exportOnInit = props.pdfExportData?.exportOnInit;
  useEffect(() => {
    if (exportOnInit && !downloaded) {
      setDownloaded(true);
      setTimeout(() => submitClicked(), 500);
    }
  }, [exportOnInit, downloaded]);

  const showProgressBar =
    priority_states_array.indexOf(snackbarState.state) === -1;
  // const showProgressBar = snackbarState.state === 'waiting';
  const locales = props.pdfExportData?.locales;
  const contacts = props.pdfExportData?.contacts;
  const audiences = props.pdfExportData?.audiences;
  const exposeContentOptions = props.pdfExportData?.exposeContentOptions;
  const pagesToExportOptions = props.pdfExportData?.pagesToExportOptions;
  const page_title = props.pdfExportData?.page_title || "";
  const spe_text = is_spe || is_standalone ? ": Single Page Export" : "";
  const toolbar_title = `${page_title}${spe_text}`;

  if (
    !pdfExportData ||
    (typeof pdfExportData === "object" &&
      Object.keys(pdfExportData).length === 0)
  )
    return <React.Fragment />;

  const enableRegeneratePdf = snackbarState.state === "pdf-exists";
  const enableDismissSnackbar =
    ["pdf-exists", "received"].indexOf(snackbarState.state) > -1;

  const show_cascading_menu = !!contacts || !!audiences || !!locales;

  function localeCompare() {
    if (!props.selectedLocale) {
      return null;
    }
    if (typeof props.selectedLocale === "string") {
      return locales.find(
        (locale) =>
          locale.language.toLowerCase() === props.selectedLocale.toLowerCase()
      );
    }
    return locales.find((locale) => locale.id === props.selectedLocale.id);
  }

  const menu_options = [
    {
      name: "languages",
      title: "Locale",
      iconName: "language",
      comparisonKey: "language",
      labelKey: "name",
      selectedItem: localeCompare() || locales?.[0],
      selectItem: props.setLocale,
      items: locales
    },
    {
      name: "contacts",
      title: "Contacts",
      iconName: "contacts",
      defaultOption: "None",
      comparisonKey: "id",
      labelKey: "name",
      selectedItem: props.selectedContact,
      selectItem: props.setContact,
      items: contacts || []
    },
    {
      name: "audiences",
      title: "Audiences",
      iconName: "people",
      comparisonKey: "key",
      labelKey: "name",
      selectedItem: props.selectedAudience,
      selectItem: props.setAudience,
      items: audiences || [],
      useComparisonKeyForValue: true
    },
    {
      name: "exposeContent",
      title: "Expose Content",
      iconName: {
        selected: "check_box",
        unselected: "check_box_outline_blank"
      },
      comparisonKey: "value",
      labelKey: "name",
      selectedItem: props.exposeContent || exposeContentOptions?.[1],
      selectItem: props.setExposeContent,
      items: exposeContentOptions
    },
    {
      name: "pagesToExport",
      title: "Comparisons",
      iconName: "rule",
      comparisonKey: "value",
      labelKey: "name",
      selectedItem: props.pagesToExport,
      selectItem: props.setPagesToExport,
      defaultOption: "All",
      items: is_spe ? [] : pagesToExportOptions
    }
  ];

  return (
    <div className={classes.root + " removeForExport"}>
      <EmailInputDialog
        open={emailDialogOpen}
        selectedEmail={selectedEmail}
        cancel={dialogCancel}
        confirm={dialogConfirm}
      />
      <HideOnScroll waiting={showProgressBar}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar style={{ height: "100%" }}>
            {is_spe && (
              <IconButton
                title="Back to Preview"
                className={classes.backToPreview}
                size="small"
                color="secondary"
                disableRipple={true}
                disableFocusRipple={true}
                onClick={backToPreview}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h1" noWrap>
              {toolbar_title}
            </Typography>
            <div className={classes.toolbarButtons}>
              {props.pdfExportData && !props.pdfExportData?.hideDownloadBtn && (
                <IconButton
                  aria-label="Download"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  size="small"
                  variant="contained"
                  color="secondary"
                  disabled={exportDisabled}
                  onClick={() => submitClicked()}
                >
                  <CloudDownloadIcon color="secondary" />
                </IconButton>
              )}
              {show_cascading_menu && (
                <CascadingMenu
                  options={menu_options}
                  globalSettings={globalSettings}
                />
              )}
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={true}
        className={`${snackbarStyle} removeForExport`}
        message={snackbarText}
        style={{ display: snackbarState.state === "hidden" ? "none" : "" }}
      >
        <SnackbarContent
          message={snackbarText}
          className={snackbarStyle}
          action={
            <React.Fragment>
              {stateSupportsDismissButton(snackbarState.state) && (
                <Button
                  color="secondary"
                  size="small"
                  onClick={dismissSnackbar}
                >
                  Dismiss
                </Button>
              )}
              {stateSupportsDownloadButton(snackbarState.state) && (
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => forceCloseSnackbar(snackbarData)}
                >
                  Download
                </Button>
              )}
              {(enableRegeneratePdf || enableDismissSnackbar) && (
                <DropdownArrowWithPopover
                  idSuffix="state-snackbar"
                  menuItems={[
                    // Allow re-generation only for the 'exists' state
                    ...(enableRegeneratePdf
                      ? [
                          {
                            onClick: () => submitClicked(true),
                            content: "Re-generate PDF",
                            Icon: RepeatIcon,
                            iconProps: { size: "small" }
                          }
                        ]
                      : []),
                    // Show "dismiss" under dropdown only for success states
                    ...(enableDismissSnackbar
                      ? [
                          {
                            onClick: () => dismissSnackbar(),
                            content: "Dismiss",
                            Icon: CloseIcon,
                            iconProps: { size: "small" }
                          }
                        ]
                      : [])
                  ]}
                />
              )}
              {/*<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>*/}
              {/*  <CloseIcon fontSize="small" />*/}
              {/*</IconButton>*/}
            </React.Fragment>
          }
        />
      </Snackbar>
    </div>
  );
}
