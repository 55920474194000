import React, { useState } from "react";
import styled from "@emotion/styled";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function EmailInputDialog(props) {
  const {
    selectedEmail = "",
    open = false,
    cancel = () => {},
    confirm = (email) => {}
  } = props;
  const [inputEmail, setInputEmail] = useState(selectedEmail);

  const handleConfirm = () => {
    confirm(inputEmail);
  };
  const inputChanged = (e) => {
    setInputEmail(e.target.value);
  };

  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>Generate Comparisons</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your email to receive the generated bundle of individual product
          comparison PDFs.
          <br />
          This process takes a few minutes.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          defaultValue={selectedEmail}
          fullWidth
          variant="standard"
          onChange={inputChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
