import {
  getElementPosition,
  getRelativeElementPosition,
  getRgbColorAsHex,
  getBorderProps,
  getRoundedRadius,
  getBgProps,
  pxToPt,
  processNumber,
  createText,
  createTextElementsFromContainer,
  createImage,
  createCta,
  createButton,
  createButton_v2
} from "../util";

import scripts from "./scripts";

function createHoverButton(button, configs, hotspot_idx, options = {}) {
  const btn_rect = button.getBoundingClientRect();

  // getRelativeElementPosition(text_rect, configs.page)
  const {
    page: { idx: page_idx, height }
  } = configs;
  return {
    name: `hover-button`,
    alwaysVisible: true,
    page: page_idx,
    position: getRelativeElementPosition(btn_rect, configs.page),
    ...options
    // action: {
    //   // hotspots_2_0_hover-button-
    //   mouseEnter: `showPopup('hotspots_${page_idx}_${hotspot_idx}')`,
    //   mouseExit: `hidePopup('hotspots_${page_idx}_${hotspot_idx}')`
    // }
  };
}

function getBgPosition(configs) {
  const { page = {}, element_rect } = configs;
  return {
    x: pxToPt(element_rect.x - page.x),
    y: pxToPt(page.height - (element_rect.y - page.y) - element_rect.height),
    width: pxToPt(element_rect.width),
    height: pxToPt(element_rect.height)
  };
}

const createPopupBG = (configs, computed_bg_style, label = "bg") => ({
  name: label,
  page: configs.page.idx,
  position: getRelativeElementPosition(
    configs.element.getBoundingClientRect(),
    configs.page
  ),
  ...getBgProps(computed_bg_style)
});

const createPopupBorder = (configs, computed_bg_style) => ({
  name: "popup-bg-border",
  page: configs.page.idx,
  position: getBgPosition(configs),
  ...getBorderProps(computed_bg_style.border),
  borderRadius: getRoundedRadius(computed_bg_style)
});

// We create a new object for every "action" option
// in case one of them is modified.
const createPopupControllerActions = (html_id, hotspot_idx) => ({
  mouseEnter: `showPopup('${html_id}', ${hotspot_idx})`,
  mouseExit: `hidePopup('${html_id}', ${hotspot_idx})`
});

function createHotspotPopupElements(hotspot, configs, hotspot_idx) {
  let elements = [];
  const {
    page: { idx: page_idx, height },
    html_id
  } = configs;

  // Hover button
  const hover_button = createHoverButton(
    hotspot,
    configs,
    hotspot_idx,
    {
      form: {
        type: "button",
        action: createPopupControllerActions(html_id, hotspot_idx)
      }
    }
    // popup_controller_options
  );
  elements.push(hover_button);

  // Popup BG
  const computed_bg_style = window.getComputedStyle(configs.element);
  const popup_bg = createPopupBG(configs, computed_bg_style, "popup-bg");
  elements.push(popup_bg);

  // Popup image
  const figure = configs.element.querySelector("figure");
  const image = figure?.querySelector?.("img");
  if (image && image.src) {
    const popup_image = createImage(configs, image, "popup-image");
    elements.push(popup_image);
  }

  // Popup BG Border
  const popup_border = createPopupBorder(configs, computed_bg_style);
  elements.push(popup_border);

  const text_elements = createTextElementsFromContainer(
    configs.element,
    "popup-text",
    configs
  );

  elements = elements.concat(text_elements);

  // CTA 1
  const popup_cta_1_element = configs.element.querySelector(".cta.primary");
  if (popup_cta_1_element) {
    const cta1 = createCta(popup_cta_1_element, configs, "popup-cta-1");
    elements.push(cta1);
  }

  // CTA 2
  const popup_cta_2_element = configs.element.querySelector(".cta.secondary");
  if (popup_cta_2_element) {
    let cta2 = createCta(popup_cta_2_element, configs, "popup-cta-2");
    const computed_cta2_styles = window.getComputedStyle(popup_cta_2_element);
    cta2 = {
      ...cta2,
      ...getBorderProps(computed_cta2_styles.border)
    };

    elements.push(cta2);
  }

  // Create clickable button for the popup, which will act as the primary CTA.
  // When there are two CTAs, we create one button to cover the top part of the popup and
  // including the first CTA while creating a second button to cover the rest for the second one
  // We create one button regardless of CTA count so that the hover state can be processed.

  // Create a container button for the entire popup
  let popup_container = createButton_v2(configs.element, configs, {
    name: "popup-container",
    action: createPopupControllerActions(html_id, hotspot_idx)
  });

  // Both CTAs
  if (popup_cta_1_element && popup_cta_2_element) {
    // Resize popup_container so it covers up to the first element,
    // then create another container for the second button

    // Bounds of both CTAs, so we can get the half-way point
    const cta1_rect = popup_cta_1_element.getBoundingClientRect();
    const cta1_pos = getRelativeElementPosition(cta1_rect, configs.page);

    const cta2_rect = popup_cta_2_element.getBoundingClientRect();
    const cta2_pos = getRelativeElementPosition(cta2_rect, configs.page);

    const dist_between_ctas = cta1_pos.y - (cta2_pos.y + cta2_pos.height);

    const halfway_y = cta2_pos.y + cta2_pos.height + dist_between_ctas / 2;

    const { position: popup_pos } = popup_container;

    const top_height = popup_pos.height + popup_pos.y - halfway_y;
    const bottom_container_height = popup_pos.height - top_height;

    popup_container.position.y += bottom_container_height;
    popup_container.position.height = top_height;

    const cta1_href = popup_cta_1_element.href.replace(
      window.location.href,
      ""
    );
    if (cta1_href !== "#") {
      popup_container.form.action.mouseClick = `app.launchURL('${popup_cta_1_element.href}')`;
    }

    elements.push(popup_container);
    // Clone object without reference (in an awkward way)
    let bottom_container = JSON.parse(JSON.stringify(popup_container));
    bottom_container.position.y =
      popup_container.position.y - bottom_container_height;
    bottom_container.position.height = bottom_container_height;

    const cta2_href = popup_cta_2_element.href.replace(
      window.location.href,
      ""
    );
    if (cta2_href !== "#") {
      bottom_container.form.action.mouseClick = `app.launchURL('${popup_cta_2_element.href}')`;
    }

    elements.push(bottom_container);
  } else {
    // No CTAs
    if (!popup_cta_1_element && !popup_cta_2_element) {
    }
    // One CTA
    else {
      const cta_element = popup_cta_1_element || popup_cta_2_element;
      const href = cta_element.href.replace(window.location.href, "");
      if (cta_element.href && href !== "#") {
        popup_container.form.action.mouseClick = `app.launchURL('${cta_element.href}')`;
      }
    }

    elements.push(popup_container);
  }
  return elements;
}

function loadHotspotsConfig(hotspots_module, configs) {
  const { page, fonts, containing_element } = configs;
  const { idx: page_idx } = page;
  // Disregard background image and hotspot icons since those are for EvoPDF
  const { html_id, content = {} } = hotspots_module;
  const { hotspots = [] } = content;

  const popup_elements = containing_element.querySelectorAll(`.hotspot-popup`);
  const hotspot_elements =
    containing_element.querySelectorAll(`.hotspot-button`);

  const created_hotspot_buttons = hotspots.flatMap((hotspot, hotspot_idx) => {
    const popup = popup_elements[hotspot_idx];
    const hotspot_element = hotspot_elements[hotspot_idx];
    const btn_rect = hotspot_element.getBoundingClientRect();
    const computed_bg_style = window.getComputedStyle(hotspot_element);
    const bg_props = getBgProps(computed_bg_style, true);
    const shorted_bg_color = (bg_props.borderColor || "").substring(0, 7);
    const enhanced_bg_props = {
      ...bg_props,
      borderColor: shorted_bg_color,
      borderRadius: 13
    };
    return {
      name: "" + hotspot_idx,

      // getRelativeElementPosition(text_rect, configs.page)
      alwaysVisible: true,
      position: getRelativeElementPosition(btn_rect, configs.page),
      ...enhanced_bg_props
    };
  });

  const created_popups = hotspots.map((hotspot, hotspot_idx) => {
    const popup = popup_elements[hotspot_idx];
    const hotspot_element = hotspot_elements[hotspot_idx];
    const enhanced_configs = {
      ...configs,
      element: popup,
      element_rect: popup.getBoundingClientRect(),
      html_id
    };

    return {
      name: String(hotspot_idx),
      // All popups are initially hidden
      hidden: true,
      elements: createHotspotPopupElements(
        hotspot_element,
        enhanced_configs,
        hotspot_idx
      )
    };
  });

  return {
    config: {
      name: html_id, //`hotspots_${page_idx}`,
      page: page_idx,
      position: getElementPosition(containing_element),
      // Contain all content of a hotspot within the same element (and PDF layer)
      elements: [
        { name: "hotspots", elements: created_hotspot_buttons },
        ...created_popups
      ]
    },
    prefix: html_id,
    script_variables: {
      popup_delay: 200,
      total_hotspots: hotspots.length
    }
  };
}

export { loadHotspotsConfig, scripts };
