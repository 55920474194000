import { get_db_base, request_items } from "./DB";
import { find } from "lodash";

/**
 * Removes leading & trailing (given) tags
 */
export const remove_wrapping_tag = (str, tag) => {
  if (!str) return "";
  // remove wrapping tag
  const regexp_leading = new RegExp("^<" + tag + ".*?>");
  const regexp_trailing = new RegExp("</" + tag + ".*?>$");

  return str.replace(regexp_leading, "").replace(regexp_trailing, "");
};

/**
 * Removes leading & trailing P tags
 */
export const remove_p_tag = (str) => remove_wrapping_tag(str, "p");

/**
 * Removes all tags
 */
export const remove_all_tags = (str) =>
  (str || "").replace(/<([^ ]+) ?.*?>.*<\/\1>/g, "");

/**
 * Returns the index of the given .sheet element within its .sheet siblings
 */
export const index_of_sheet = (sheetId) => {
  var target_sheet = document.querySelector(sheetId);
  if (!target_sheet) {
    console.error("target_sheet for: " + sheetId);
    return 0;
  }

  var sheets_container = target_sheet.parentNode;
  if (!sheets_container) {
    console.error("sheets_container for: " + sheetId);
    return 0;
  }

  var all_sheets = [...sheets_container.querySelectorAll(".sheet")];
  var target_sheet_idx = all_sheets.indexOf(target_sheet);
  if (target_sheet_idx === -1) {
    console.error("target_sheet_idx for: " + sheetId);
    return 0;
  }
  return target_sheet_idx;
};

/**
 * Synchronizes the heights of rows across multiple columns, enables variable row height in when <table> is not used.
 * Adapted from https://benhowdle.im/easy-peasy-equal-heights.html
 * @param selector is the attribute and value that marks all the items you want to synchronize
 */
export const syncRowHeights = (selector) => {
  var selector = selector || '[datakey="sameHeights-default"]',
    query = document.querySelectorAll(selector),
    elements = query.length,
    max = 0;
  if (elements) {
    while (elements--) {
      var element = query[elements];
      if (element.clientHeight > max) {
        max = element.clientHeight;
      }
    }
    elements = query.length;
    while (elements--) {
      var element = query[elements];
      element.style.height = max + "px";
    }
  }
};

export { get_db_base, request_items };

export const request_items_ex = (
  db_base,
  collection,
  collection_name_override,
  is_single,
  fields_to_get,
  filter
) => {
  // var obj = request_items(db_base, collection, collection_name_override, is_single, fields_to_get, filter);
  // return obj[Object.keys(obj)[0]];
  var collection_name = collection_name_override
    ? collection_name_override
    : collection;
  return new Promise((a, r) => {
    request_items(
      db_base,
      collection,
      collection_name_override,
      is_single,
      fields_to_get,
      filter
    ).then((result) => {
      let obj = {};
      obj[collection_name] = result;
      a(obj);
    });
  });
};

/**
 * Trims values from a list of objects
 *
 * For example: { a: ' b ' } -> { a: 'b' }
 */
export const trim_object_list_string_values = (list) => {
  list.forEach((item) => {
    for (var key in item) {
      if (typeof item[key] === "string") item[key] = item[key].trim();
    }
  });
  return list;
};

/**
 * Polyfill replacement for String.endsWith
 */
export const str_ends_with = (str, ending) => {
  return str.substring(str.length - ending.length, str.length) === ending;
};

export const set_page_title = (title) => (document.title = title);

export const is_set = (v) => !(v === null || v === undefined);

/**
 * Verifies whether a given string could possibly be bundle ID.
 */
export const isIdBundle = (str) => {
  var intVal = parseInt(str);
  if (isNaN(intVal)) return true;
  // Bundle ID may be composed of some integers.
  // We know the string is a bundle ID if the length of the int value
  // is not equivalent to the length of the string itself
  return ("" + intVal).length !== ("" + str).length;
};

/**
 * Condensed way to simply provide value or default based on a given condition result
 */
export const getOrDefault = (obj, key, key_default, should_inherit_default) => {
  if (should_inherit_default || !key) return obj[key] || obj[key_default];
  return obj[key];
};

/**
 * Returns the value of a pivoted field
 * For example: audienced+localized fields
 *
 * param examples:
 *  - obj: {id, audience}
 *  - field: "heading"
 *  - pivots/default_pivots:
 *  - - for localized:
 *  - - - pivots = {locale: selected_locale}
 *  - - - default_pivots = {locale: default_locale.language}
 *  - - for audienced, localized:
 *  - - - pivots = {audience: selected_audience, locale: selected_locale}
 *  - - - default_pivots = {audience: default_audience, locale: default_locale.language}
 */
export function getLocalizedOrDefault(obj, field, pivots, default_pivots) {
  const pivot_keys = Object.keys(pivots);
  // 1=only locale, 2=audience & locale
  if (pivot_keys.length == 1) {
    // return a localized field based on the selected locale language
    // if no field exists, get the localized field based on the default locale language
    const current_locale_value = obj.locale.find(
      ({ language: { language } }) =>
        language === pivots.locale ||
        language === pivots.locale?.language ||
        language === pivots.language
    )?.[field];
    const default_locale_value = obj.locale.find(
      ({ language: { language } }) =>
        language === default_pivots.locale ||
        language === pivots.locale?.language ||
        language === default_pivots.language
    )?.[field];
    return current_locale_value || default_locale_value;
  } else {
    //pivot by (default?) audience and then again by (default) locale
    return (
      getPivotedField(obj, field, pivots) ||
      getPivotedField(obj, field, default_pivots)
    );
  }
}

/**
 * Returns the value of a pivoted field
 * For example: audienced+localized fields
 *
 * param examples:
 *  - obj: {id, audience}
 *  - field:
 *  - pivots: { selected_audience, selected_locale }
 */
export function getPivotedField(obj, field, pivots) {
  const pivot_keys = Object.keys(pivots);
  let value = obj;
  pivot_keys.forEach((key) => (value = value?.[key]?.[pivots[key]]));
  return value?.[field];
}

/**
 * Retrieve audienced, localized content
 *
 * Params:
 *  - obj - data container
 *  - key_default_pairs - list of objects in the form [{key, key_default}],
 *                        where key is the "currently selected" value for a setting
 *                        (e.g. audiences), and key_default is the default key to use if
 *                        the value for a key is not present
 *  - should_inherit_default - whether to inherit defaults if primary value not present
 */
export const getOrDefault_V2 = (
  obj,
  key_default_pairs,
  should_inherit_default
) => {
  let prev_value = obj;
  for (var k in key_default_pairs) {
    const { key, key_default } = key_default_pairs[k];

    prev_value = getOrDefault(
      prev_value,
      key,
      key_default,
      should_inherit_default
    );
  }

  return prev_value;
};

const dt_class = 'class="dynamic-text"';
const span_dt_pfx = `<span ${dt_class}>`;
// Find any nested tags such as <sup>1</sup>
const tag_match_regex = /<(\w+)[^>]*>([^<]*)<\/\1>/g;
/**
 * Converts text with html content in it into siblings of class .dynamic-text
 *
 * For example,
 *   "PCL<sup>2</sup> and PostScript"
 *  becomes
 *   <span class="dynamic-text">PCL</span><sup class="dynamic-text">2</sup>
 *     <span class="dynamic-text">and PostScript</span>
 */
export const intersperseDynamicText = (string = "") => {
  const replacement = `<$1 ${dt_class}>$2</$1>`;
  const processed_content = string
    // Here we would have "Lowest in its Class<sup class="dynamic-text">1</sup>"
    .replace(tag_match_regex, replacement);

  const pc2 = processed_content
    // Add a </span> before a .dynamic-text, and an opening one after it
    .replace(tag_match_regex, (match) => `</span>${match}${span_dt_pfx}`);

  // Finally, add missing open/closing tags at beginning/end
  return `${span_dt_pfx}${pc2}</span>`;
};

export const unicodeForSuperscriptSymbol = (symbol) => {
  switch (symbol) {
    case "0":
      return "\u2070";
    case "1":
      return "\u00B9";
    case "2":
      return "\u00B2";
    case "3":
      return "\u00B3";
    case "4":
      return "\u2074";
    case "5":
      return "\u2075";
    case "6":
      return "\u2076";
    case "7":
      return "\u2077";
    case "8":
      return "\u2078";
    case "9":
      return "\u2079";
    default:
      return symbol;
  }
};

/**
 * Converts superscripts within a string to an equivalent unicode character,
 * if possible
 *
 * For example,
 *   "PCL<sup>2</sup> and PostScript"
 *  becomes
 *   PCL² and PostScript
 */
export const unicodeSubstitutions = {};
export const digitSuperscriptsToUnicode = (string = "") => {
  const matches = [...string.matchAll(/<sup>([^<]*)<\/sup>/g)] || [];
  matches.forEach(([entire_match, symbol]) => {
    const unicodeSymbol = unicodeForSuperscriptSymbol(symbol);
    //on jpe we want to identify symbols by their unicode, replace it with the actual symbol,
    // and use textRise
    unicodeSubstitutions[unicodeSymbol] = symbol;
    string = string.replaceAll(entire_match, unicodeSymbol);
  });
  return string;
};

export const findGlobalImageUrl = (collections = {}, image_name = "") => {
  const { global_settings } = collections;
  const { global_images = [] } = global_settings;
  const image = global_images.find(({ name }) => name === image_name);
  const image_url = image?.url || "";
  return image_url;
};

/**
 * Formats numbers with the appropriate decimal separator.
 *
 * number - the number to format
 * locale - string such as 'en-US' for delegating to toLocaleString
 * decimal_separator - a string override ([",", "."]) for specifying
 *                    a different separator. Useful for en-CB since
 *                    it is formatted as 'en-US' (1,000.000) but we
 *                    want to show 'es' formatting (1.000,000)
 */
export const localizeNumber = (number, locale = "en-US", decimal_separator) => {
  switch (decimal_separator) {
    case ".":
      return formatNumber(number);

    case ",":
      return formatNumber(number, {
        whole_separator: ".",
        decimal_separator: ","
      });

    default:
      return number.toLocaleString(locale);
  }
};

/**
 * Formats numbers with the appropriate decimal separator.
 * The
 *
 * number - the number to format
 * options
 *   whole_separator - separator for whole number, such as ','
 *   decimal_separator - separator between decimal and whole number, such as '.'
 *   max_decimals - fix decimal result
 */
export const formatNumber = (number, options = {}) => {
  const {
    whole_separator = ",",
    decimal_separator = ".",
    max_decimals = 2
  } = options;

  const number_str = String(number);
  const [whole_str, decimal_str] = number_str.split(".");
  const whole_str_length = whole_str.length;

  const separated_whole = [...whole_str]
    .map((digit, digit_idx) => {
      const insert_separator = (whole_str_length - digit_idx - 1) % 3 === 0;
      if (digit_idx === whole_str_length - 1 || !insert_separator) return digit;

      return `${digit}${whole_separator}`;
    })
    .join("");

  if (!decimal_str) {
    return separated_whole;
  }

  const fixed_decimal_str = decimal_str.substring(0, max_decimals);

  return `${separated_whole}${decimal_separator}${fixed_decimal_str}`;
};

/**
 * Retrieves the date in the format: YYYY-MM-DD
 */
export function dateForPdfName() {
  var d = new Date();
  var year = d.getFullYear();
  var month = "" + (d.getUTCMonth() + 1);
  var day = "" + d.getUTCDate();
  return [year, zeroPad(month, 2), zeroPad(day, 2)].join("-");
}

/**
 * Pads a given string (of digits) with a given number of zeroes ("digits")
 */
function zeroPad(str, digits) {
  if (str.length < digits)
    for (var i = str.length; i < digits; ++i) str = "0" + str;
  return str;
}

/**
 * Extracts a model-variant pairing for PDF naming
 */
function makeProductShortnameForPdfName(product) {
  const { model = "", variant = "" } = product;
  let shortname = "";
  if (model && variant) return `${model} ${variant}`;
  return model;
}

/**
 * Creates a filename-safe shortname from a product
 */
export function formatProductModelForPdfName(product) {
  const shortname = makeProductShortnameForPdfName(product);
  return (
    (shortname || "")
      .toLowerCase()
      // Remove non-alphanumeric characters
      .replace(/[^a-z0-9]/g, "")
  );
}

export const getDisplayValuePTSeries = (feature) => {
  const {
    specification_value = "",
    specification_unit = "",
    unit_separator = "",
    specification = "",
    choice = "",
    choices = [],
    facet = "",
    value_separator = "",
    facet_separator = "",
    facet_placement = "after"
  } = feature;
  const { input_type } = specification;
  let display_value = "";
  if (input_type === "dropdown") display_value = choice;
  if (input_type === "checkboxes")
    display_value = choices.join(value_separator || ", ");
  if (input_type === "text-input")
    display_value = `${specification_value}${unit_separator}${specification_unit}`;
  if (facet_placement === "before")
    display_value = `${facet}${facet_separator}${display_value}`;
  else display_value = `${display_value}${facet_separator}${facet}`;
  return display_value;
};

export function findComparisonFeaturingProduct(product, comparison_pages) {
  return find(comparison_pages, (comparison_page) => {
    const { secondary_products } = comparison_page;
    const primary_product_id =
      comparison_page.primary_product?.id || comparison_page.primary_product_id;
    const secondary_product_ids = secondary_products.map(
      (product) => product?.id || product
    );
    return (
      !!primary_product_id &&
      (primary_product_id === product.id ||
        secondary_product_ids.indexOf(product.id) > -1)
    );
  });
}
