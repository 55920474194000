import axios from "axios";

const default_db_url = "https://epson.comparisontool.com/";
const provided_db_url = process.env.REACT_APP_DB_URL;

// Choose DB URL
const db_url = new URL(provided_db_url || default_db_url).toString();

// Append project path parameter for formatting
const get_db_base = (project) => `${db_url}${project}/`;

const request_items = (
  db_base,
  collection,
  collection_name_override,
  is_single,
  fields_to_get,
  filter
) => {
  var collection_path = collection + (is_single ? "/1" : "");
  var fields = "?fields=" + fields_to_get;
  var filter_path = filter ? "&" + filter : "";
  var path = collection_path + fields + filter_path;
  return axios
    .get(db_base + "items/" + path)
    .then((result) => result.data.data);
};

export { get_db_base, request_items };
