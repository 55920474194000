import {
  str_ends_with,
  dateForPdfName,
  formatProductModelForPdfName
} from "../Functions";
import { getPdfPromotions } from "./PageDataCollector";
import { queue } from "./Amplify";
import loadModularConfig from "./modular";
import crypto from "crypto";
import { pagesToExportArray } from "../ScannersBijFunctions";

const DEFAULT_PDF_NAME = "{{date}}_document.pdf";

// Replace <link> with their <style> contents
function replaceLink(o) {
  const obj = o;
  return new Promise((accept, reject) => {
    fetch(o.href)
      .then(function (res) {
        const reader = res.body.getReader();
        var styles = "";
        const decoder = new TextDecoder();
        function processText({ done, value }) {
          if (done) {
            obj.outerHTML = "<style>" + styles + "</style>";
            accept(styles);
          } else {
            styles += decoder.decode(value);
            reader.read().then(processText);
          }
        }
        reader.read().then(processText);
      })
      .catch(function (e) {
        console.error(e);
        reject(e);
      });
  });
}

function replaceLinks(doc) {
  var allPromises = [];

  // find all 'src'
  doc.querySelectorAll("[src]").forEach(function (o) {
    if (String(o.getAttribute("src")).match(/^https?/)) return;
    // replace src with fully qualified (computed) version
    o.setAttribute("src", o.src);
  });

  // find all links
  doc.querySelectorAll("[href]").forEach(function (o) {
    var href = o.getAttribute("href");
    // skip page-level anchors and links that are already valid
    if (href.match(/^(?:#|https?:\/\/)/)) return;
    // extract content...?

    if (o.tagName !== "LINK") return;
    if (o.getAttribute("rel") !== "stylesheet") {
      if (o.getAttribute("as") !== "style") return;
    }

    allPromises.push(replaceLink(o));
  });

  return Promise.all(allPromises);
}

function replaceEmotionCss(clonedDoc) {
  // replace emotionjs links first
  // source: https://github.com/emotion-js/emotion/issues/1248#issuecomment-494497194

  // "clonedDoc" does not contain "sheet" information, so we check against existing doc only
  var styleElements = [...document.querySelectorAll("style")];
  var styles = styleElements
    .flatMap((element) => {
      let { sheet } = element;
      if (!sheet) {
        if (element.innerHTML.length > 1) {
          return element.innerHTML;
        }
        return "";
      }
      return [...sheet.cssRules].map((rules) => rules.cssText);
    })
    .join("\n")
    .trim();

  // styles insertion "optimized" only in production
  if (!styles) return;

  // Remove all styles from cloned document before applying new style element
  [...clonedDoc.querySelectorAll("style")].forEach((item) => item.remove());

  var combinedStylesContainer = document.createElement("style");
  combinedStylesContainer.type = "text/css";
  combinedStylesContainer.id = "combinedStylesContainer";
  combinedStylesContainer.innerHTML = styles;

  clonedDoc.querySelector("head").appendChild(combinedStylesContainer);
}

function removeElements(clonedDoc) {
  // remove scripts and preview-only items
  var toRemove = clonedDoc.querySelectorAll(
    ".removeForExport,script,[data-meta*=removeForExport]"
  );
  toRemove.forEach((e) => {
    e.remove();
  });
}

function cleanupHtmlForExport() {
  var clonedDoc = document.documentElement.cloneNode(true);
  // add "export" class to HTML with intention of applying evopdf fixes (if any)
  clonedDoc.classList.add("export");

  replaceEmotionCss(clonedDoc);
  return replaceLinks(clonedDoc).then((res) => {
    // all promises complete
    removeElements(clonedDoc);
    setTableCellHeights(clonedDoc);
    clearDropdowns(clonedDoc);
    clearStylesForExport(clonedDoc);
    clearClasses(clonedDoc);
    includeInputs(clonedDoc);
    replaceHrefs(clonedDoc);
    setFixHeightsForExport(clonedDoc);
    return clonedDoc;
  });
}

function replaceHrefs(clonedDoc) {
  [...clonedDoc.querySelectorAll("[data-href]")].forEach((e) => {
    e.href = e.getAttribute("data-href");
  });
}

const attr_clear_classes = "data-clear-classes";
function clearClasses(clonedDoc) {
  [...clonedDoc.querySelectorAll(`[${attr_clear_classes}]`)].forEach((e) => {
    const classes = e.getAttribute(attr_clear_classes).split(",");
    e.classList.remove(...classes);
    e.removeAttribute(attr_clear_classes);
  });
}
function clearStylesForExport(clonedDoc) {
  [...clonedDoc.getElementsByClassName("clearStylesForExport")].forEach((e) => {
    e.classList.remove("clearStylesForExport");
    e.style = "";
  });
}

function elementIsNestedInRemovableSection(table) {
  let el = table;
  while (el) {
    // Document node has no classList
    if (!el.classList) return false;
    if (el.classList.contains("removeForExport")) return true;
    el = el.parentNode;
  }
  return false;
}

function setTableCellHeights(clonedDoc) {
  const tables = [...document.querySelectorAll("table")]
    // Remove tables which are nested under some ".removeForExport'"
    .filter((t) => !elementIsNestedInRemovableSection(t));

  const cell_selector = "th,td";

  // collect heights from visible cells
  const visible_cells = tables.flatMap((t) => [
    ...t.querySelectorAll(cell_selector)
  ]);
  var cell_heights = visible_cells.map((cell) => cell.offsetHeight + "px");

  // apply heights from visible cells onto cloned document cells
  const cloned_cells = [...clonedDoc.querySelectorAll(cell_selector)];
  cloned_cells.forEach((cell, idx) => (cell.style.height = cell_heights[idx]));
}

function clearDropdowns(clonedDoc) {
  [...clonedDoc.querySelectorAll("select")].forEach(
    (e) => (e.selectedIndex = 0)
  );
}

function includeInputs(clonedDoc) {
  // Convert to inputs
  [...clonedDoc.querySelectorAll(".convertToInput")].forEach(
    (parent_container, idx) => {
      let new_input = document.createElement("textarea");
      new_input.rows = 2;

      parent_container.insertBefore(new_input, parent_container.firstChild);
      new_input = null;
    }
  );

  // Set title names appropriately
  [
    ...clonedDoc.querySelectorAll(".convertToInput.product-title textarea")
  ].forEach((input, idx) => {
    input.name = `qc_title_${idx}`;
  });
  // Set feature cell names appropriately
  [...clonedDoc.querySelectorAll(".comparison-table .tableColumn")].forEach(
    (column, column_idx) => {
      [...column.querySelectorAll(".convertToInput textarea")].forEach(
        (input, input_idx) => {
          input.name = `qc_feature_${column_idx}_${input_idx}`;
        }
      );
    }
  );
}

/**
 * elementIsNestedInRemovableSection
 */
function setFixHeightsForExport(clonedDoc) {
  const visible_elements = [
    ...document.querySelectorAll(".setFixedHeightForExport")
  ]
    // Remove tables which are nested under some ".removeForExport'"
    .filter((t) => !elementIsNestedInRemovableSection(t));

  const invisible_elements = [
    ...clonedDoc.querySelectorAll(".setFixedHeightForExport")
  ];

  if (invisible_elements.length != visible_elements.length) {
    const message =
      "Unable to fix heights for .setFixedHeightForExport elements due to element count mismatches";
    const mismatch = `${invisible_elements.length} != ${visible_elements.length}`;
    console.error(`${message}: ${mismatch}`);
    return;
  }

  invisible_elements.forEach((invisible_element, element_idx) => {
    const visible_element = visible_elements[element_idx];
    invisible_element.style.height = `${visible_element.offsetHeight}px`;
  });
}

// returns a list of matched variables
function variablesFromTemplate(template) {
  if (!template) return [];
  var matches = [...template.matchAll(/{{(.*?)}}/g)];
  return matches.map((match) => {
    return {
      variable: match[1],
      to_replace: match[0]
    };
  });
}

function renderTemplate(str, variables) {
  var tpl_vars = variablesFromTemplate(str);
  tpl_vars.forEach((tpl_var) => {
    var value = variables[tpl_var.variable] || "";
    var regexp = new RegExp(tpl_var.to_replace, "g");
    str = str.replace(regexp, value);
  });
  return str;
}

function formatPdfName(givenFromProject) {
  var variables = {
    date: dateForPdfName()
  };

  var name = DEFAULT_PDF_NAME;
  if (givenFromProject) name = givenFromProject;
  name = appendPdfExtensionIfNecessary(name);

  return renderTemplate(name, variables);
}

function appendPdfExtensionIfNecessary(str) {
  var ext = ".pdf";
  if (!str_ends_with(str.toLowerCase(), ext)) return str + ext;
  return str;
}

function submitHtml_poll(submissionBody) {
  // Continuously poll
  // Submit HTML
  return queue(submissionBody);
  // .then((queueResponse) => {
  //   var data = JSON.parse(queueResponse.body);
  //   var s3Key = data.s3Key;
  //   // Begin polling
  //   _poll(s3Key);
  // }, (e) => {
  //   console.error(e);
  //   report_pdf_error(e);
  //   setState_error(e);
  // });
}

/**
 * "Push" approach
 */
// submitHtml_push(submissionBody) {
//   var promise = requestFirebaseToken();
//   promise.then((token) => {
//     if (!token) {
//       // error
//       return;
//     }

//     return subscribe(token);
//   }).then((subscriptionResponse) => {
//     var data = subscriptionResponse.body;
//     subscriptionData.EndpointArn = data.endpoint;
//     subscriptionData.SubscriptionArn = data.subscription;
//     return subscriptionData;
//   }).then((sub) => {
//     // queue, then unsubscribe
//     return queue(submissionBody);
//   }).then((queueResponse) => {
//     // unsubscription occurs once notification is received
//     //unsubscribe(subscription);
//   });
// }

function getElementDimensions(element) {
  let styles = window.getComputedStyle(element);
  return {
    width: parseInt(styles.width),
    height: parseInt(styles.height)
  };
}

function getFirstSheetDimensions() {
  let firstSheet = document.querySelector(".sheet");
  let dimensions = getElementDimensions(firstSheet);
  return {
    sheetWidth: dimensions.width,
    sheetHeight: dimensions.height
  };
}

function removeSiblings(element, siblingKey) {
  while (!!element) {
    const sibling_to_remove = element;
    element = element[siblingKey];
    sibling_to_remove.remove();
  }
}

function isolateSheet(clonedDoc, element_id) {
  const sheet_id = "#" + element_id;
  const target_element = clonedDoc.querySelector(sheet_id);
  if (!target_element) {
    throw 'Unable to export: target sheet "' + sheet_id + '" missing.';
  }

  // Remove all previous siblings
  removeSiblings(target_element.previousSibling, "previousSibling");
  // Remove all subsequent siblings
  removeSiblings(target_element.nextSibling, "nextSibling");
}

function getForcePdfExport(clonedDoc) {
  var targetElement = clonedDoc.querySelector("[force-pdf-export]");
  if (targetElement) {
    targetElement.removeAttribute("force-pdf-export");
    return true;
  }
  return false;
}

const defaultHashKeys = [
  "other",
  "type",
  "sheetWidth",
  "sheetHeight",
  "referrer"
];

function submitHtml(
  clonedDoc,
  pdfExportData,
  projectType,
  selectedContact,
  selectedLocale,
  forcedExport,
  exposeContent,
  pagesToExport,
  emailTo
) {
  // Remove siblings of the Page element to export, so that it is the only page present
  const { idOfSinglePageElementToExport } = pdfExportData;
  if (idOfSinglePageElementToExport) {
    isolateSheet(clonedDoc, idOfSinglePageElementToExport);
  }

  const pdfName = formatPdfName(pdfExportData.name);
  const isSinglePage = !!idOfSinglePageElementToExport; //document.querySelectorAll('.sheet').length === 1 ||
  var additionalInfoForExporter = isSinglePage
    ? {}
    : pdfExportData.additional || {};

  if (additionalInfoForExporter.allPdfPromoLinks === true)
    additionalInfoForExporter.allPdfPromoLinks = getPdfPromotions(clonedDoc);

  const { dbDataRaw, ...additionalData } = additionalInfoForExporter;
  var html = clonedDoc.outerHTML;

  var hashKeys = [...defaultHashKeys, ...Object.keys(additionalData || {})];
  var firstSheetDimensions = getFirstSheetDimensions();
  const forceExport = getForcePdfExport(clonedDoc) || forcedExport;
  const modular_config = !exposeContent
    ? loadModularConfig(pdfExportData.all_data?.collections, projectType)
    : {};

  const allPagesToExport = pagesToExportArray(pagesToExport);
  // <primary>-<secondary1>-<secondaryN>-comparison_<date>.pdf
  const generateComparisonName = (page) => {
    const all_products = [page.primary_product, ...page.secondary_products];
    const formatted_product_names = all_products.map((p) =>
      formatProductModelForPdfName(p)
    );
    const comparison_name = formatted_product_names.join("-");
    const date = dateForPdfName();
    return `${comparison_name}-comparison_${date}.pdf`;
  };

  const selectedComparisonPages = allPagesToExport.map((page) => ({
    id: page.id,
    name: generateComparisonName(page)
  }));
  const backgroundExport = selectedComparisonPages.length > 0;

  const backgroundExportProps = backgroundExport
    ? {
        selectedComparisonPages,
        emailTo,
        backgroundExport
      }
    : {};

  var submissionBody = {
    html: html,
    pdfName: pdfName,
    referrer: window.location.origin,
    type: projectType,
    ...firstSheetDimensions,
    ...additionalData,
    // s3Key: 'test',
    other: {
      staticExport: exposeContent || false,
      pageTitle: pdfExportData?.page_title || "",
      locale: selectedLocale?.language,
      ...backgroundExportProps,
      ...(pdfExportData?.other || {})
    },

    // Do not include this object unless necessary
    ...(forceExport
      ? {
          receiverConfig: {
            forceExport
          }
        }
      : {}),

    hashConfig: {
      hashKeys,
      dbDataRaw: {
        // Create an MD5 hash of the data
        md5: crypto
          .createHash("md5")
          .update(
            JSON.stringify({
              // Append selected contact to hashed content
              ...dbDataRaw,
              selected_contact: selectedContact?.id,

              // Append single page export information
              ...(!!idOfSinglePageElementToExport
                ? { idOfSinglePageElementToExport }
                : {})
            })
          )
          .digest("hex")
      }
    },

    editorConfig: modular_config
  };

  // Support interrupting export for faster local development.
  // For example, when making changes to the back-end locally.
  if (process.env.REACT_APP_INTERRUPT_EXPORT) {
    console.log("EvoPDF + JPE", submissionBody);
    const { editorConfig, ...remainingData } = submissionBody;
    console.log("EvoPDF", remainingData);
    console.log("JPE", editorConfig);
    throw "DEBUG: Purposeful export cancellation.";
  }

  // Google Analytics
  // report_pdf_generate(projectType);

  return submitHtml_poll(submissionBody);
}

export { cleanupHtmlForExport, formatPdfName, submitHtml };
