import {
  getElementPosition,
  getRelativeElementPosition,
  getRgbColorAsHex,
  getBorderProps,
  getRoundedRadius,
  getBgProps,
  pxToPt,
  processNumber,
  createText,
  createTextElementsFromContainer,
  createImage,
  createImageElementsFromContainer,
  createCta,
  createCta_v2,
  createButton,
  createButton_v2,
  createBG_v2,
  createBorder,
  offsetElementPositions
} from "../util";

import { createFormFieldsFromContainer } from "../forms";

import { remove_all_tags } from "../../../Functions";

import scripts from "./scripts";

function createDealerButtons(mod, configs) {
  const { containing_element } = configs;
  const { html_id } = mod;
  const list_items = [...containing_element.querySelectorAll(".list_item")];
  return list_items.flatMap((item, idx) => {
    const item_icon = item.querySelector(".css-icon");
    const icon_span = item_icon.querySelector("span");
    return [
      {
        name: `dealer-${idx}`,
        elements: [
          createBG_v2(icon_span, configs, {
            name: "icon",
            backgroundColor: "#6B757A",
            borderRadius: 3,
            hidden: true
          }),

          createButton_v2(item, configs, {
            name: "btn",
            action: {
              mouseClick: `form_rq_dealerSelected('${html_id}', ${idx})`
            },
            form: { value: idx }
          })
        ]
      },

      createButton_v2(item_icon, configs, {
        name: "dealer_selection",
        action: { mouseClick: "" },
        hidden: true,
        form: { type: "radio", value: idx }
      })
    ];
  });
}

function createDealerTooltipsSection(mod, configs) {
  const { containing_element } = configs;
  const { html_id } = mod;
  const tooltips = [...containing_element.querySelectorAll(".contact-tooltip")];
  const email_dealer_btn = containing_element.querySelector(".email-dealer");
  const email_address_btn = containing_element.querySelector(".email");

  const email_dealer_action = {
    mouseClick: `form_rq_emailDealer('${html_id}')`
  };

  const createTooltipsMiscContent = () => {
    if (!tooltips[0]) return [];

    return [
      {
        name: "tooltips_misc",
        hidden: true,
        elements: [
          createBG_v2(tooltips[0], configs, {
            name: "bg",
            include_border: true
          }),

          createCta_v2(email_dealer_btn, configs, { name: "cta" })
        ]
      }
    ];
  };

  return [
    // BG for all tooltips
    ...createTooltipsMiscContent(),

    // Per-dealer tooltips
    {
      name: "tooltips",
      elements: tooltips.map((el, tooltip_idx) => {
        const header = el.querySelector(".header");
        const address = el.querySelector(".address p");
        const phone = el.querySelector(".phone a");
        const email = el.querySelector(".email a");
        const highlights_container = el.querySelector(".highlights");
        const highlights = highlights_container.querySelector(".highlights");
        return {
          name: String(tooltip_idx),
          hidden: true,
          elements: [
            ...createTextElementsFromContainer(
              el,
              `text-${tooltip_idx}`,
              configs
            )
          ]
        };
      })
    },

    // Email dealer button
    createButton_v2(email_address_btn, configs, {
      name: "email",
      action: email_dealer_action,
      hidden: true
    }),
    createButton_v2(email_dealer_btn, configs, {
      name: "email-button",
      action: email_dealer_action,
      hidden: true
    })
  ];
}

function createFormElements(mod, configs) {
  const { containing_element } = configs;
  const form = containing_element.querySelector(".order-info");
  return createFormFieldsFromContainer(form, configs, {
    input: {
      alwaysVisible: true,
      form: {
        editable: true
      }
    }
  });
}

function createElements(mod, configs) {
  const filter_callbacks = [
    createFormElements,
    createDealerButtons,
    createDealerTooltipsSection
  ];
  return filter_callbacks.flatMap((fn) => fn(mod, configs));
}

function getScriptVariables(mod, configs) {
  const { content = {} } = mod;
  const { selected_contacts = [] } = content;
  const dealers = selected_contacts.map(({ phone: p, email: e, name: n }) => ({
    p,
    e,
    n
  }));
  return { dealers };
}

function loadFormRequestQuoteConfig(mod, configs) {
  const { page, containing_element } = configs;
  const { idx: page_idx } = page;
  const { html_id } = mod;
  const elements = createElements(mod, configs);

  return {
    config: {
      name: html_id,
      page: page_idx,
      position: getElementPosition(containing_element),
      elements
    },
    prefix: html_id,
    script_variables: getScriptVariables(mod, configs)
  };
}

export { loadFormRequestQuoteConfig, scripts };
