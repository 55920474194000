import { createMuiTheme } from "@material-ui/core/styles";

let appTheme = createMuiTheme({
  typography: {
    fontFamily: "Helvetica Neue LT W05_47 Lt Cn", // 'Helvetica Neue LT W05_47 Lt Cn', 'Helvetica Neue LT W05_57 Cond', 'Helvetica Neue LT W05_77 Bd Cn',
    htmlFontSize: 14,
    fontSize: 12,
    body1: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: 1.25,
      letterSpacing: "0.01em"
    },
    body2: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: 1.2,
      letterSpacing: "0.05em"
    },
    h1: {
      fontFamily: "azo-sans-web",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: 1.2
    },
    subtitle1: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: 1.16,
      letterSpacing: "0.05em"
    },
    h2: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "24px",
      lineHeight: 1.2
    },
    h5: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: 1.2
    },
    h6: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: 1.2
    },
    subtitle2: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: 1.25
    },
    button: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: 1.25,
      letterSpacing: "0.03em",
      textTransform: "capitalize"
    },
    caption: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: 1,
      textTransform: "initial"
    },
    overline: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: 0.96,
      letterSpacing: "0.14em",
      textTransform: "uppercase"
    }
  },
  palette: {
    primary: {
      light: "#1E4493",
      main: "#122448"
    },
    secondary: {
      main: "#ffffff"
    },
    text: {
      primary: "#000000",
      secondary: "#000000de"
    },
    background: {
      default: "#EDEDED"
    },
    gray: {
      main: "#CBCBCB"
    },
    divider: "#CBCBCB",
    biscay: {
      main: "#2e3853"
    },
    gblue: {
      dark: "#2F373B",
      main: "#86939A"
    },
    cyan: {
      light: "#4ab8d366",
      main: "#4BB7D3"
    },
    magenta: {
      main: "#EB165C"
    },
    yellow: {
      main: "#E9CE27"
    },
    dark: {
      main: "#2F373B"
    },
    black: {
      main: "#000000"
    },
    white: {
      main: "#FFFFFF"
    }
  }
});

export default appTheme;
