const scripts = `
try {
  // Tabbed Container
  var tabbedContainer_slide_fields = ['tab-inactive', 'tab-active', 'cta-1', 'cta-2'];

  function tabbedContainer_goToSlide(tabbedContainer, slide_id) {
    goToSlide(tabbedContainer, slide_id, tabbedContainer_slide_fields);
  }

  function tabbedContainer_goToPrevSlide(tabbedContainer_name) {
    goToPrevSlide(tabbedContainer_name, tabbedContainer_slide_fields);
  }

  function tabbedContainer_goToNextSlide(tabbedContainer_name) {
      goToNextSlide(tabbedContainer_name, tabbedContainer_slide_fields);
  }
} catch (e) {
  app.alert(e);
}
`;

export default scripts;
