import Amplify, { API as AWS_API } from "aws-amplify";

/*************** Amplify ****************/
const target_api = "Push";

// prod: 07qphx441b
const default_api_url_id = "l8jtdg6mok";
const default_api_url_stage = "default";
const api_id = process.env.REACT_APP_API_URL_ID || default_api_url_id;
const api_stage = process.env.REACT_APP_API_URL_STAGE || default_api_url_stage;
export const api_url = `https://${api_id}.execute-api.us-west-2.amazonaws.com/${api_stage}/`;

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "Push",
        endpoint: api_url
      }
    ]
  }
});

const processApiPromise = (promise, callback) => {
  promise.then((response) => {
    if (typeof callback === "function") callback(response);
    return response;
  });
  return promise;
};

const getFromApi = (apiName, path, init, callback) => {
  var promise = AWS_API.get(apiName, path, init).catch((e) => console.error(e));
  return processApiPromise(promise, callback);
};
const postToApi = (apiName, path, init, callback) => {
  var promise = AWS_API.post(apiName, path, init);
  return processApiPromise(promise, callback);
};

/**
 * Used by service worker for "push" approach
 */
// function pdfQueueResponseReceived(error, urls) {
//   if (error) {
//     console.error('error', error);
//   } else {
//     if (!urls) {
//       // unsubscription
//     } else if (urls.length > 0)
//       window.open(urls[0], "_blank");
//   }
//   unsubscribe(subscriptionData.EndpointArn, subscriptionData.SubscriptionArn);
// }

/**
 * Used for "push" approach
 */
// function subscribe(fcmToken) {
//   var init = { body : { tokenToSubscribe: fcmToken } };
//   return postToApi(target_api, 'subscribe', init);
// }
/**
 * Used for "push" approach
 */
// function unsubscribe(endpointArn, subscriptionArn) {
//   var init = {
//     body : {
//       endpointArnToUnsubscribe: endpointArn,
//       subscriptionArnToUnsubscribe: subscriptionArn,
//     }
//   };
//   return postToApi(target_api, 'unsubscribe', init);
// }

export const queue = (body) => {
  var init = { body: body };
  return postToApi(target_api, "queue", init);
};

/**
 * Used for "push" approach
 */
// var subscriptionData = {
//   EndpointArn: '',
//   SubscriptionArn: ''
// }

export const poll = (s3Key, projectType) => {
  var init = { queryStringParameters: { s3Key: s3Key } };
  return getFromApi(target_api, "poll", init);
};

export const spe = (s3Key, elementID, type) => {
  var init = { queryStringParameters: { elementID: elementID, type: type } };
  return getFromApi(target_api, `spe-p-r/${s3Key}`, init);
};

export const bundleVerify = (s3Key, type) => {
  var init = { queryStringParameters: { type } };
  return getFromApi(target_api, `b-id-verf/${s3Key}`, init);
};
