import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { filter } from "lodash";

import {
  usePopupState,
  bindToggle,
  // bindHover,
  bindMenu
} from "material-ui-popup-state/hooks";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { pageToDropdown } from "../../helpers/ScannersBijFunctions";

const useHomePageStyle = makeStyles(() => ({
  root: {
    width: "500px"
  },
  selected: {
    fontWeight: 600,
    color: "#A02128",
    "&:before": {
      paddingRight: "11px",
      content: '"✓"'
    }
  },
  unselected: {
    "&:before": {
      content: '""',
      paddingRight: "22px"
    }
  },
  exposeContentSelected: {
    color: "#A02128"
  }
}));

const ParentPopupState = React.createContext(null);

const CascadingMenu = (props) => {
  const classes = useHomePageStyle();

  const popupState = usePopupState({ popupId: "demoMenu", variant: "popover" });

  // Example "options" configuration
  // const options = [{
  //   name: 'contacts',
  //   title: 'Contacts',
  //   iconName: 'contacts',
  //   defaultOption: 'None',
  //   comparisonKey: 'id',
  //   labelKey: 'name',
  //   selectedItem: {},
  //   selectItem: () => {}
  //   items: []
  // }]
  const { options = [], globalSettings = {} } = props;
  const { hide_single_option_toggles = true } = globalSettings;

  const valid_options = options.filter(({ defaultOption, items = [] }) => {
    // Include defaultOption as one of the items, for comparison purposes.
    // The default option is removed if not set, leaving us with the original item count.
    const pseudo_items = [defaultOption, ...items].filter((opt) => !!opt);
    // Only show options with more than 1 entry available based on the global setting is enabled
    if (hide_single_option_toggles) {
      return pseudo_items.length > 1;
    }
    // Hide options with no values
    return pseudo_items.length > 0;
  });

  const all_options_hidden = valid_options.length === 0;

  return (
    <React.Fragment>
      {!all_options_hidden && (
        <React.Fragment>
          <IconButton variant="contained" {...bindToggle(popupState)}>
            <MoreVertIcon color={"secondary"} />
          </IconButton>

          <ParentPopupState.Provider value={popupState}>
            <Menu
              className={classes.root}
              {...bindMenu(popupState)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              {valid_options.map(
                (
                  {
                    name,
                    title,
                    iconName,
                    defaultOption,
                    comparisonKey,
                    labelKey,
                    selectedItem,
                    selectItem,
                    items,
                    useComparisonKeyForValue
                  },
                  option_idx
                ) => {
                  if (name === "pagesToExport") {
                    items = [defaultOption].concat(items);
                    return (
                      <Submenu
                        popupId={name}
                        icon={iconName}
                        title={title}
                        key={option_idx}
                      >
                        {items.map((item, item_idx) => {
                          const isDefault =
                            item === defaultOption && selectedItem.length === 0;
                          const isSelected =
                            comparisonKey &&
                            (selectedItem.includes(item) || isDefault);
                          return (
                            <MenuItem
                              key={item_idx}
                              className={clsx(
                                { [classes.selected]: isSelected },
                                { [classes.unselected]: !isSelected }
                              )}
                              onClick={() => {
                                const selection = pageToDropdown(
                                  selectedItem,
                                  item,
                                  defaultOption,
                                  items
                                );
                                selectItem(selection);
                              }}
                            >
                              {item?.[labelKey] ||
                                item?.[comparisonKey] ||
                                item}
                            </MenuItem>
                          );
                        })}
                      </Submenu>
                    );
                  } else if (name === "exposeContent") {
                    return (
                      <MenuItem
                        key={name}
                        className={
                          selectedItem.value
                            ? classes.exposeContentSelected
                            : ""
                        }
                        onClick={() => {
                          popupState.close();
                          selectItem({ value: !selectedItem.value });
                        }}
                      >
                        <Icon className={classes.icon}>
                          {selectedItem.value
                            ? iconName.selected
                            : iconName.unselected}
                        </Icon>
                        {title}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <Submenu
                        popupId={name}
                        icon={iconName}
                        title={title}
                        key={option_idx}
                      >
                        {defaultOption && (
                          <MenuItem
                            className={clsx(
                              { [classes.selected]: !selectedItem },
                              { [classes.unselected]: selectedItem }
                            )}
                            onClick={() => {
                              popupState.close();
                              selectItem(null);
                            }}
                          >
                            {defaultOption}
                          </MenuItem>
                        )}
                        {items.map((item, item_idx) => {
                          let isSelected =
                            comparisonKey &&
                            (item[comparisonKey] || item) ===
                              (selectedItem?.[comparisonKey] || selectedItem);
                          return (
                            <MenuItem
                              key={item_idx}
                              className={clsx(
                                { [classes.selected]: isSelected },
                                { [classes.unselected]: !isSelected }
                              )}
                              onClick={() => {
                                popupState.close();
                                if (useComparisonKeyForValue) {
                                  selectItem(item[comparisonKey] || item);
                                } else {
                                  selectItem(item);
                                }
                              }}
                            >
                              {item?.[labelKey] ||
                                item?.[comparisonKey] ||
                                item}
                            </MenuItem>
                          );
                        })}
                      </Submenu>
                    );
                  }
                }
              )}
            </Menu>
          </ParentPopupState.Provider>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CascadingMenu;

const submenuStyles = (theme) => ({
  menu: {
    minWidth: "200px",
    marginTop: theme.spacing(0),
    marginRight: "100px"
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
    marginLeft: theme.spacing(3)
  }
});

const Submenu = withStyles(submenuStyles)(
  // Unfortunately, MUI <Menu> injects refs into its children, which causes a
  // warning in some cases unless we use forwardRef here.
  React.forwardRef(({ classes, title, popupId, children, ...props }, ref) => {
    const parentPopupState = React.useContext(ParentPopupState);
    const popupState = usePopupState({
      popupId,
      variant: "popover",
      parentPopupState
    });
    return (
      <ParentPopupState.Provider value={popupState}>
        <MenuItem
          {...bindToggle(popupState)}
          selected={popupState.isOpen}
          ref={ref}
        >
          <Icon className={classes.icon}>{props.icon}</Icon>
          <span className={classes.title}>{title}</span>
          <ChevronRight className={classes.moreArrow} />
        </MenuItem>
        <Menu
          {...bindMenu(popupState)}
          classes={{ paper: classes.menu }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          getContentAnchorEl={null}
          {...props}
        >
          {children}
        </Menu>
      </ParentPopupState.Provider>
    );
  })
);
