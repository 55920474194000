import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

const useDropdownArrowWithPopoverStyles = makeStyles((theme) => ({
  buttonToggle: {
    '&, &:focus': {
      outline: 'none'
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}), { meta: 'removeForExport' });

/**
 * Supports the following props
 *   idSuffix  - string to append to id
 *   menuItems - array of objects, each containing: [1] onCLick; [2] children
 *             - Example: [{ onClick: () => {}, children: 'Re-generate PDF' }]
 */
export default function DropdownArrowWithPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { idSuffix = '', menuItems = [] } = props;

  const idSuffixStr = idSuffix ? `-${idSuffix}` : '';
  const menuId = `collapsible-menu${idSuffixStr}`;
  const ExpandIcon = !open ? ExpandLessIcon : ExpandMoreIcon;
  const classes = useDropdownArrowWithPopoverStyles();

  return (
    <React.Fragment>
      <IconButton
        className={classes.buttonToggle}
        size="small"
        color="secondary"
        disableRipple={true}
        disableFocusRipple={true}
        aria-controls={menuId}
        ref={anchorRef}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
      >
        <ExpandIcon/>
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
        placement='top-start'
      >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id={menuId}>
                    { menuItems.map(({ onClick, content, Icon, iconProps }, itemIdx) => {
                      const { className } = iconProps;
                      const allIconProps = {
                        ...iconProps,
                        className: !className ? classes.icon : `${className} ${classes.icon}`
                      };

                      return (
                        <MenuItem key={itemIdx}
                          onClick={(e) => {
                            handleClose(e);
                            onClick(e);
                          }}
                        >
                          { Icon && <Icon {...allIconProps}/> }
                          {content}
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </React.Fragment>
  );
}