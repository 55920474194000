import { filter } from "lodash";

// Extract a single value (with the given key) from the given object
function extract_object_value_from_key(object, key) {
  if (Array.isArray(object))
    return object.map((obj) => extract_object_value_from_key(obj, key));
  return object?.[key];
}

/**
 * Extract an object's value from the given string_of_variable
 * string_of_variable (e.g. "primary_product.tco") may be a period-delimited separation of nested keys for plucking
 * individual values
 */
function extract_object_value(object, string_of_variable) {
  let variable_parts = string_of_variable.split(".");
  let object_at_depth = object;
  for (var i = 0; i < variable_parts.length; ++i) {
    let key = variable_parts[i];
    object_at_depth = extract_object_value_from_key(object_at_depth, key);
  }

  return object_at_depth;
}

/**
 * Collects variables from an object as specified by variables_to_collect and returns a
 * formatted map of extracted data in the following format:
 * { variable_name: variable_value }
 */
export function prepareJSONLogicData(object, variables_to_collect) {
  let data = {};
  variables_to_collect.forEach((variable) => {
    let found_value = extract_object_value(object, variable.value);
    data[variable.name] = found_value;
  });

  return data;
}

/**
 * Returns true if provided variables can be used for TCO Savings calculation
 */
export function validateTCOSavingsLogicData(variables) {
  let variable_names = Object.keys(variables);
  for (var i = 0; i < variable_names.length; ++i) {
    const var_name = variable_names[i];
    const var_value = variables[var_name];

    // Invalid value
    if (var_value === undefined) {
      return false;
    }

    // Prevent TCO calculation from occuring if any first-child, non-array, non-object value is a zero.
    // This is for keys pertaining to primary product TCO (named by the user)
    if (typeof var_value === "number" && var_value < 1) return false;

    // Remove zeroes from arrays
    // This is for keys pertaining to secondary product TCOs (named by the user)
    if (Array.isArray(var_value)) {
      variables[var_name] = filter(variables[var_name], (value) => value > 0);

      // If no values remain after removing zeroes, we cannot perform TCO calculation
      if (variables[var_name].length === 0) return false;
    }
  }

  return true;
}
