import { findIndex, filter } from "lodash";

class DisclaimerProcessor {
  // disclaimers_by_id: all disclaimers mapped by their id
  // page_type: one of ['index', 'brand', 'comparison']
  constructor(disclaimers_by_id = {}, page_type) {
    this.disclaimers_map = {
      ...disclaimers_by_id,
      ...Object.entries(disclaimers_by_id).reduce((acc, [_, v]) => {
        acc[v.tag] = v;
        return acc;
      }, {})
    };
    this.page_type = page_type;
    this.disclaimer_sup_symbols_by_id = {};
    this.disclaimer_symbols_by_id = {};

    // listed by paragraph & type
    // e.g. {1: indexed: [], overridden: []], 2: [...]}
    this.disclaimers_for_page = {};
    this.global_disclaimers = {};
    this.processGlobalDisclaimers();
  }

  // shorthand for processString
  ps(str, disclaimer) {
    return this.processString(str, disclaimer);
  }

  reset() {
    this.disclaimers_for_page = {};
    this.disclaimer_sup_symbols_by_id = {};
    this.disclaimer_symbols_by_id = {};
    this.processGlobalDisclaimers();
  }

  processGlobalDisclaimers() {
    var keys = Object.keys(this.disclaimers_map);

    // collect disclaimers that show on this page
    keys.forEach((key) => {
      var disclaimer = this.disclaimers_map[key];
      if (
        !this.disclaimerIsGlobalToPage(disclaimer) &&
        !disclaimer.disclaimer_is_global
      )
        return;
      this.processDisclaimer(disclaimer);
    });
  }

  // Remove items marked as "extract" only if not marked as global
  filterDisclaimersForCurrentPage(list) {
    return filter(list, (d) => {
      const disclaimer = this.disclaimers_map[d.id];
      return this.disclaimerIsGlobalToPage(disclaimer) || !d.extract;
    });
  }

  getGlobalDisclaimers() {
    var disclaimers_for_page = this.global_disclaimers;
    var disclaimers_by_paragraph = [];
    for (var paragraph_idx in disclaimers_for_page) {
      // Retrieve disclaimers that are not marked for extraction (for use in other parts of the page)
      let paragraph_at_idx = disclaimers_for_page[paragraph_idx];
      var overridden = this.filterDisclaimersForCurrentPage(
        paragraph_at_idx.overridden
      );
      var indexed = this.filterDisclaimersForCurrentPage(
        paragraph_at_idx.indexed
      );
      disclaimers_by_paragraph.push([...overridden, ...indexed]);
    }

    return disclaimers_by_paragraph;
  }

  getDisclaimersForPageIndexed() {
    return this.disclaimers_for_page || [];
  }

  getDisclaimersByParagraphForCurrentPage() {
    // show OVERRIDEN disclaimers (in paragraph) followed by indexed
    var disclaimers_for_page = this.disclaimers_for_page;

    var disclaimers_by_paragraph = [];
    for (var paragraph_idx in disclaimers_for_page) {
      // Retrieve disclaimers that are not marked for extraction (for use in other parts of the page)
      let paragraph_at_idx = disclaimers_for_page[paragraph_idx];
      var overridden = this.filterDisclaimersForCurrentPage(
        paragraph_at_idx.overridden
      );
      var indexed = this.filterDisclaimersForCurrentPage(
        paragraph_at_idx.indexed
      );
      disclaimers_by_paragraph.push([...overridden, ...indexed]);
    }

    return disclaimers_by_paragraph;
  }

  getIndexedDisclaimerCount(disclaimer_is_global) {
    const disclaimers_to_use = disclaimer_is_global
      ? this.global_disclaimers
      : this.disclaimers_for_page;
    return Object.keys(disclaimers_to_use).reduce((acc, paragraph_idx) => {
      return [...acc, ...(disclaimers_to_use[paragraph_idx]?.indexed || [])];
    }, []).length;
  }

  cleanupDisclaimerCopy(copy) {
    copy = copy || "";
    return copy.replace(/^<p>/, "").replace(/<\/p>$/, "");
  }

  disclaimerIsGlobalToPage(disclaimer) {
    return disclaimer.always_show_on.indexOf(this.page_type) > -1;
  }

  disclaimerSymbol(disclaimer) {
    return this.disclaimer_symbols_by_id[disclaimer?.id];
  }

  disclaimerAlreadyProcessed(disclaimer) {
    const disclaimer_symbol = this.disclaimerSymbol(disclaimer);
    // No symbol, but processed
    if (disclaimer_symbol === "") return true;
    return !!disclaimer_symbol;
  }

  disclaimerSymbol_superscript(disclaimer) {
    return this.disclaimer_sup_symbols_by_id[disclaimer?.id];
  }

  _getListForDisclaimer(disclaimer) {
    if (disclaimer.disclaimer_is_global)
      return this.global_disclaimers[disclaimer.paragraph];
    return this.disclaimers_for_page[disclaimer.paragraph];
  }

  _initListForDisclaimer(disclaimer) {
    const { disclaimer_is_global, paragraph } = disclaimer;
    if (disclaimer_is_global) {
      if (!this.global_disclaimers[paragraph]) {
        this.global_disclaimers[paragraph] = {
          indexed: [],
          overridden: []
        };
      }
    } else {
      if (!this.disclaimers_for_page[paragraph]) {
        this.disclaimers_for_page[paragraph] = {
          indexed: [],
          overridden: []
        };
      }
    }
  }

  // add to appropriate paragraph list if indicated, and
  // return disclaimer's symbol
  processDisclaimer(disclaimer, extract_disclaimer_from_paragraphs_list) {
    var _disclaimer = this.disclaimers_map[disclaimer?.id || disclaimer];
    if (
      this.disclaimerAlreadyProcessed(disclaimer) &&
      !extract_disclaimer_from_paragraphs_list
    ) {
      return this.disclaimerSymbol_superscript(disclaimer);
    }

    // count map entries
    var symbol = _disclaimer?.symbol || "{a}";

    // prepare global
    this._initListForDisclaimer(_disclaimer);
    var target_list = null;

    var current_page_list = this._getListForDisclaimer(disclaimer);

    var autonumber = false;
    // set symbol based on "symbol"
    // autonumber
    if (symbol === "{a}") {
      autonumber = true;
      symbol =
        "" +
        (this.getIndexedDisclaimerCount(disclaimer.disclaimer_is_global) + 1);
      target_list = current_page_list?.indexed || [];
    }
    // symbol override - no symbol
    else if (!symbol || symbol === "{n}") {
      symbol = "";
      target_list = current_page_list.overridden;
    }
    // symbol override - custom
    else {
      symbol = _disclaimer?.symbol + "";
      target_list = current_page_list.overridden;
    }

    // Mark processed disclaimer for extraction from paragraph list
    if (extract_disclaimer_from_paragraphs_list) {
      let existing_disclaimer_entry = findIndex(
        target_list,
        (d) => d.id === disclaimer.id
      );
      if (existing_disclaimer_entry > -1) {
        target_list[existing_disclaimer_entry].extract = true;
        return this.disclaimer_sup_symbols_by_id[_disclaimer.id];
      }
    }

    const disclaimer_data_obj = {
      id: _disclaimer?.id,
      symbol: symbol,
      body: this.cleanupDisclaimerCopy(_disclaimer?.body),
      sort: _disclaimer.sort,
      autonumber: autonumber,
      getLocalized: _disclaimer?.getLocalized
    };

    if (extract_disclaimer_from_paragraphs_list)
      disclaimer_data_obj.extract = true;

    // add to "processed" disclaimers list
    target_list.push(disclaimer_data_obj);

    // mark disclaimer as processed by assigning a value to the mapped id
    this.disclaimer_symbols_by_id[_disclaimer.id] = symbol;
    this.disclaimer_sup_symbols_by_id[_disclaimer.id] = `<sup>${symbol}</sup>`;
    return this.disclaimer_sup_symbols_by_id[_disclaimer.id];
  }

  processString(str, disclaimer) {
    if (typeof str !== "string") return "";

    // find & replace "insert here" disclaimer {^}
    var match = str.match(/\{\^\}/);
    var replacement = "";
    if (match) {
      if (disclaimer) replacement = this.processDisclaimer(disclaimer);
      str = str.replace(match, replacement);
    }
    // no "insert here" disclaimer
    else {
      // append given disclaimer to end
      if (disclaimer) str = str + this.processDisclaimer(disclaimer);
    }

    // replace {^variables} with appropriate superscript
    var matches = [...str.matchAll(/\{\^(.*?)\}/g)];
    matches.forEach((match) => {
      const id = parseInt(match[1]);

      // In the form {^id} or {^shortname} (AKA tag)
      var matched_disclaimer =
        this.disclaimers_map[id] || this.disclaimers_map[match[0]];

      // Prefix curlies and carat with backslash
      var match_target = match[0].replace(/([}{^])/g, "\\$1");
      replacement = "";
      if (matched_disclaimer)
        replacement = this.processDisclaimer(matched_disclaimer);

      var regexp = new RegExp(match_target, "g");
      str = str.replace(regexp, replacement);
    });

    return str;
  }
}

export default DisclaimerProcessor;
