import { applyNewLogic, getColSpansForDataTable } from "./ModularFunctions";
import jsonLogic from "./vendor/JSONLogic";

jsonLogic.add_operation("ceiling", (number, significance) => {
  return Math.ceil(number / significance) * significance;
});

jsonLogic.add_operation("filter_by_key", (list, key, values = []) => {
  return list
    .filter((obj) => values.indexOf(obj[key]) > -1)
    .sort((a, b) => values.indexOf(b[key]) - values.indexOf(a[key]));
});

// Iterate through attributes, collecting its name and related product feature values
jsonLogic.add_operation("match_product_attributes", (attributes, products) => {
  return attributes.map((attr, attr_idx) => {
    const productValues = [
      ...products.map((product) => ({
        text: product.features_data_table[attr_idx],
        span: 1
      }))
    ];
    const mergeValues = attr.merge_equal_values_within_spec_table;
    return [attr.label, ...getColSpansForDataTable(productValues, mergeValues)];
  });
});

jsonLogic.add_operation("hasOnly", (a, b) => {
  if (!a || typeof a.indexOf === "undefined") return false;
  if (!b || typeof b.indexOf === "undefined") return false;
  if (a.length !== b.length) return false;
  const as = new Set(a);
  return b.every((x) => as.has(x));
});

jsonLogic.add_operation("either", (a, b) => {
  if (!Array.isArray(b) || b.length !== 2) return false;
  return a == b[0] || a == b[1];
});

jsonLogic.add_operation("includes", (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  const as = new Set(a);
  return b.every((x) => as.has(x));
});
jsonLogic.add_operation("excludes", (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  const as = new Set(a);
  return b.every((x) => !as.has(x));
});

jsonLogic.add_operation("any", (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  const as = new Set(a);
  return b.some((x) => as.has(x));
});

jsonLogic.add_operation("newLogic", (a, b) => applyNewLogic(a, b));

export default jsonLogic;
