import {
  pxToPt,
  createButton_v2,
  createBorder,
  createBorder_v2,
  getBgProps,
  createText,
  createBG_v2,
  getRelativeElementPosition,
  offsetElementPositions,
  createTextElementsFromContainer_v2,
  _createTextStructure
} from "./util";

/**
 * Creates a radio form element object for JPE.
 */
function createRadio(element, configs, options = {}) {
  const { name = "", action, form = {}, ...other_options } = options;
  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);
  const { page } = configs;

  return {
    name,
    position: getRelativeElementPosition(element_rect, configs.page),
    form: {
      type: "radio",
      action: options.action,
      ...form
    },
    ...other_options
  };
}

/**
 * Creates a checkbox form element object for JPE.
 */
function createCheckbox(element, configs, options = {}) {
  const { name = "", form = {}, ...other_options } = options;
  const element_rect = element.getBoundingClientRect();

  return {
    name,
    position: getRelativeElementPosition(element_rect, configs.page),
    form: {
      type: "checkbox",
      ...form
    },
    ...other_options
  };
}

/**
 * Creates a "custom" checkbox object for the JPE.
 *
 * element - the "label" element next to a button
 * configs - module configs (including current page info)
 * options:
 *      name - checkbox container name, and prefix for children
 *      label -
 */
function createCustomCheckbox(element, configs, options = {}) {
  const {
    name = "",
    label,
    btn_fn = () => {},
    draw_border = true,
    cb_checked = false
  } = options;

  const parent = element.parentElement.parentElement;
  const item_rect = parent.getBoundingClientRect();

  const button = parent.querySelector("button");
  const button_rect = button.getBoundingClientRect();
  const button_styles = window.getComputedStyle(button);

  const icon = button.querySelector(".icon");

  let elements = [];

  if (!!label) {
    elements.push(
      createText(element, configs, "text", {
        content: label
      })
    );
  }

  // Create checkbox border
  if (draw_border) {
    elements.push(
      createBorder(button, configs, {
        name: "cb_icon"
      })
    );
  }

  let cb_mark_props = {};
  if (!cb_checked) {
    cb_mark_props.hidden = true;
  }

  elements = [
    ...elements,

    // Check mark
    createText(icon, configs, "mark", {}, cb_mark_props),

    // Create Button for entire checkbox row
    createButton_v2(parent, configs, {
      name: "btn",
      action: {
        mouseClick: btn_fn
      }
    }),

    // Create checkbox to keep track of state
    createCheckbox(button, configs, {
      name: "cb",
      hidden: true
    })
  ];

  return {
    // Create text layer
    name,
    elements
  };
}

function createFormObject(element, configs, options = {}) {
  const { name = "", form = {}, ...other_options } = options;
  const { type = "" } = form;
  const element_rect = element.getBoundingClientRect();

  return {
    name,
    position: getRelativeElementPosition(element_rect, configs.page),
    form: {
      type,
      ...form
    },
    ...other_options
  };
}

function getBorderThickness(border_str) {
  const v = parseInt(border_str) - 1;
  if (v > 0) return v;
  return 0;
}

// Offsets an element's position based on either padding or the border
function offsetElementByPadding(element, input_element, use_padding) {
  const computed_style = window.getComputedStyle(element);
  const {
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,

    borderTop,
    borderLeft,
    borderBottom,
    borderRight
  } = computed_style;

  const padding_left = pxToPt(parseFloat(paddingLeft));
  const padding_right = pxToPt(parseFloat(paddingRight));
  const padding_top = pxToPt(parseFloat(paddingTop));
  const padding_bottom = pxToPt(parseFloat(paddingBottom));

  const border_top = getBorderThickness(borderTop);
  const border_bottom = getBorderThickness(borderBottom);
  const border_left = getBorderThickness(borderLeft);
  const border_right = getBorderThickness(borderRight);

  const offset_right = use_padding ? padding_right : border_right;

  const offsets = {
    x: border_left,
    y: border_bottom,
    width: -(border_left + offset_right),
    height: -(border_top + border_bottom)
  };

  offsetElementPositions([input_element], offsets);
  return input_element;
}
/**
 * Creates the components for a custom form input.
 * These components include:
 *  - border
 *  - background
 *  - overlay (optional)
 *  - form input
 */
function createInputField(element, configs, options = {}) {
  const { input, ...other_options } = options;
  const sibling = element.nextElementSibling;
  const with_overlay = sibling && sibling.classList.contains("overlay-text");

  let elements = [
    // BG
    createBG_v2(element, configs, { name: "input", include_border: true })
  ];

  const { form: input_form, ...other_input_options } = input;

  // Remove family to default to Helvetica
  const { family, ...input_text } = _createTextStructure(element, {
    content: ""
  });

  let input_element = createFormObject(element, configs, {
    name: element.name,
    form: { type: element.type, ...input_form },
    text: input_text,
    ...other_input_options
  });

  if (with_overlay) {
    elements.push(createText(sibling, configs, "overlay"));
  }

  input_element = offsetElementByPadding(element, input_element, with_overlay);

  return {
    ...input_element,
    elements: elements,
    ...other_options
  };
}

function createDropdownField(dropdown, configs, options = {}) {
  const { name = "", value } = options;
  const element_rect = dropdown.getBoundingClientRect();
  // Remove family to default to Helvetica
  const { family, ...input_text } = _createTextStructure(dropdown, {
    content: ""
  });

  const value_to_use = value || dropdown.options[0]?.textContent || "";
  const dropdown_position = getRelativeElementPosition(
    element_rect,
    configs.page
  );

  // Shrink by 1px in every direction due to border
  offsetElementPositions(dropdown_position, {
    y: 1,
    x: 1,
    width: -2,
    height: -1
  });

  return {
    name: name || dropdown.name,
    position: dropdown_position,
    text: input_text,
    form: {
      type: "dropdown",
      options: [...dropdown.options].map((o) => o.textContent),
      value: value_to_use
    },

    elements: [
      // BG + border
      createBG_v2(dropdown, configs, { name: "bg", include_border: true })
    ]
  };
}

function createDropdownChevrons(container, configs) {
  let chevrons = createTextElementsFromContainer_v2(container, configs);
  // Mark second chevron as hidden
  chevrons[1].hidden = true;
  return chevrons;
}

/**
 * Creates a "custom" checkbox object for the JPE.
 *
 * element - the "label" element next to a button
 * configs - module configs (including current page info)
 * options:
 *      name - checkbox container name, and prefix for children
 *      label -
 */
// function createCustomCheckbox(element, configs, options = {}) {
//   const {
//     name = "",
//     label,
//     btn_fn = () => {},
//     draw_border = true,
//     cb_checked = false
//   } = options;

//   const parent = element.parentElement.parentElement;
//   const item_rect = parent.getBoundingClientRect();

//   const button = parent.querySelector("button");
//   const button_rect = button.getBoundingClientRect();
//   const button_styles = window.getComputedStyle(button);

//   const icon = button.querySelector(".icon");

//   let elements = [];

//   if (!!label) {
//     elements.push(
//       createText(element, configs, "text", {
//         content: label
//       })
//     );
//   }

//   // Create checkbox border
//   if (draw_border) {
//     elements.push(
//       createBorder(button, configs, {
//         name: "cb_icon"
//       })
//     );
//   }

//   let cb_mark_props = {};
//   if (!cb_checked) {
//     cb_mark_props.hidden = true;
//   }

//   elements = [
//     ...elements,

//     // Check mark
//     createText(icon, configs, "mark", {}, cb_mark_props),

//     // Create Button for entire checkbox row
//     createButton_v2(parent, configs, {
//       name: "btn",
//       action: {
//         mouseClick: btn_fn
//       }
//     }),

//     // Create checkbox to keep track of state
//     createCheckbox(button, configs, {
//       name: "cb",
//       hidden: true
//     })
//   ];

//   return {
//     // Create text layer
//     name,
//     elements
//   };
// }

function createCustomDropdownItems(container, configs, options = {}) {
  const { html_id, filter_idx } = options;
  const list_items_container = container.querySelector("ul");
  const list_items = [...list_items_container.children];

  return [
    // Create BG
    createBG_v2(list_items_container, configs, {
      name: "bg"
    }),
    // Create border for BG
    createBorder_v2(list_items_container, configs, {
      down: true,
      left: true,
      right: true
    }),

    ...list_items.flatMap((list_item, item_idx) => {
      const list_item_container = list_item.firstElementChild;
      const button = list_item.querySelector("button");
      const text = button.textContent;
      const text_obj = createText(button, configs, String(item_idx), {
        content: text
      });

      offsetElementByPadding(list_item_container, text_obj, true);
      // Button for selectable row
      const item_btn = createButton_v2(list_item_container, configs, {
        name: `btn-${item_idx}`,
        action: {
          mouseClick: `mf_dropdownItemClicked('${html_id}', ${filter_idx}, ${item_idx})`
        }
      });

      return [text_obj, item_btn];
    })
  ];
}

/**
 * Truncates a dropdown's label string with an ellipsis if it's too long.
 */
function truncateDropdownLabelString(str, max = 22) {
  if (str.length > max) return str.substr(0, max) + "...";
  return str;
}

function createCustomDropdownField(container, configs, options = {}) {
  const { name = "", html_id, value, filter_idx } = options;

  const toggle_button = container.querySelector("button");
  const button_styles = window.getComputedStyle(toggle_button);

  const items = [...container.querySelectorAll("ul > li")];

  const display_layers = items.map((i) => i.textContent);

  const text_ph = toggle_button.querySelector(".text");

  return {
    name: name || "dropdown",

    elements: [
      // Border
      createBorder_v2(toggle_button, configs, {
        up: true,
        down: true,
        left: true,
        right: true,

        alwaysVisible: true
      }),

      // Toggle button for entire dropdown
      createButton_v2(toggle_button, configs, {
        name: "toggle_btn",
        action: {
          mouseClick: `mf_dropdownToggled('${html_id}', ${filter_idx})`
        },
        alwaysVisible: true,
        ...getBgProps(button_styles, true, { color: "#ffffff" })
      }),

      // Create a form field to hold index of selection
      createText(
        text_ph,
        configs,
        "value",
        {
          content: "0"
        },
        {
          convertToTextField: true,
          hidden: true,
          // Initialize to first index of values
          form: { value: "0", editable: true }
        }
      ),

      createDropdownLabels(text_ph, configs, { display_layers }),

      {
        name: "chevrons",
        elements: createDropdownChevrons(toggle_button, configs)
      },

      {
        name: "items",
        hidden: true,
        elements: createCustomDropdownItems(container, configs, {
          html_id,
          filter_idx
        })
      }
    ]
  };
}

/**
 * Creates an individual input object.
 */
function createFormField(element, configs, options = {}) {
  const tagName = element.tagName.toLowerCase();
  switch (tagName) {
    case "input":
      return createInputField(element, configs, options);

    case "select":
      return createDropdownField(element, configs, options);

    default:
      console.error("Form element type unrecognized: " + tagName);
      return {};
  }
}

/**
 * Creates JPE-friendly input objects.
 *
 * Objects are created based on form input type.
 * Supported elements:
 *  - input[type=text]
 *  - input[type=number]
 *  - select
 */
function createFormFieldsFromContainer(container, configs, options = {}) {
  const form_inputs = [...container.querySelectorAll("input, select")];

  return [
    {
      name: "form",
      elements: form_inputs.map((input) =>
        createFormField(input, configs, options)
      )
    }
  ];
}

function createDropdownLabels(text_ph, configs, options) {
  const { display_layers } = options;
  return {
    name: "labels",
    elements:
      // Layer for every possible entry, as well as
      // one for "Multiple selected" and one for "-"
      display_layers.map((text, text_idx) =>
        createText(
          text_ph,
          configs,
          String(text_idx),
          {
            content: truncateDropdownLabelString(text)
          },
          {
            hidden: text_idx > 0
          }
        )
      )
  };
}

function createTooltips(tooltips, configs, options) {
  const { name } = options;
  const { page } = configs;
  const { idx } = page;
  return {
    name,
    page: idx,
    elements: tooltips.map((tooltip, tooltip_idx) => {
      const tooltip_rect = tooltip.getBoundingClientRect();
      return {
        name: String(tooltip_idx),
        form: {
          tooltip: tooltip.getAttribute("data-tooltip-text"),
          type: "button"
        },
        position: getRelativeElementPosition(tooltip_rect, configs.page)
      };
    })
  };
}

export {
  createFormFieldsFromContainer,
  createRadio,
  createCheckbox,
  createCustomCheckbox,
  createDropdownField,
  createCustomDropdownField,
  createDropdownChevrons,
  createDropdownLabels,
  createTooltips
};
