const scripts = `
try {
  // Settings
  var hotspots_config = {{script_vars}};

  // Hotspots
  var popupTimeOut = null;
  var active_popup_layer_name = null;

  function hidePopup_delayed(module_name_pfx, popup_idx, delay) {
    clearPopupTimeOut();
    const name = module_name_pfx + '_' + popup_idx;
    const action = 'togglePopup("' + module_name_pfx + '", ' + popup_idx + ', false)';
    const _delay = delay || hotspots_config[module_name_pfx].popup_delay;
    popupTimeOut = app.setTimeOut(action, _delay);
  }

  function clearPopupTimeOut() {
    if (popupTimeOut != null)
      app.clearTimeOut(popupTimeOut);
  }
  var popup_fields = ['popup-container','popup-cta-1','popup-cta-2'];
  function togglePopup(module_name_pfx, popup_idx, state) {
    const name = module_name_pfx + '_' + popup_idx;
    toggleLayer(name, state);

    // Show fields
    for (var i = 0; i < popup_fields.length; ++i) {
      var field_name = name + "_" + popup_fields[i];
      if (getField(field_name)) {
        toggleFieldVisibility(field_name, state);
      }
    }
    // Clear active name name as necessary
    if (!state && name === active_popup_layer_name) {
      active_popup_layer_name = null;
      showHoverButtons(module_name_pfx);
    }
  }
  function showPopup(module_name_pfx, popup_idx) {
    clearPopupTimeOut();

    // Skip when showing the same popup
    const name = module_name_pfx + '_' + popup_idx;
    if (active_popup_layer_name === name)
      return;
    // But first, hide the previous popup if it exists
    if (active_popup_layer_name) {
      togglePopup(module_name_pfx, popup_idx, false);
      active_popup_layer_name = null;
    }
    // Hide all other hover buttons
    hideHoverButtonsExcept(module_name_pfx, popup_idx);
    togglePopup(module_name_pfx, popup_idx, true);
    active_popup_layer_name = name;
  }
  function hidePopup(module_name_pfx, popup_idx) {
    hidePopup_delayed(module_name_pfx, popup_idx);
  }

  function getHoverButtonNames(module_name_pfx) {
    var names = [];
    const max_buttons = hotspots_config[module_name_pfx].total_hotspots;
    for (var i = 0; i < max_buttons; ++i) {
      const field_name = module_name_pfx + '_' + i + '_hover-button';
      names.push(field_name);
    }
    return names;
  }
  function showHoverButtons(module_name_pfx) {
    const field_names = getHoverButtonNames(module_name_pfx);
    field_names.forEach((field_name) => {
      toggleFieldVisibility(field_name, true);
    });
  }
  function hideHoverButtonsExcept(module_name_pfx, popup_idx) {
    const field_names = getHoverButtonNames(module_name_pfx);
    field_names.forEach((field_name, hover_btn_idx) => {
      const new_visibility_state = hover_btn_idx == popup_idx;
      toggleFieldVisibility(field_name, new_visibility_state);
    });
  }
} catch (e) {
  app.alert('Hotspots module error: ' + e);
}
`;

export default scripts;
