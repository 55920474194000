import {
  createBG_v2,
  createBorder,
  createBorder_v2,
  createButton_v2,
  createImage,
  createText,
  createTextElementsFromContainer,
  getBgProps,
  getBorderProps,
  getElementPosition,
  getRelativeElementPosition,
  getRoundedRadius,
  offsetElementPositions
} from "../util";

import {
  createCustomCheckbox,
  createCustomDropdownField,
  createDropdownChevrons,
  createDropdownLabels,
  createRadio
} from "../forms";

import { remove_all_tags } from "../../../Functions";

import scripts from "./scripts";

function createMultiSelectItems(
  mod,
  configs,
  multi_select_item_elements,
  filter_idx,
  filter
) {
  const { html_id } = mod;
  return filter.items.map((multi_select_item, item_idx) => {
    const element = multi_select_item_elements[item_idx];
    const btn_fn = `mf_multiItemClicked('${html_id}', ${filter_idx}, ${item_idx})`;

    return createCustomCheckbox(element, configs, {
      name: String(item_idx),
      label: multi_select_item,
      btn_fn
    });
  });
}

function createMultiSelectCollapser(element, mod, configs, filter_idx) {
  const { html_id } = mod;
  const bg = createBG_v2(element, configs, { name: "bg" });
  const offset_positions = [bg];

  offsetElementPositions(offset_positions, {
    y: -1.25,
    x: -1.25,
    width: 2.5,
    height: 1
  });
  const offset_position = bg.position;

  const button_obj = {
    ...bg,
    ...createButton_v2(element, configs, {
      name: "btn",
      action: {
        mouseClick: `mf_multiToggled('${html_id}', ${filter_idx})`
      }
    }),
    position: offset_position
  };
  return {
    name: "collapser",
    elements: [
      button_obj,
      ...createTextElementsFromContainer(element, "", configs)
    ]
  };
}

function createMultiSelects(mod, configs) {
  const { containing_element } = configs;
  const { html_id, content = {} } = mod;
  const { attribute_filters = [] } = content;

  return (
    attribute_filters
      .map((filter, filter_idx) => {
        if (filter.type !== "multi") return null;

        const filter_selector = `.filter:nth-child(${filter_idx + 1})`;
        const filter_container =
          containing_element.querySelector(filter_selector);
        const multi_select_container_element =
          filter_container.querySelector("ul");
        const container_rect =
          multi_select_container_element.getBoundingClientRect();
        const multi_select_item_elements =
          multi_select_container_element.querySelectorAll("li label");

        const text_ph = filter_container.querySelector(".text");

        const toggle_button = filter_container.querySelector("button");
        const display_layers = ["\u2013", "Multiple selected", ...filter.items];
        const chevron_elements = createDropdownChevrons(toggle_button, configs);

        const collapser =
          multi_select_container_element.querySelector(".collapser");

        return {
          name: `multi_${filter_idx}`,
          elements: [
            // Border
            createBorder_v2(toggle_button, configs, {
              up: true,
              down: true,
              left: true,
              right: true,

              alwaysVisible: true
            }),

            createDropdownLabels(text_ph, configs, { display_layers }),

            {
              name: "chevrons",
              // Create chevrons
              elements: chevron_elements
            },
            {
              name: "items",
              hidden: true,
              elements: [
                // Create BG
                {
                  name: "bg",
                  // BG
                  position: getRelativeElementPosition(
                    container_rect,
                    configs.page
                  ),
                  backgroundColor: "#ffffff"
                },

                // Create border for BG
                createBorder_v2(multi_select_container_element, configs, {
                  down: true,
                  left: true,
                  right: true
                }),

                ...createMultiSelectItems(
                  mod,
                  configs,
                  multi_select_item_elements,
                  filter_idx,
                  filter
                ),

                // Collapser
                createMultiSelectCollapser(collapser, mod, configs, filter_idx),

                // Toggle button for entire multi
                createButton_v2(toggle_button, configs, {
                  name: "toggle_btn",
                  action: {
                    mouseClick: `mf_multiToggled('${html_id}', ${filter_idx})`
                  },
                  alwaysVisible: true
                })
              ]
            }
          ]
        };
      })
      // Remove nulls (non-multis)
      .filter((v) => !!v)
  );
}

function createResistanceIcon(element, configs, hex_color, name) {
  const computed_style = window.getComputedStyle(element);
  const item_rect = element.getBoundingClientRect();
  return {
    name: name,
    position: getRelativeElementPosition(item_rect, configs.page),
    ...getBgProps(computed_style, false, { backgroundColor: hex_color })
  };
}

function createResistanceIcons(element, configs, name) {
  const { resistance_colors } = configs;
  const resistance_color_keys = Object.keys(resistance_colors);
  return {
    name,
    elements: [
      ...resistance_color_keys.map((key, idx) =>
        createResistanceIcon(
          element,
          configs,
          resistance_colors[key],
          String(idx)
        )
      )
    ]
  };
}

function createCerts(container, configs, prefix) {
  const text_elements = [...container.querySelectorAll(".tag")];

  return text_elements.flatMap((e, el_idx) => {
    const tag_container_styles = window.getComputedStyle(e.parentElement);
    const parent_rect = e.parentElement.getBoundingClientRect();
    return {
      name: `${prefix}-${el_idx}`,
      elements: [
        createText(e, configs, "text"),
        {
          name: "border",
          position: getRelativeElementPosition(parent_rect, configs.page),
          ...getBorderProps(tag_container_styles.border),
          borderRadius: getRoundedRadius(tag_container_styles)
        }
      ]
    };
  });
}

function createProductRow(row, mod, configs, row_idx) {
  const { html_id } = mod;

  const warning = row.querySelector(".warning span");
  const value = row.querySelector(".value");
  const wr_ph = row.querySelector(".water-resistance .css-icon");
  const ar_ph = row.querySelector(".alcohol-resistance .css-icon");
  const certs = row.querySelector(".tags");
  const link_img = row.querySelector(".data-sheet img");

  const els = [
    // Warning
    createText(warning, configs, "warning"),

    // Text
    createText(
      value,
      configs,
      "header",
      { content: " " },
      { convertToTextField: true }
    ),

    // Water resistance icons
    createResistanceIcons(wr_ph, configs, "wr"),

    // Alcohol resistance icons
    createResistanceIcons(ar_ph, configs, "ar"),

    // Certs
    ...createCerts(certs, configs, "cert"),

    // Data sheet link image
    createImage(configs, link_img, "data-sheet"),

    // Data sheet button
    createButton_v2(link_img, configs, {
      name: "data-sheet",
      action: {
        mouseClick: `mf_dataSheetClicked('${html_id}', ${row_idx})`
      }
    })
  ];
  // offsetElementPositions(els, { y: 4 });
  return els;
}

function getResistanceColors(mod, configs) {
  // TODO: extract data from globals in a sensible way...
  return {
    good: "#5fa545",
    ok: "#f2c00e",
    na: "#6b757a"
  };
}

function createProductRows(mod, configs) {
  const { page, fonts, containing_element } = configs;

  const resistance_colors = getResistanceColors(mod, configs);

  const enhanced_configs = {
    ...configs,
    resistance_colors
  };

  const table = containing_element.querySelector("table");
  const rows = [...table.querySelectorAll("tbody tr")];
  return {
    name: "table",
    elements: rows.map((row, row_idx) => ({
      name: `row_${row_idx}`,
      elements: createProductRow(row, mod, enhanced_configs, row_idx)
    }))
  };
}

function createBinaryPaginationButtonText(container, configs, prefix = "prev") {
  const text = container.querySelector(".text");
  const icon = container.querySelector(".icon");

  return [
    {
      name: `${prefix}_disabled`,
      elements: [
        // "prev" text
        createText(text, configs, "text", { color: "#6B757A" }),

        // "prev" arrow
        createText(icon, configs, "arrow", { color: "#6B757A" })
      ]
    },
    {
      name: `${prefix}_enabled`,
      elements: [
        // "prev" text
        createText(text, configs, "text", { color: "#003087" }),

        // "prev" arrow
        createText(icon, configs, "arrow", { color: "#003087" })
      ]
    }
  ];
}

function createResultsRow(mod, configs) {
  const { containing_element } = configs;
  const { html_id, content = {} } = mod;
  const { products = [] } = content;

  const btn_prev = containing_element.querySelector(".btn-prev");

  const btn_next = containing_element.querySelector(".btn-next");

  const text_results = containing_element.querySelector(".results");
  const total_models = products.length;

  // Mark all products as matched (no filters selected)
  const matched_product_ids = Array(total_models)
    .fill(0)
    .map((_, idx) => idx);

  const pagination_data = JSON.stringify({
    page_start: 0,
    matched_product_ids
  });

  const ph_json = containing_element.querySelector(".json-ph");
  const elements = [
    ...createBinaryPaginationButtonText(btn_prev, configs, "prev"),

    // "prev" button
    createButton_v2(btn_prev, configs, {
      name: "prev_btn",
      action: { mouseClick: `mf_prevResultsPage('${html_id}')` }
    }),

    // Pagination results
    createText(
      text_results,
      configs,
      "text",
      {
        content: " "
      },
      {
        convertToTextField: true
      }
    ),

    // Pagination data
    {
      ...createText(
        ph_json,
        configs,
        "json",
        {
          content: pagination_data
        },
        { convertToTextField: true }
      ),
      hidden: true
    },

    ...createBinaryPaginationButtonText(btn_next, configs, "next"),

    // "next" button
    createButton_v2(btn_next, configs, {
      name: "next_btn",
      action: { mouseClick: `mf_nextResultsPage('${html_id}')` }
    })
  ];

  return {
    name: "results",
    alwaysVisible: true,
    elements
  };
}

function createClearFiltersButton(mod, configs) {
  const { containing_element } = configs;
  const { html_id } = mod;
  const btn_clear_filters = containing_element.querySelector(".clear-filters");
  return [
    createButton_v2(btn_clear_filters, configs, {
      name: "clear-filters",
      action: { mouseClick: `mf_clearFilters('${html_id}')` }
    })
  ];
}

function createAcrobatMessage(mod, configs) {
  const { html_id, content = {} } = mod;
  const { containing_element } = configs;
  const element = containing_element.querySelector(".acrobat-message");
  const element_rect = element.getBoundingClientRect();
  const elements = [...createTextElementsFromContainer(element, "", configs)];
  const element_styles = window.getComputedStyle(element);
  const [message_icon, message_text, message_dismiss] = elements;
  elements.forEach((e) => {
    //arbitrary offset to center text better within div
    e.position.y = e.position.y - 1.5;
  });
  //line up icon with the rest of the text
  message_icon.text.lineHeight = 0;
  message_icon.name = "acrobat-message_icon";
  message_text.name = "acrobat-message_text";
  message_dismiss.name = "dismiss";
  //add dismiss functionality to "dismiss" text
  message_dismiss.form = {
    type: "button",
    action: { mouseClick: `mf_hideAcrobatMessage('${html_id}')` }
  };
  const res = {
    name: "acrobat-message",
    position: getRelativeElementPosition(element_rect, configs.page),
    elements: elements,
    ...getBgProps(element_styles, false)
  };
  return res;
}

function createRadioSelect(mod, configs, radio_container, idx) {
  const { html_id } = mod;

  const radio_items = [...radio_container.querySelectorAll(".radio-item")];
  const radio_containers = radio_items.map((item, item_idx) =>
    item.querySelector(".button-container")
  );
  const container_name = `radio-${idx}`;

  const radio_item_elements = radio_containers.flatMap(
    (button_container, item_idx) => {
      const radio_click_action = `mf_radioClicked('${html_id}', ${idx}, ${item_idx})`;
      const border_color = button_container.getAttribute("data-border-color");
      return [
        {
          name: String(item_idx),
          elements: [
            // Outline (for when selected)
            createBorder(button_container, configs, {
              name: "outline",
              hidden: true,
              color: border_color
            }),

            // Clickable button
            createButton_v2(button_container, configs, {
              name: "button",
              action: { mouseClick: radio_click_action }
            })
          ]
        },
        // Hidden value
        createRadio(button_container, configs, {
          name: "value",
          hidden: true,
          form: { value: item_idx }
        })
      ];
    }
  );

  return {
    name: container_name,
    elements: radio_item_elements
  };
}

function createRadioSelects(mod, configs) {
  const { containing_element } = configs;
  const { html_id, content = {} } = mod;
  const { attribute_filters = [] } = content;

  return (
    attribute_filters
      .map((filter, filter_idx) => {
        if (filter.type !== "radio") return null;

        const selector = `.filter:nth-child(${
          filter_idx + 1
        }) .radio-container`;
        const radio_item = containing_element.querySelector(selector);

        return createRadioSelect(mod, configs, radio_item, filter_idx);
      })
      // Remove nulls
      .filter((v) => !!v)
  );
}

function createCheckboxes(mod, configs) {
  const { containing_element } = configs;
  const { html_id, content = {} } = mod;
  const { attribute_filters = [] } = content;

  return attribute_filters
    .flatMap((filter, filter_idx) => {
      if (filter.type !== "checkboxes") return null;
      const selector = `.filter:nth-child(${filter_idx + 1}) button`;
      const checkbox_buttons = [
        ...containing_element.querySelectorAll(selector)
      ];

      return checkbox_buttons.map((cb, cb_idx) => {
        const btn_fn = `mf_pseudoCbClicked('${html_id}', ${filter_idx}, ${cb_idx})`;
        return createCustomCheckbox(cb, configs, {
          name: `cb-${filter_idx}-${cb_idx}`,
          btn_fn,
          draw_border: false
        });
      });
    })
    .filter((v) => !!v);
}

function createDropdowns(mod, configs) {
  const { containing_element } = configs;
  const { html_id, content = {} } = mod;
  const { attribute_filters = [] } = content;

  return attribute_filters
    .flatMap((filter, filter_idx) => {
      if (filter.type !== "dropdown") return null;
      const selector = `.filter:nth-child(${filter_idx + 1}) button`;
      const button = containing_element.querySelector(selector);
      const button_container = button.parentElement;
      return createCustomDropdownField(button_container, configs, {
        name: `dropdown-${filter_idx}`,
        html_id,
        filter_idx
      });
    })
    .filter((v) => !!v);
}

function createElements(mod, configs) {
  const filter_callbacks = [
    createRadioSelects,
    createMultiSelects,
    createCheckboxes,
    createClearFiltersButton,
    createDropdowns,
    createAcrobatMessage
  ];
  const elements = [
    // Controls: multiselects
    ...filter_callbacks.flatMap((fn) => fn(mod, configs)),
    // Table
    createProductRows(mod, configs),
    // Results row :"previous" & "next" buttons, and
    // "showing results"
    createResultsRow(mod, configs)
  ];
  return elements;
}

function getScriptVariables(mod, configs) {
  const { containing_element } = configs;
  const { html_id, content = {} } = mod;
  const {
    products: model_filter = [],
    attribute_filters = [],
    primary_brand_disclaimer,
    page_size = 10
  } = content;

  // Indices of filters, as follows
  // filter_indices: {
  //   multi: [1],
  //   radio: [3, 4],
  //   checkboxes: [5],
  //   dropdown: [0, 2]
  // },
  let filter_indices = attribute_filters.reduce(
    (acc, filter, idx) => ({
      ...acc,
      [filter.type]: [...(acc[filter.type] || []), idx]
    }),
    {}
  );

  // Filter items count by filter index, corresponding to filter_indices
  // filter_counts: [1, 5, 2, 3, 3, 2],
  const filter_counts = [
    ...attribute_filters.map((filter, filter_idx) => filter.items.length)
  ];

  const brand_names = model_filter.reduce(
    (acc, { brand }) => ({
      ...acc,
      [brand?.brand]: true
    }),
    {}
  );
  const brands = Object.keys(brand_names);

  const model_names = attribute_filters[0].items;
  const materials = attribute_filters[1].items;
  const adhesives = attribute_filters[2].items;
  const resistance_keys = attribute_filters[3].items;

  const products = model_filter.map((product) => {
    const {
      brand,
      model = "",
      sku,
      may_show_star_wheel_marks,
      spec_sheet_url,
      related_models,
      material,
      adhesive,
      water_resistance,
      alcohol_resistance,
      bs_5609_certification,
      ul_certification
    } = product;

    const processed_model = remove_all_tags(model);
    const product_model_sku = `${processed_model} (${sku})`;

    const certs = [bs_5609_certification && 0, ul_certification && 1].filter(
      (v) => v !== false
    );

    // Indexed by model_names
    const related_model_indices = model_names
      .map((n, idx) => (related_models.indexOf(n) > -1 ? idx : -1))
      // Remove -1s
      .filter((v) => v > -1);

    const material_idx = materials.indexOf(material);

    const adhesive_idx = adhesives.indexOf(adhesive);
    const brand_idx = brands.indexOf(brand?.brand);
    const water_resistance_idx = resistance_keys.indexOf(water_resistance);
    const alcohol_resistance_idx = resistance_keys.indexOf(alcohol_resistance);

    // [0,1], null
    const product_data = [
      // bool: Star wheel marks
      may_show_star_wheel_marks,
      // int: Brand idx
      brand_idx,
      // str: "Model (SKU)""
      product_model_sku,
      // Water Resist
      water_resistance_idx,
      // Alcohol Resist
      alcohol_resistance_idx,
      // Certs
      certs,
      // Spec Sheet Url
      spec_sheet_url,

      // related model indices
      related_model_indices,
      // material index
      material_idx,
      // adhesive index
      adhesive_idx
    ];

    return product_data;
  });

  // TODO: count the number of options for the "certifications" attribute
  const max_certs = 2;

  return {
    primary_brand_idx: 0,
    primary_brand_disclaimer: primary_brand_disclaimer ? "*" : "",

    // multi_select_counts,
    // radio_select_counts,
    filter_indices,
    filter_counts,

    materials,
    adhesives,
    page_size,
    brands,
    products: products.map((p) =>
      p.map((v) => {
        if (typeof v !== "string") {
          return v;
        }

        return (
          v
            // Ensure all double quotes are escaped
            .replace(/"/g, '\\"')
            // .replace(/™/g, (s) => "\u2122")
            // Remove unicode characters
            .replace(/[^\x00-\x7F]/g, (s) => "")
            // Remove newlines
            .replace(/\n/g, "")
        );
      })
    ),
    max_certs
  };
}

function loadMediaFinderConfig(mod, configs) {
  const { page, containing_element } = configs;
  const { idx: page_idx } = page;
  const { html_id } = mod;
  const elements = createElements(mod, configs);

  return {
    config: {
      name: html_id,
      page: page_idx,
      position: getElementPosition(containing_element),
      elements
    },
    prefix: html_id,
    script_variables: getScriptVariables(mod, configs)
  };
}

export { loadMediaFinderConfig, scripts };
