const scripts = `

const globals = {{globals}};

var ocgs_map = {};
function loadOcgs() {
  var ocgs = this.getOCGs();
  for (var i = 0; i < ocgs.length; ++i) {
    // Use only first OCG
    if (!ocgs_map[ocgs[i].name])
      ocgs_map[ocgs[i].name] = ocgs[i]
  }
}
loadOcgs();

function toggleLayer(name, state) {
  // Show layer
  var ocg = ocgs_map[name];
  if (ocg && ocg.state != state)
    ocg.state = state;
}

function isOcgVisible(ocg_name) {
  if (!ocgs_map[ocg_name])
    return false;
  return ocgs_map[ocg_name].state;
}

function hideLayer(name) {
  toggleLayer(name, false);
}

function showLayer(name) {
  toggleLayer(name, true);
}

// Useful for avoiding having to "reload" content after
// setting current state
// 
// WARNING: This cannot be used in unprivileged context.
// function setOcgInitState(name, state) {
//   if (!ocgs_map[name])
//     return;
//   ocgs_map[name].initState = state;
// }

function toggleFieldVisibility(name, state) {
  if (state)
    showField(name);
  else
    hideField(name);
}
function showField(name) {
  setFieldVisibility(name, display.visible);
}
function hideField(name) {
  setFieldVisibility(name, display.hidden);
}

function setFieldValue(field_name, value) {
  try {
    var field = getField(field_name);
    if (!field)
      return;
    var toggleReadonly = false;
    if (field.readonly) {
      toggleReadonly = true;
      field.readonly = false;
    }
    field.value = value;
    if (toggleReadonly) {
      field.readonly = true;
    }
  } catch (e) {
    console.println('unable to set value of "' + field_name + '" to ' + value);
  }
}

function setFieldVisibility(field_name, display) {
  try {
    var field = getField(field_name);
    if (!field)
      return;
    var toggleReadonly = false;
    if (field.readonly) {
      toggleReadonly = true;
      field.readonly = false;
    }
    field.display = display;
    if (toggleReadonly) {
      field.readonly = true;
    }
  } catch (e) {
    console.println('Unable to set display of "' + field_name + '" to ' + display);
  }
}

function compareValues(a, b, op) {
  switch(op) {
    case '<': return a < b;
    case '<=': return a <= b;
    case '>': return a > b;
    case '>=': return a >= b;
    case '=': return a == b;
    case '==': return a === b;
    case '!': return a != b;
    case '!=': return a !== b;

    default: return false;
  }
}

function makeEmptyArray(n, default_val = '') {
  var a = new Array(n);
  for (var i = 0; i < n; ++i) {
    a[i] = default_val;
  }
  return a;
}

// Carousel helpers
// Expecting e.g. { 'carousel_2': { slide_count: 4, slide_current: 0 } }
// var carousels = {{carousels_info}};
var carousels = {};
function toggleFields(list, state, base) {
  const base_name = base || '';
  // We skip the first element because it is intended to have the opposite
  // visibility setting
  for (var i = 0; i < list.length; ++i) {
    var field_name = base_name + '_' + list[i];
    toggleFieldVisibility(field_name, state);
  }
  toggleFieldVisibility(base_name + '_' + list[0], !state);
}

function toggleSlide(carousel_name, id, state, fields) {
  var name_pfx = carousel_name + '_' + id;
  toggleFields(fields, state, name_pfx);
  toggleLayer(name_pfx, state);
}
function goToSlide(carousel_name, id, fields) {
  var slides_count = countSlides(carousel_name, fields);
  var current_slide_idx = carousels[carousel_name].slide_current;

  // Disable previous slide
  toggleSlide(carousel_name, current_slide_idx, false, fields);
  // But keep 

  // Save current slide id
  carousels[carousel_name].slide_current = id;
  // Enable next slide
  toggleSlide(carousel_name, id, true, fields);
}

function getCurrentSlideIdx(carousel_name) {
  return carousels[carousel_name].slide_current;
}

// Count slides by checking tab numbers
function countSlides(carousel_name, fields) {
  if (carousels[carousel_name]) {
    return carousels[carousel_name].slide_count;
  }
  carousels[carousel_name] = {
    slide_count: 0,
    slide_current: 0
  };

  var i = -1;
  var last_field = null;
  do {
    ++i;
    var field_name = carousel_name + '_' + i + '_' + fields[0];
    last_field = getField(field_name);
  } while (!!last_field);
  carousels[carousel_name].slide_count = i;
  return carousels[carousel_name].slide_count;
}
function goToNextSlide(carousel_name, fields) {
  var total_slides = countSlides(carousel_name, fields);
  var slideIdx = (getCurrentSlideIdx(carousel_name) + 1) % total_slides;
  goToSlide(carousel_name, slideIdx, fields);
}
function goToPrevSlide(carousel_name, fields) {
  var total_slides = countSlides(carousel_name, fields);
  var slideIdx = (getCurrentSlideIdx(carousel_name) - 1);
  if (slideIdx < 0)
    slideIdx += total_slides;
  goToSlide(carousel_name, slideIdx, fields);
}

const buildFieldName = (parts = [], separator = '_') => parts.join(separator);

// Toggles "off" one chevron and another one "on".
// If "expanded", the "up" chevron (assumed to be index 1) is set to on,
// while the "down" chevron (assumed to be index 0) is set to off.
// If "not expanded", "up" is set to off while "down" is set to on.
function reloadFieldChevrons(module_name_pfx, prefix, expanded) {
  // Build field name such as module-2_multi_1_chevrons_0
  const names_pfx = [module_name_pfx, prefix, 'chevrons'];

  // Toggle "expanded" (up arrow) layer
  toggleLayer(buildFieldName([...names_pfx, 1]), expanded);

  // Toggle "not expanded" (down arrow) layer
  toggleLayer(buildFieldName([...names_pfx, 0]), !expanded);
}

`;
export { scripts };
