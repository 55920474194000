import {
  loadCarouselConfig,
  scripts as carouselScripts
} from "./carousel/index";
import {
  loadHotspotsConfig,
  scripts as hotspotsScripts
} from "./hotspots/index";

import {
  loadTabbedContainerConfig,
  scripts as tabbedContainerScripts
} from "./tabbed-container/index";

import {
  loadConfigToolConfig,
  scripts as configToolScripts
} from "./config-tool/index";

import {
  loadMediaFinderConfig,
  scripts as mediaFinderScripts
} from "./media-finder/index";

import {
  loadFormRequestQuoteConfig,
  scripts as formRequestQuoteScripts
} from "./form_request-quote/index";

import { api_url } from "../Amplify";

import { scripts as globalScripts } from "./global_scripts";

import { localizeNumber, unicodeSubstitutions } from "../../Functions";

import { fillScript } from "./util";

function moveTabbedContentDynamicText() {
  const parents = [
    ...document.querySelectorAll(".wfe-am-v2-mod:not(.adjusted-for-pdf)")
  ];
  parents.forEach((dynamicTextContainer) => {
    moveDynamicText(dynamicTextContainer);
  });
}
function moveDynamicText(dynamicTextContainer) {
  const originalDynamicTexts = [
    ...dynamicTextContainer.querySelectorAll(".dynamic-text")
  ];
  const tagSet = new Set(["P", "STRONG", "B", "UL"]);
  originalDynamicTexts.forEach((o) => {
    let addedClassToChildren = false;
    [...o.childNodes].forEach((c) => {
      if (tagSet.has(c.tagName) || (o.tagName === "P" && !c.tagName)) {
        addedClassToChildren = true;
        addDynamicText(c, o);
      }
      if (!!addedClassToChildren) o.classList.remove("dynamic-text");
    });
  }); //to ensure this function doesn't move already adjusted classes
  dynamicTextContainer.classList.add("adjusted-for-pdf");
}

function addDynamicText(child, parent) {
  const { tagName } = child;
  if (!tagName) {
    parent.innerHTML = parent.innerHTML.replace(
      child.wholeText,
      `<span class="dynamic-text">${child.wholeText}</span>`
    );
  }
  if (tagName === "P" || tagName === "B" || tagName === "STRONG") {
    child.classList.add("dynamic-text");
    //remove nested tags to accommodate JPE
    if ([...child.querySelectorAll("p, strong, b")].length > 0) {
      let innerHTML = "";
      child.childNodes.forEach((c) => {
        if (!c.tagName) innerHTML += c.wholeText;
        else innerHTML += c.innerText;
      });
      child.innerHTML = innerHTML;
    }
  }
  if (tagName === "UL") {
    [...(child.querySelectorAll("li") || [])].forEach(
      (li) =>
        (li.innerHTML = `<span class="dynamic-bullet dynamic-text">•</span><span class="dynamic-text">${li.innerHTML}</span>`)
    );
  }
}

function fillGlobalScripts(scripts) {
  const global_vars = {
    base_url: window.location.origin + window.location.pathname,
    api_url,
    project: window.location.pathname.split("/")[1],
    // This is to be filled by the generator
    s3_key: "{{s3_key}}"
  };
  const global_vars_str = JSON.stringify(global_vars);
  return scripts.replace("{{globals}}", global_vars_str);
}

const module_types_map = {
  hotspots: {
    scripts: hotspotsScripts,
    loader: loadHotspotsConfig
  },
  carousel: {
    scripts: carouselScripts,
    loader: loadCarouselConfig
  },
  "tabbed-content": {
    scripts: tabbedContainerScripts,
    loader: loadTabbedContainerConfig
  },
  "config-tool": {
    scripts: configToolScripts,
    loader: loadConfigToolConfig
  },
  "media-finder": {
    scripts: mediaFinderScripts,
    loader: loadMediaFinderConfig
  },
  "form_request-quote": {
    scripts: formRequestQuoteScripts,
    loader: loadFormRequestQuoteConfig
  }
};

function loadModule(mod, configs, project, collections) {
  // Module not dynamic
  const dynamic_module_config = module_types_map[mod.type];
  if (!dynamic_module_config) {
    return null;
  }

  const enhanced_configs = {
    ...configs,
    containing_element: document.getElementById(mod.html_id)
  };
  return dynamic_module_config.loader(
    mod,
    enhanced_configs,
    project,
    collections
  );
}

function loadModularConfig(collections, project) {
  if (!collections) {
    return {};
  }

  const { pages, global_settings } = collections;
  const {
    decimal_separator,
    default_locale: { language: selected_locale }
  } = global_settings;

  // Delegate to imported version with default
  // values
  const _localizeNumber = (number) =>
    localizeNumber(number, selected_locale, decimal_separator);

  const fonts_prefix = `${window.location.origin}/fonts/global`;
  const fonts = {
    // HelveticaNeueLTPro-BdCn.ttf
    "Helvetica Neue LT W05_77 Bd Cn": `${fonts_prefix}/HelveticaNeueLTStd-BdCn.ttf`,
    "Helvetica Neue LT W05_47 Lt Cn": `${fonts_prefix}/HelveticaNeueLTStd-LtCn.ttf`,
    "Font Awesome": `${fonts_prefix}/fontawesome-webfont.ttf`
  };
  let modules = [];
  let existing_modules = {};
  moveTabbedContentDynamicText();
  pages.forEach((page, page_idx) => {
    const page_el = document.getElementById(page.html_id);
    const page_rect = page_el.getBoundingClientRect();
    const configs = {
      localizeNumber: _localizeNumber,
      page: {
        idx: page_idx + 1,
        x: page_rect.x,
        y: page_rect.y,
        width: page_el?.offsetWidth,
        height: page_el?.offsetHeight
      }
    };

    page.modules.forEach((mod, mod_idx) => {
      // Include one of each script per module
      const loaded_module = loadModule(mod, configs, project, collections);
      if (loaded_module) {
        const { config, script_variables, prefix } = loaded_module;
        if (!existing_modules[mod.type]) {
          existing_modules[mod.type] = {};
        }
        if (script_variables) {
          existing_modules[mod.type][prefix] = script_variables;
        }

        modules.push(config);
      }
    });

    // Removed since EvoPDF is better suited for handling tooltips within footer.
    // The main reason is that content may shift slightly when translating from
    // HTML to PDF, so text may not align exactly.
    // Footer links
    // const footer_tooltips = [
    //   ...page_el.querySelectorAll("footer [data-tooltip-text]")
    // ];

    // if (footer_tooltips.length > 0) {
    //   modules.push(
    //     createTooltips(footer_tooltips, configs, {
    //       name: "footer-tooltips"
    //     })
    //   );
    // }
  });

  // Use all found type names to collect known (optionally fillable) script templates
  const unique_module_scripts = Object.keys(existing_modules)
    // Collect script templates from type names
    .map((module_type) => {
      const config = module_types_map[module_type];
      const string_data = JSON.stringify(existing_modules[module_type]);
      const data = { script_vars: string_data };
      return fillScript(config.scripts, data);
    });

  const processed_global_scripts = fillGlobalScripts(globalScripts);

  // Combine scripts into single list
  const open_action_scripts = [
    processed_global_scripts,
    ...unique_module_scripts
  ]
    // Merge scripts
    .join("\n");

  return {
    documentOptions: {
      openAction: open_action_scripts
    },
    fonts,
    modules,
    unicodeSubstitutions
  };
}

export default loadModularConfig;
