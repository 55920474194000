const scripts = `
try {
  // Sample of expected config
  // {
  //   'module-2': {
  //      // Brand index for which to apply the primary brand's disclaimer
  //      primary_brand_idx: 0,
  //      primary_brand_disclaimer: '*',
  //
  //      // Indices of filters
  //      filter_indices: {
  //        multi: [1],
  //        radio: [3, 4],
  //        checkboxes: [5],
  //        dropdown: [0, 2]
  //      },
  //      // By index, corresponding to filter_indices
  //      filter_counts: [1, 5, 2, 3, 3, 2],
  //
  //      page_size: 3,
  //      brands: ['Epson', 'Avery Dennison'],
  //              // star wheel marks, brand, model + sku, water_resist, alcohol_resist, certs, spec_sheet_url
  //      products: [[true, 2, "JETGLOSS-FSC / RP51 40# White Kraft (LS336K)", 1, 1, [0,1], null]],
  //      max_certs: 2
  //    }
  // }
  var mf_vars = {{script_vars}};

  function mf_hideAcrobatMessage(module_name_pfx) {
    const layerName = buildFieldName([module_name_pfx, 'acrobat-message']);
    const btnName = buildFieldName([module_name_pfx, 'acrobat-message_dismiss']);
    hideLayer(layerName, false);
    hideField(btnName);
  }

  function mf_nextResultsPage(module_name_pfx) {
    const { page_size } = mf_vars[module_name_pfx];
    const { page_start, matched_product_ids } = mf_getTableVars(module_name_pfx);
    const results_count = matched_product_ids.length;
    const next_val = page_start + page_size;
    if (results_count - next_val <= 0)
      return;

    mf_setTableVars(module_name_pfx, { page_start: next_val });
    mf_updateTableRows(module_name_pfx);
    mf_reloadPaginationButtons(module_name_pfx);
  }

  function mf_reloadPaginationButtons(module_name_pfx) {
    const { page_size } = mf_vars[module_name_pfx];
    const { page_start, matched_product_ids } = mf_getTableVars(module_name_pfx);
    const results_count = matched_product_ids.length;

    const can_use_prev = page_start > 0;
    const can_use_next = results_count - (page_start + page_size) > 0;

    toggleLayer(buildFieldName([module_name_pfx, 'results_prev_enabled']), can_use_prev);
    toggleLayer(buildFieldName([module_name_pfx, 'results_next_enabled']), can_use_next);
  }

  function mf_prevResultsPage(module_name_pfx) {
    const { page_size } = mf_vars[module_name_pfx];
    const { page_start } = mf_getTableVars(module_name_pfx);

    const prev_val = page_start - page_size;
    if (prev_val <= 0) {
      mf_setTableVars(module_name_pfx, { page_start: 0 });
    } else {
      mf_setTableVars(module_name_pfx, { page_start: prev_val });
    }

    mf_updateTableRows(module_name_pfx);
    mf_reloadPaginationButtons(module_name_pfx);
  }

  function mf_getTableVarsField(module_name_pfx) {
    const name_parts = [module_name_pfx, 'results', 'json'];
    return getField(buildFieldName(name_parts));
  }

  // Returns the parsed json data from the related field.
  // Data will be in the following form:
  // { page_start: 0, matched_product_ids: [0, 1, 2, ...] }
  function mf_getTableVars(module_name_pfx) {
    const field = mf_getTableVarsField(module_name_pfx);

    try {
      const json = JSON.parse(field.value);
      return json;
    } catch(e) {
      console.println('ERROR - Media Finder ['+arguments.callee.name+']: ' + e);
      return { page_start: 0, matched_product_ids: [] };
    }
  }

  // function mf_getDropdownValue() {}
  // const mf_fieldVal_cb = { dropdown: mf_getDropdownValue, }

  // Returns the value from a single field by delegating to
  // the appropriate callback
  // type - one of [dropdown, checkboxes, multi, radio]
  // function mf_getFilterValue(field, type)

  function getSelectedRadioIdx(field) {
    for (var i = 1; i < 10; ++i) {
      if (field.value === ('Choice' + i))
        return i - 1;
    }

    return -1;
  }

  // Collect filter settings
  // e.g. { model: 1, material: [1,2,3], ... }
  function mf_getSelection(module_name_pfx) {
    const model = getField(buildFieldName([module_name_pfx, 'dropdown-0_value'])).value - 1;
    const material = mf_getSelectedMultiIndices(module_name_pfx, 1);
    const adhesive = getField(buildFieldName([module_name_pfx, 'dropdown-2_value'])).value - 1;
    const water_resist = getSelectedRadioIdx(getField(buildFieldName([module_name_pfx, 'radio-3_value'])));
    const alcohol_resist = getSelectedRadioIdx(getField(buildFieldName([module_name_pfx, 'radio-4_value'])));

    const bs_name = buildFieldName([module_name_pfx, 'cb-5-0_cb']);
    const bs_checked = mf_isCbChecked(getField(bs_name));

    const ul_name = buildFieldName([module_name_pfx, 'cb-5-1_cb']);
    const ul_checked = mf_isCbChecked(getField(ul_name));
    var certs = [];
    if (bs_checked) certs.push(0);
    if (ul_checked) certs.push(1);

    return [model, material, adhesive, water_resist, alcohol_resist, certs];
  }

  // Clear all filters:
  function mf_clearFilters(module_name_pfx) {
    mf_clearRadios(module_name_pfx);
    mf_clearMultis(module_name_pfx);
    mf_clearCheckboxes(module_name_pfx);
    mf_clearDropdowns(module_name_pfx);

    // Reload matches
    mf_filtersChanged(module_name_pfx);
  }

  function mf_getIndices(module_name_pfx, type) {
    const { filter_indices } = mf_vars[module_name_pfx];
    return filter_indices[type];
  }

  function mf_clearMultis(module_name_pfx) {
    const { filter_counts } = mf_vars[module_name_pfx];
    const indices = mf_getIndices(module_name_pfx, 'multi');
    indices.forEach((filter_idx) => {
      const items_count = filter_counts[filter_idx];

      for (var item_idx = 0; item_idx < items_count; ++item_idx) {
        const cb_name = mf_buildMultiName(module_name_pfx, filter_idx, 'cb', item_idx);
        var field = getField(cb_name);
        mf_disableCheckBox(field);
        mf_reloadPseudoCheckbox(field, module_name_pfx, filter_idx, item_idx);
      }

      mf_reloadMultiDisplay(module_name_pfx, filter_idx);
    });
  }
  function mf_clearCheckboxes(module_name_pfx) {
    const { filter_counts } = mf_vars[module_name_pfx];
    const indices = mf_getIndices(module_name_pfx, 'checkboxes');
    indices.forEach((filter_idx) => {
      const items_count = filter_counts[filter_idx];

      for (var item_idx = 0; item_idx < items_count; ++item_idx) {
        const cb_id = 'cb-' + filter_idx + '-' + item_idx;
        const cb_name = buildFieldName([module_name_pfx, cb_id, 'cb']);
        var field = getField(cb_name);
        mf_disableCheckBox(field);

        // Disable check mark
        const cb_mark_name = buildFieldName([module_name_pfx, cb_id, 'mark']);
        toggleLayer(cb_mark_name, false);
      }
    });
  }

  function mf_clearDropdowns(module_name_pfx) {
    const { filter_counts } = mf_vars[module_name_pfx];
    const indices = mf_getIndices(module_name_pfx, 'dropdown');
    indices.forEach((filter_idx) => {
      mf_updateDropdownLabel(module_name_pfx, filter_idx, 0);
    });
  }


  function mf_clearRadios(module_name_pfx) {
    const indices = mf_getIndices(module_name_pfx, 'radio');
    indices.forEach((filter_idx) => {
      const field_name = buildFieldName([module_name_pfx, 'radio-' + filter_idx, 'value']);
      var field = getField(field_name);
      // Deselect values
      field.value = '0';
      mf_reloadPseudoRadios(module_name_pfx, filter_idx);
    });
  }

  // Returns a list of matched product indices within the main products list
  function mf_filterProducts(module_name_pfx, filter_values) {
    const { products } = mf_vars[module_name_pfx];
    
    // selected values
    const [s_model, s_material, s_adhesive, s_water_resist, s_alcohol_resist, s_certs] = filter_values;

    return products.map((product, idx) => {
      const [
        show_warning,
        brand_idx,
        model_sku,
        water_resist_idx,
        alcohol_resist_idx,
        certs,
        spec_sheet_url,

        related_model_indices,
        material_idx,
        adhesive_idx
      ] = product;

      if (s_model > -1 && related_model_indices.indexOf(s_model) == -1) {
        return false;
      }

      if (s_material.length > 0 && s_material.indexOf(material_idx) == -1) {
        return false;
      }

      if (s_adhesive > -1 && adhesive_idx !== s_adhesive) {
        return false;
      }
      
      if (s_water_resist > -1 && water_resist_idx > s_water_resist) {
        return false;
      }

      if (s_alcohol_resist > -1 && alcohol_resist_idx > s_alcohol_resist) {
        return false;
      }

      if (s_certs.length > 0 && s_certs.some((v) => certs.indexOf(v) == -1)) {
        return false;
      }

      return idx;
    }).filter((v) => v !== false);
  }

  function mf_brandIsPrimary(module_name_pfx, brand_idx) {
    const { primary_brand_idx } = mf_vars[module_name_pfx];
    return primary_brand_idx === brand_idx;
  }

  function mf_updateTableRow(module_name_pfx, result, row_idx) {
    const [
      show_warning, brand_idx, model,
      water_resist, alcohol_resist,
      certs,  sheet_url
    ] = result;

    const { brands, max_certs, primary_brand_disclaimer } = mf_vars[module_name_pfx];

    // Warning icon
    toggleLayer(buildFieldName([module_name_pfx, 'table_row', row_idx, 'warning']), show_warning);

    var header_field = getField(buildFieldName([module_name_pfx, 'table_row', row_idx, 'header']));
    if (header_field) {
      const brand = brands[brand_idx];
      header_field.richText = true;

      var disclaimed_brand = brand;
      if (mf_brandIsPrimary(module_name_pfx, brand_idx)) {
        disclaimed_brand = brand + primary_brand_disclaimer;
      }
      
      header_field.richValue = [{
        fontWeight: 900,
        fontFamily: ['Helvetica'],
        text: disclaimed_brand
      },
      // empty space
      {
        fontWeight: 400,
        fontFamily: ['Helvetica'],
        text: ' '
      }, {
        fontWeight: 400,
        fontFamily: ['Helvetica'],
        text: model
      }];
    }

    // Water & alcohol_resist
    mf_toggleResistanceIcons(module_name_pfx, row_idx, 'wr', water_resist);
    mf_toggleResistanceIcons(module_name_pfx, row_idx, 'ar', alcohol_resist);
    
    // Certs
    for (var i = 0; i < max_certs; ++i) {
      const enable_cert = certs.indexOf(i) > -1;
      const layer_name = buildFieldName([module_name_pfx, 'table_row', row_idx, 'cert-' + i]);
      toggleLayer(layer_name, enable_cert);
    }

    // Data sheet
    const data_sheet_name = buildFieldName([module_name_pfx, 'table_row', row_idx, 'data-sheet']);
    toggleLayer(data_sheet_name, !!sheet_url);
    toggleFieldVisibility(data_sheet_name, !!sheet_url);
  }

  function mf_toggleResistanceIcons(module_name_pfx, row_idx, type, id_to_enable) {
    const pfx = [module_name_pfx, 'table_row', row_idx, type];

    for (var i = 0; i < 3; ++i) {
      const name = buildFieldName([...pfx, i]);
      toggleLayer(name, i === id_to_enable);
    }
  }

  // Open "data sheet" URL for clicked product
  function mf_dataSheetClicked(module_name_pfx, row_idx) {
    // Get paginated product at given row index,
    const { page_start, matched_product_ids } = mf_getTableVars(module_name_pfx);
    const { products } = mf_vars[module_name_pfx];
    const pivot_idx = matched_product_ids[page_start + row_idx];

    const product = products[pivot_idx] || [];
    const url = product[6];
    if (!url) {
      console.println("Warning - Media Finder: Product[" + pivot_idx + "] URL invalid.");
      return;
    }

    app.launchURL(url);
  }

  function mf_updateTableRows(module_name_pfx) {
    // true, 2, "JETGLOSS-FSC / RP51 40# White Kraft (LS336K)", 1, 2, null
    const { page_size, products } = mf_vars[module_name_pfx];
    const { page_start, matched_product_ids } = mf_getTableVars(module_name_pfx);

    const matched_results = matched_product_ids.map((i) => products[i]);

    for (var row_idx = 0; row_idx < page_size; ++row_idx) {
      // Whether the current paginated index overflows matched results
      const result_idx = page_start + row_idx;
      const idx_overflows = result_idx >= matched_results.length;

      // Toggle row's buttons (brand+model+sku, data-sheet)
      mf_toggleTableRowElements(module_name_pfx, !idx_overflows, row_idx);

      // Toggle the row's layered content (warning, tags, data-sheet)
      toggleLayer(buildFieldName([module_name_pfx, 'table_row', row_idx]), !idx_overflows);

      if (!idx_overflows) {
        const result = matched_results[result_idx];
        mf_updateTableRow(module_name_pfx, result, row_idx);
      }
    }

    mf_updatePaginationText(module_name_pfx);
  }

  // type: cb, btn, mark
  function mf_buildMultiName(module_name_pfx, filter_idx, type, item_idx) {
    const pfxs = [module_name_pfx, 'multi', filter_idx, 'items'];
    if (!type)
      return buildFieldName(pfxs);
    if (item_idx === null)
      return buildFieldName([...pfxs, type]);
    return buildFieldName([...pfxs, item_idx, type]);
  }

  function mf_isCbChecked(field) {
    return field.value === "On";
  }

  function mf_reloadPseudoCheckbox(field, module_name_pfx, filter_idx, item_idx) {
    const cb_mark_name = mf_buildMultiName(module_name_pfx, filter_idx, 'mark', item_idx);
    toggleLayer(cb_mark_name, mf_isCbChecked(field));
  }

  function mf_forEveryFilterPseudoCheckbox(module_name_pfx, filter_idx, callback) {
    const { filter_counts } = mf_vars[module_name_pfx];

    const items_count = filter_counts[filter_idx];
    for (var item_idx = 0; item_idx < items_count; ++item_idx) {
      const cb_name = mf_buildMultiName(module_name_pfx, filter_idx, 'cb', item_idx);
      const field = getField(cb_name);
      callback(field, module_name_pfx, filter_idx, item_idx);
    }
  }

  const mf_tableFields = ['header', 'data-sheet'];
  function mf_toggleTableRowElements(module_name_pfx, state, row_idx) {
    const pfx = [module_name_pfx, 'table_row', row_idx];

    toggleLayer(buildFieldName(pfx), state);

    // Toggle fields in row
    mf_tableFields.forEach((field_sfx) => {
      const field_name = buildFieldName([...pfx, field_sfx]);
      toggleFieldVisibility(field_name, state);
    });
  }

  function mf_toggleTableElements(module_name_pfx, state) {
    const { page_size } = mf_vars[module_name_pfx];
    // Toggle rows
    for (var row_idx = 0; row_idx < page_size; ++row_idx) {
      mf_toggleTableRowElements(module_name_pfx, state, row_idx);
    }
  }

  function mf_forEveryMultiPseudoCheckbox(module_name_pfx, callback) {
    const { filter_indices } = mf_vars[module_name_pfx];
    const { multi } = filter_indices;
    // Reload multi-select checkboxes by filter id
    multi.forEach((filter_idx) => {
      mf_forEveryFilterPseudoCheckbox(module_name_pfx, filter_idx, callback);
    });
  }

  function mf_reloadAllMultiPseudoCheckboxes(module_name_pfx) {
    mf_forEveryMultiPseudoCheckbox(module_name_pfx, mf_reloadPseudoCheckbox);
  }

  function mf_reloadAllPseudoRadios(module_name_pfx) {
    const { filter_indices } = mf_vars[module_name_pfx];
    const { radio } = filter_indices;
    for (var idx in radio) {
      mf_reloadPseudoRadios(module_name_pfx, radio[idx]);
    }
  }

  function mf_pseudoCbClicked(module_name_pfx, filter_idx, item_idx) {
    const id_str = 'cb-' + filter_idx + '-' + item_idx;
    const cb_name = buildFieldName([module_name_pfx, id_str, 'cb']);
    var field = getField(cb_name);
    if(field) {
      mf_toggleCheckBox(field);
      const cb_mark_name = buildFieldName([module_name_pfx, id_str, 'mark']);
      toggleLayer(cb_mark_name, mf_isCbChecked(field));
    }

    mf_filtersChanged(module_name_pfx);
  }

  function mf_disableCheckBox(field) {
    field.value = 'Off';
  }

  function mf_toggleCheckBox(field) {
    if (mf_isCbChecked(field)) {
      field.value = "Off";
    } else {
      field.value = "On";
    }
  }

  // Returns whether a multi-select is expanded
  function mf_isMultiExpanded(module_name_pfx, filter_idx) {
    const dropdown_layer_name = mf_buildMultiName(module_name_pfx, filter_idx);
    return isOcgVisible(dropdown_layer_name);
  }

  function mf_isDropdownExpanded(module_name_pfx, filter_idx) {
    // module-2_dropdown-2_items
    const layer_name = buildFieldName([module_name_pfx, 'dropdown-' + filter_idx, 'items']);
    return isOcgVisible(layer_name);
  }

  function mf_togglePagination(module_name_pfx, state) {
    const pfx = [module_name_pfx, 'results'];
    ['prev_btn', 'text', 'next_btn'].forEach((k) => {
      // Toggle pagination buttons & text field
      toggleFieldVisibility(buildFieldName([...pfx, k]), state);
    });
  }

  function mf_resetVars(module_name_pfx, matched_product_ids) {
    mf_setTableVars(module_name_pfx, {
      page_start: 0,
      matched_product_ids: matched_product_ids
    });
  }

  function mf_filtersChanged(module_name_pfx) {
    const values = mf_getSelection(module_name_pfx);
    const matched_results = mf_filterProducts(module_name_pfx, values);

    mf_resetVars(module_name_pfx, matched_results);
    mf_updateTableRows(module_name_pfx);
    mf_reloadPaginationButtons(module_name_pfx);
  }

  function mf_updatePaginationText(module_name_pfx) {
    const { page_size } = mf_vars[module_name_pfx];
    const { page_start, matched_product_ids } = mf_getTableVars(module_name_pfx);
    const matched_count = matched_product_ids.length;
    const pfx = [module_name_pfx, 'results', 'text'];
    const field = getField(buildFieldName(pfx));
    field.richText = true;
    if (matched_count === 0) {
      field.richValue = [{
        fontFamily: ['Helvetica'],
        textSize: field.textSize,
        text: 'Showing Results 0 of 0'
      }];
    } else {
      const page_end_max = page_start + page_size;

      // Show the maximum range.
      // e.g. for when only 3 are matched but we have more available rows
      const page_end = page_end_max > matched_count ? matched_count : page_end_max;
      const range = (page_start + 1) + '-' + page_end;
      const range_str = String(range);
      const matched_str = String(matched_count);
      field.richValue = [{
        fontFamily: ['Helvetica'],
        textSize: field.textSize,
        text: 'Showing Results ' + range_str + ' of ' + matched_str
      }];
    }
  }

  function mf_toggleRadioFilters(module_name_pfx, state, skip_index) {
    const { filter_indices, filter_counts } = mf_vars[module_name_pfx];
    const { radio } = filter_indices;

    radio.forEach((radio_index_key) => {
      const items_count = filter_counts[radio_index_key];

      if (radio_index_key == skip_index)
        return;

      for (var button_idx = 0; button_idx < items_count; ++button_idx) {
        const field_name = buildFieldName([module_name_pfx, 'radio-' + radio_index_key, button_idx, 'button']);
        toggleFieldVisibility(field_name, state);
      }
    });
  }

  function mf_multiToggled(module_name_pfx, filter_idx) {
    const toggle_name = mf_buildMultiName(module_name_pfx, filter_idx, 'toggle_btn');
    const dropdown_layer_name = mf_buildMultiName(module_name_pfx, filter_idx);

    const is_expanded = mf_isMultiExpanded(module_name_pfx, filter_idx);

    // Toggle up/down chevrons
    reloadFieldChevrons(module_name_pfx, 'multi_' + filter_idx, !is_expanded);

    // Toggle dropdowns layer
    toggleLayer(dropdown_layer_name, !is_expanded);
    
    // Toggle multi-select buttons
    mf_forEveryFilterPseudoCheckbox(module_name_pfx, filter_idx, (field, module_name_pfx, filter_idx, item_idx) => {
      const cb_btn_name = mf_buildMultiName(module_name_pfx, filter_idx, 'btn', item_idx);
      toggleFieldVisibility(cb_btn_name, !is_expanded);
    });

    // Toggle "Collapse" button
    const collapser_btn = buildFieldName([module_name_pfx, 'multi', filter_idx, 'items_collapser_btn']);
    toggleFieldVisibility(collapser_btn, !is_expanded);

    // Toggle *other* clickable elements
    mf_toggleTableControllers(module_name_pfx, is_expanded, filter_idx);

    // Toggle table elements
    mf_toggleTableElements(module_name_pfx, is_expanded);

    // Trigger mf_filtersChanged only after hiding the multi-select
    if (is_expanded)
      mf_filtersChanged(module_name_pfx);
  }

  function mf_toggleMultiSelectFilters(module_name_pfx, is_expanded, skip_index) {
    const { filter_indices } = mf_vars[module_name_pfx];
    const { multi } = filter_indices;
    multi.forEach((filter_idx) => {
      if (filter_idx === skip_index)
        return;

      // module-2_multi_1_items_toggle_btn
      const id = 'multi_' + filter_idx + '_items_toggle_btn';
      const toggle_name = buildFieldName([module_name_pfx, id]);
      toggleFieldVisibility(toggle_name, is_expanded);
    });
  }

  function mf_toggleTableControllers(module_name_pfx, is_expanded, skip_index) {
    mf_toggleRadioFilters(module_name_pfx, is_expanded, skip_index);
    mf_toggleDropdownFilters(module_name_pfx, is_expanded, skip_index);
    mf_toggleMultiSelectFilters(module_name_pfx, is_expanded, skip_index);
    mf_toggleCheckboxFilters(module_name_pfx, is_expanded);

    mf_togglePagination(module_name_pfx, is_expanded);
    mf_toggleClearFilters(module_name_pfx, is_expanded);
  }

  function mf_toggleClearFilters(module_name_pfx, is_expanded) {
    // module-2_clear-filters
    toggleFieldVisibility(buildFieldName([module_name_pfx, 'clear-filters']), is_expanded);
  }

  function mf_toggleDropdownFilters(module_name_pfx, is_expanded, skip_index) {
    const { filter_indices } = mf_vars[module_name_pfx];
    const { dropdown } = filter_indices;

    // module-2_dropdown-2_toggle_btn
    dropdown.forEach((filter_idx) => {
      if (filter_idx === skip_index) return;

      const id = 'dropdown-' + filter_idx;
      const field_name = buildFieldName([module_name_pfx, id, 'toggle_btn']);
      toggleFieldVisibility(field_name, is_expanded);
    });
  }

  function mf_reloadAllCheckboxFilters(module_name_pfx) {
    const { filter_indices, filter_counts } = mf_vars[module_name_pfx];
    const { checkboxes } = filter_indices;

    checkboxes.forEach((filter_idx) => {
      const items_count = filter_counts[filter_idx];
      for (var item_idx = 0; item_idx < items_count; ++item_idx) {
        const id_str = 'cb-' + filter_idx + '-' + item_idx;
        const cb_mark_name = buildFieldName([module_name_pfx, id_str, 'mark']);
        
        // module-2_cb-5-0_cb
        const cb_name = buildFieldName([module_name_pfx, id_str, 'cb']);
        const field = getField(cb_name);
        toggleLayer(cb_mark_name, mf_isCbChecked(field));
      }
    });
  }

  function mf_toggleCheckboxFilters(module_name_pfx, is_expanded) {
    const { filter_indices, filter_counts } = mf_vars[module_name_pfx];
    const { checkboxes } = filter_indices;
    
    // module-2_cb-5-0_btn
    checkboxes.forEach((filter_idx) => {
      const items_count = filter_counts[filter_idx];
      for (var item_idx = 0; item_idx < items_count; ++item_idx) {
        const id = 'cb-' + filter_idx + '-' + item_idx;
        const field_name = buildFieldName([module_name_pfx, id, 'btn']);
        toggleFieldVisibility(field_name, is_expanded);
      }
    });
  }

  function mf_reloadPseudoRadio(field, module_name_pfx, filter_idx, item_idx) {
    const radio_id = 'radio-' + filter_idx;
    const radio_layer_name = buildFieldName([module_name_pfx, radio_id, item_idx, 'outline']);

    const enable_layer = field.value === ('Choice' + (item_idx + 1));
    toggleLayer(radio_layer_name, enable_layer);
  }

  function mf_reloadPseudoRadios(module_name_pfx, filter_idx) {
    const { filter_counts } = mf_vars[module_name_pfx];
    const items_count = filter_counts[filter_idx];

    const radio_id = 'radio-' + filter_idx;
    const field_name = buildFieldName([module_name_pfx, radio_id, 'value']);
    var field = getField(field_name);
    for (var i = 0; i < items_count; ++i) {
      mf_reloadPseudoRadio(field, module_name_pfx, filter_idx, i);
    }
  }

  function mf_radioClicked(module_name_pfx, filter_idx, item_idx) {
    const cb_name = buildFieldName([module_name_pfx, 'radio-' + filter_idx, 'value']);
    var field = getField(cb_name);

    if (field) {
      field.value = 'Choice' + (item_idx + 1);
      mf_reloadPseudoRadios(module_name_pfx, filter_idx);
    }

    mf_filtersChanged(module_name_pfx);
  }

  function mf_getFilterCount(module_name_pfx, filter_idx) {
    const { filter_counts } = mf_vars[module_name_pfx];
    return filter_counts[filter_idx];
  }

  // For a given multi-select, disables all "label" layers for the main
  // display (the clickable "dropdown") except the one for the given index
  function mf_setMultiLabelIndex(module_name_pfx, filter_idx, enable_idx) {
    // module-2_multi_1_labels_0
    const layer_name_pfx = [module_name_pfx, 'multi', filter_idx, 'labels'];
    const items_count = mf_getFilterCount(module_name_pfx, filter_idx);
    // Offset of +2 is due to two additional states:
    // "-" and "Multiple selected"
    for (var i = 0; i < items_count + 2; ++i) {
      const layer_name = buildFieldName([...layer_name_pfx, i]);
      const enable = i === enable_idx;
      toggleLayer(layer_name, enable);
    }
  }

  // Returns a list of indices of "selected" multi-select items
  function mf_getSelectedMultiIndices(module_name_pfx, filter_idx) {
    var indices = [];
    const items_count = mf_getFilterCount(module_name_pfx, filter_idx);
    for (var i = 0; i < items_count; ++i) {
      // module-2_multi_1_items_0_cb
      const cb_name = buildFieldName([module_name_pfx, 'multi', filter_idx, 'items', i, 'cb']);
      const cb = getField(cb_name);
      if (mf_isCbChecked(cb)) {
        indices.push(i);
      }
    }
    return indices;
  }

  // Update label for multi-select controller (the primary, clickable "form element")
  function mf_reloadMultiDisplay(module_name_pfx, filter_idx) {
    const selected_indices = mf_getSelectedMultiIndices(module_name_pfx, filter_idx);
    var enable_idx = 0;
    if (selected_indices.length === 1) {
      // Offset by default (0) and "Multiple selected" (1)
      enable_idx = selected_indices[0] + 2;
    } else if (selected_indices.length > 1) {
      // Show the "Multiple selected" message
      enable_idx = 1;
    } else {
      // enable_idx already 0
    }
    mf_setMultiLabelIndex(module_name_pfx, filter_idx, enable_idx);
  }

  // Click handler for a single item within an expanded multi-select form field
  function mf_multiItemClicked(module_name_pfx, filter_idx, item_idx) {
    const cb_name = mf_buildMultiName(module_name_pfx, filter_idx, 'cb', item_idx);
    var field = getField(cb_name);
    if(field) {
      mf_toggleCheckBox(field);
      mf_reloadPseudoCheckbox(field, module_name_pfx, filter_idx, item_idx);
      mf_reloadMultiDisplay(module_name_pfx, filter_idx);
    }
  }

  function mf_toggleDropdownItems(module_name_pfx, filter_idx, is_expanded) {
    const id = 'dropdown-' + filter_idx + '_items';
    const { filter_counts} = mf_vars[module_name_pfx];
    const items_count = filter_counts[filter_idx];

    // Offset by 1 for the empty/deselect state
    for (var item_idx = 0; item_idx < items_count + 1; ++item_idx) {
      // module-2_dropdown-2_items_btn-0
      const field_name = buildFieldName([module_name_pfx, id, 'btn-' + item_idx]);
      toggleFieldVisibility(field_name, is_expanded);
    }
  }

  function mf_dropdownToggled(module_name_pfx, filter_idx) {
    const is_expanded = mf_isDropdownExpanded(module_name_pfx, filter_idx);
    const dropdown_id = 'dropdown-' + filter_idx;
    // module-2_dropdown-2_0
    reloadFieldChevrons(module_name_pfx, dropdown_id, !is_expanded);

    // Toggle "items" list
    // module-2_dropdown-2_items
    toggleLayer(buildFieldName([module_name_pfx, dropdown_id, 'items']), !is_expanded);
    mf_toggleDropdownItems(module_name_pfx, filter_idx, !is_expanded);

    // Hide table while dropdown is open
    mf_toggleTableElements(module_name_pfx, is_expanded);

    // Toggle *other* clickable elements
    mf_toggleTableControllers(module_name_pfx, is_expanded, filter_idx);

    if (is_expanded) {
      mf_filtersChanged(module_name_pfx);
    }
  }

  function mf_getDropdownField(module_name_pfx, filter_idx) {
    // module-2_dropdown-2_value
    const id = 'dropdown-' + filter_idx;
    const field_name = buildFieldName([module_name_pfx, id, 'value']);
    return getField(field_name);
  }

  // Set new value and show related layer
  function mf_updateDropdownLabel(module_name_pfx, filter_idx, new_value) {
    var field = mf_getDropdownField(module_name_pfx, filter_idx);
    field.value = String(new_value);
    mf_reloadDropdown(module_name_pfx, filter_idx);
  }

  function mf_reloadDropdown(module_name_pfx, filter_idx) {
    const { filter_counts } = mf_vars[module_name_pfx];
    const items_count = filter_counts[filter_idx];

    // module-2_dropdown-2_value
    const id = 'dropdown-' + filter_idx;
    const field_name = buildFieldName([module_name_pfx, id, 'value']);;
    const field = getField(field_name);
    const value = field.value;

    // Offset + 1 due to the "-" value
    for (var i = 0; i < items_count + 1; ++i) {
      // module-2_dropdown-2_labels_0
      var layer_name = buildFieldName([module_name_pfx, id, 'labels', i]);
      toggleLayer(layer_name, value == i);
    }
  }

  function mf_reloadAllMultiSelects(module_name_pfx) {
    const { filter_indices } = mf_vars[module_name_pfx];
    const { multi } = filter_indices;
    for (var idx in multi) {
      mf_reloadMultiDisplay(module_name_pfx, multi[idx]);
    }
  }

  function mf_reloadAllDropdowns(module_name_pfx) {
    const { filter_indices } = mf_vars[module_name_pfx];
    const { dropdown } = filter_indices;
    for (var idx in dropdown) {
      mf_reloadDropdown(module_name_pfx, dropdown[idx]);
    }
  }

  function mf_dropdownItemClicked(module_name_pfx, filter_idx, item_idx) {
    mf_updateDropdownLabel(module_name_pfx, filter_idx, item_idx);

    // Close dropdown
    mf_dropdownToggled(module_name_pfx, filter_idx);
  }

  // Saves JSON data for the media finder table.
  //
  // As with mf_getTableVars(), stored data will have the following form:
  // { page_start: 0, matched_product_ids: [0, 1, 2, ...] }
  function mf_setTableVars(module_name_pfx, vars = {}) {
    const {
      page_start : old_page_start,
      matched_product_ids : old_matched_product_ids
    } = mf_getTableVars(module_name_pfx);

    const {
      page_start: new_page_start,
      matched_product_ids: new_matched_product_ids
    } = vars;

    const new_value = {
      page_start: new_page_start !== undefined ? new_page_start : old_page_start,
      matched_product_ids: new_matched_product_ids !== undefined ? new_matched_product_ids : old_matched_product_ids
    };

    var field = mf_getTableVarsField(module_name_pfx);
    field.value = JSON.stringify(new_value);
  }

  function mf_init() {
    for (module_name_pfx in mf_vars) {
      mf_reloadAllMultiPseudoCheckboxes(module_name_pfx);
      mf_reloadAllCheckboxFilters(module_name_pfx);
      mf_reloadAllPseudoRadios(module_name_pfx);
      mf_reloadAllDropdowns(module_name_pfx);
      mf_reloadAllMultiSelects(module_name_pfx);
      mf_updateTableRows(module_name_pfx);
      mf_reloadPaginationButtons(module_name_pfx);
    }
  }

  mf_init();
} catch(e) {
  console.println('ERROR - Media finder: ' + e);
}
`;

export default scripts;
