import { digitSuperscriptsToUnicode } from "../../Functions";

// Pixels to points
const px_to_pt = 0.75;
function pxToPt(px) {
  return px * px_to_pt;
}

const parseNumber = (number) => parseInt(number) || 0;
const processNumber = (number) => pxToPt(parseNumber(number));

/**
 * Returns an element's position within a page
 * Both parameters pertain to results from element.getBoundingClientRect()
 */
function getRelativeElementPosition(element_rect, page) {
  return {
    x: pxToPt(element_rect.x - page.x),
    y: pxToPt(page.height - (element_rect.y - page.y) - element_rect.height),
    width: pxToPt(element_rect.width),
    height: pxToPt(element_rect.height)
  };
}

function getElementPosition(element, relative_to = {}) {
  const { x: x_relative = 0, y: y_relative = 0 } = relative_to;
  return {
    x: element.offsetLeft + x_relative,
    y: element.offsetTop + y_relative,
    width: element.offsetWidth,
    height: element.offsetHeight
  };
}

/**
 * A hex color is transparent if the last two values (alpha) are zero.
 */
function isHexColorTransparent(hex_color_string = "") {
  return !!hex_color_string.match(/\#......00/);
}

function stringDigitsToHex(string_digits) {
  return (
    "#" +
    // Convert each string to int, then to string base 16 (hex)
    string_digits
      .map((v) => {
        // Convert int to hex
        const parsed = parseInt(v).toString(16);
        // Prefix with 0 as necessary to always have two-digit values
        // (e.g. 00, 0f, 0a, 01)
        if (parsed.length === 1) return `0${parsed}`;
        return parsed;
      })
      // Merge values
      .join("")
  );
}

// Create a hex string such as #ffffff
function getRgbColorAsHex(rgb_string = "") {
  // Do not process hex strings
  if (rgb_string[0] === "#") return rgb_string;
  // find numbers as string (e.g. ['255', '255', '255'])
  const string_digits = rgb_string.match(/\d+/g);
  if (!string_digits) return rgb_string;
  return stringDigitsToHex(string_digits);
}

/* Converts a string such as "1px solid rgb(255, 255, 255)"
 * into:
 *   {
 *     borderColor: "#ffffff",
 *     borderThickness: 1
 *   }
 */
function getBorderProps(border_string, options = {}) {
  const matches = border_string.match(/(\d+(?:\.\d+)?)/g);
  const [border_thickness, ...rgb] = matches;
  const { color, thickness } = options;

  return {
    borderColor: color || stringDigitsToHex(rgb),
    borderThickness: thickness || parseInt(border_thickness) || 0
  };
}

const getRoundedRadius = ({ borderRadius }) => parseNumber(borderRadius) * 0.6;

const getBgProps = (
  computed_bg_style,
  include_border = false,
  options = {}
) => {
  const { backgroundColor = "", borderRadius = 0 } = options;
  return {
    backgroundColor: getRgbColorAsHex(
      backgroundColor || computed_bg_style.backgroundColor
    ),
    borderRadius: borderRadius || getRoundedRadius(computed_bg_style),

    ...(include_border ? getBorderProps(computed_bg_style.border) : {})
  };
};

/**
 * Creates an image "object"
 *
 * Supports a "container_override" so that images can be placed elsewhere
 */
const createImage = (
  configs,
  image,
  label = "image",
  overrides = { container: null, img_src: null }
) => {
  const image_rect = (overrides.container || image).getBoundingClientRect();
  const { page } = configs;
  return {
    name: label,
    page: configs.page.idx,
    image: overrides.img_src || image.src,
    position: getRelativeElementPosition(image_rect, configs.page)
  };
};

const createImageElementsFromContainer = (
  element,
  configs,
  name_prefix = "",
  label = ""
) => {
  const image_elements = [...element.querySelectorAll(".dynamic-image")];
  return image_elements.map((image_element, image_idx) =>
    createImage(configs, image_element, `${name_prefix}-${label || image_idx}`)
  );
};

const isTextBold = ({ fontWeight }) => parseNumber(fontWeight) >= 700;

const processVerticalAlign = (alignment_string) => {
  switch (alignment_string) {
    case "top":
    case "bottom":
    case "middle":
      return alignment_string;

    default:
      return "middle";
  }
};

const _createTextStructure = (text, overrides = {}, options = {}) => {
  const text_styles = window.getComputedStyle(text);
  const { collect_bg = false, collect_border = false } = options;
  const bg_props = collect_bg ? getBgProps(text_styles, collect_border) : {};
  let conditional_settings = {};

  if (text_styles.textDecorationLine === "underline") {
    conditional_settings.underline = true;
  }

  let content = overrides.content;
  if (!text.options) content = content || text.innerHTML;

  //adjust vertical alignment for superscripts
  const isTab = !!text.classList.contains("tab");
  const hasSuperscript =
    !!content.match(/<sup>([^<]*)<\/sup>/g) ||
    !!text.innerHTML.match(/<sup>([^<]*)<\/sup>/g);
  //if paragraph, alignment should be top
  if (hasSuperscript) overrides.verticalAlign = "top";
  //if tab, alignment should be middle regardless of superscript
  if (isTab) overrides.verticalAlign = "middle";
  const vertical_align = processVerticalAlign(
    overrides.verticalAlign || text_styles.verticalAlign
  );

  // Conver <br/> to "\n"
  // Remove HTML tags
  content = content.replace(/<br\/?>/g, "\n");
  // Replace &amp; with &
  content = content.replace(/\&amp;/g, "&");
  // if (options.digitSuperscriptsToUnicode) {
  content = digitSuperscriptsToUnicode(content);
  // }

  return {
    content: content,
    family: processFontName(text_styles.fontFamily),
    // family: "hbold",
    color: getRgbColorAsHex(overrides.color || text_styles.color),
    // bold: isTextBold(text_styles),
    size: processNumber(text_styles.fontSize),
    lineHeight: processNumber(text_styles.lineHeight),

    horizontalAlignment:
      overrides.horizontalAlignment || text_styles.textAlign || "left",

    verticalAlignment: vertical_align,
    // || "middle",

    ...conditional_settings
  };
};

// const offset_keys = ['x','y','width','height'];
function getElementOffsets(element) {
  return {
    x: parseFloat(element.getAttribute("data-offset-x")) || 0,
    y: parseFloat(element.getAttribute("data-offset-y")) || 0,
    width: parseFloat(element.getAttribute("data-offset-width")) || 0,
    height: parseFloat(element.getAttribute("data-offset-height")) || 0
  };
}

const text_height_offset = 5;
const text_width_offset = 5;
const max_width = 550;
/**
 * Creates the representation of a text element.
 * Supports overriding text color.
 */
function createText(
  text,
  configs,
  label = "header",
  overrides = {},
  options = {}
) {
  const text_rect = text.getBoundingClientRect();
  const { page } = configs;

  const position = getRelativeElementPosition(text_rect, configs.page);
  const { convertToTextField: cttf, form = {}, ...other_options } = options;

  const convertToTextField =
    text.classList.contains("convert-to-text-field") || cttf;

  const enhanced_options = {
    ...options,
    // Append an option for converting to text field, as appropriate
    ...(text.classList.contains("convert-to-text-field")
      ? {
          convertToTextField: true
        }
      : {})
  };

  const text_struct = _createTextStructure(text, overrides, enhanced_options);
  const offsets = getElementOffsets(text);
  const element_position = {
    x: position.x + offsets.x,
    y: position.y - text_height_offset / 2 + offsets.y,
    width: position.width + text_width_offset + offsets.width,
    height: position.height + text_height_offset + offsets.height
  };
  if (offsets.width === "max") element_position.width = max_width;

  let form_props = {};

  if (convertToTextField) {
    form_props = {
      form: {
        type: "text",
        editable: false,
        ...form
      }
    };
  }

  return {
    name: label,
    page: configs.page.idx,
    text: text_struct,
    ...form_props,
    position: element_position,
    ...other_options
  };
}

/**
 * Locates all .dynamic-text within a container and creates text objects
 * for the PDF.
 *
 * "Cleaner" than its predecesor.
 */
function createTextElementsFromContainer_v2(element, configs) {
  const text_elements = [...element.querySelectorAll(".dynamic-text")];
  return text_elements.map((text_element, text_idx) =>
    createText(text_element, configs, String(text_idx))
  );
}

/**
 * Locates all .dynamic-text within a container and creates text objects
 * for the PDF
 */
function createTextElementsFromContainer(element, name_prefix, configs) {
  // Text elements
  const text_elements = [...element.querySelectorAll(".dynamic-text")];
  const full_name_prefix = name_prefix ? `${name_prefix}-` : "";
  return text_elements.map((text_element, text_idx) =>
    createText(text_element, configs, `${full_name_prefix}${text_idx}`)
  );
}

function createButton_v2(element, configs, options = {}) {
  const { name = "", action, form = {}, ...other_options } = options;
  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);
  const { page } = configs;
  return {
    name,
    form: {
      type: "button",
      action: options.action,
      ...form
    },
    position: getRelativeElementPosition(element_rect, configs.page),
    ...other_options
  };
}

function createButton(element, configs, label, overrides = {}, options = {}) {
  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);
  const { page } = configs;

  return {
    name: label,
    action: options.action,
    position: getRelativeElementPosition(element_rect, configs.page)
  };
}

function createCta_v2(cta, configs, options = {}) {
  const { name = "cta" } = options;

  return {
    name,
    elements: [
      // CTA BG
      createBG_v2(cta, configs, { name: "bg", include_border: true }),
      // CTA text
      ...createTextElementsFromContainer(cta, "text", configs)
    ]
  };
}

function createCta(cta, configs, label = "", overrides = {}, options = {}) {
  const cta_rect = cta.getBoundingClientRect();
  const cta_styles = window.getComputedStyle(cta);
  const { page } = configs;

  let text_data = {};
  if (!options.ignoreText) {
    text_data = { text: _createTextStructure(cta, overrides, options) };
  }

  return {
    name: label,
    ...text_data,
    backgroundColor: getRgbColorAsHex(cta_styles.backgroundColor),
    form: {
      type: "button",
      action: options.action
    },
    position: getRelativeElementPosition(cta_rect, configs.page)
  };
}

function createCtaElementsFromContainer(element, name_prefix, configs) {
  const ctas = [...element.querySelectorAll(".dynamic-cta")];
  return ctas.map((cta, text_idx) =>
    createText(cta, configs, `${name_prefix}cta-${text_idx}`)
  );
}

/**
 * Creates an object representing a background.
 *
 * Supported options:
 *  * name - element name
 *  * include_border - whether to collect border properties
 *  * backgroundColor - background color override
 *  * borderRadius - border radius override
 */
function createBG_v2(element, configs, options) {
  const { name = "", include_border = false, ...other_options } = options;
  const { backgroundColor, borderRadius, ...global_options } = other_options;
  const bg_options = { backgroundColor, borderRadius };
  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);
  const { page } = configs;

  return {
    name,
    position: getRelativeElementPosition(element_rect, configs.page),
    ...getBgProps(element_styles, options.include_border, bg_options),
    ...global_options
  };
}

// Offset calculators for border parts
// Calculations depend on the following:
// - rect: {y, x, width, height}
// - (border) thickness
//
// As a note, all positions are offset by either positive or negative
// half of thickness as appropriate.
// This is to align these pseudo borders to real ones.
// top: y+=t; bottom: y-=t; left: x-=t; right: x+=t
const border_offsets = {
  borderTop: (thickness, rect) => ({
    ...rect,
    y: rect.y + rect.height - thickness * 0.5,
    height: thickness
  }),
  borderBottom: (thickness, rect) => ({
    ...rect,
    y: rect.y - thickness * 0.5,
    height: thickness
  }),
  borderLeft: (thickness, rect) => ({
    ...rect,
    x: rect.x - thickness * 0.5,
    width: thickness
  }),
  borderRight: (thickness, rect) => ({
    ...rect,
    x: rect.x + rect.width - thickness * 0.5,
    width: thickness
  })
};

/**
 * Creates borders (as background colors) one edge at a time.
 * Useful for elements without a uniform border, such as custom dropdowns.
 *
 * Supported options:
 * - name: container element name (defaults to "border")
 * - color: override border color
 * - thickness: override border thickness
 * - up/down/left/right: borders to draw
 * - Any other options for the container
 */
function createBorder_v2(element, configs, options) {
  const {
    name = "border",
    color = "",
    thickness = 0,

    // Borders to draw
    up = false,
    down = false,
    left = false,
    right = false,

    all_borders = false,

    // Element-wide props
    ...other_options
  } = options;

  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);

  // Convert parameters to list of keys to check
  const borderKeys = [
    (all_borders || up) && "borderTop",
    (all_borders || down) && "borderBottom",
    (all_borders || left) && "borderLeft",
    (all_borders || right) && "borderRight"
  ]
    // Remove false values
    .filter((v) => !!v);

  const base_position = getRelativeElementPosition(element_rect, configs.page);

  return {
    name,
    elements: borderKeys.map((key, key_idx) => {
      const { borderThickness, borderColor } = getBorderProps(
        element_styles[key],
        { color, thickness }
      );
      return {
        name: String(key_idx),
        position: border_offsets[key](borderThickness, base_position),
        backgroundColor: borderColor
      };
    }),
    ...other_options
  };
}

function createBorder(element, configs, options) {
  const { name = "", color, thickness, ...other_options } = options;
  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);
  return {
    name,
    position: getRelativeElementPosition(element_rect, configs.page),
    ...getBorderProps(element_styles.border, { color, thickness }),
    borderRadius: getRoundedRadius(element_styles),
    ...other_options
  };
}

function createBG(element, configs, label) {
  const element_rect = element.getBoundingClientRect();
  const element_styles = window.getComputedStyle(element);
  const { page } = configs;
  const backgroundColor = getRgbColorAsHex(element_styles.backgroundColor);

  if (isHexColorTransparent(backgroundColor)) return null;

  return {
    name: label,
    backgroundColor,
    position: getRelativeElementPosition(element_rect, configs.page)
  };
}

function createHorizontalBorder(
  element,
  configs,
  borderKey = "borderBottom",
  label = "hz-border",
  options = {}
) {
  // Horizontal borders are only borderBottom and borderTop
  if (["borderBottom", "borderTop"].indexOf(borderKey) == -1) {
    throw "Unsupported border key for createHorizontalBorder(): " + borderKey;
  }

  // Use the containing element as a base for the border
  const element_rect = element.getBoundingClientRect();
  const bg_pos = getRelativeElementPosition(element_rect, configs.page);

  const element_styles = window.getComputedStyle(element);
  const border_props = getBorderProps(element_styles[borderKey]);
  const { borderColor, borderThickness } = border_props;

  const getYPos = () => {
    if (borderKey === "borderTop")
      return bg_pos.y + bg_pos.height - borderThickness;
    return bg_pos.y + borderThickness;
  };
  const y_pos = getYPos();

  // Add border to row if it has one
  const row_divider = {
    name: label,
    backgroundColor: borderColor,
    position: {
      x: bg_pos.x,
      width: bg_pos.width,
      y: y_pos,
      height: borderThickness
    },
    ...options
  };

  if (borderThickness > 0 && !isHexColorTransparent(borderColor)) {
    return row_divider;
  }
  // elements.push(row_divider);
  return null;
}

var processed_font_names_map = {};
/**
 * Simply removes quotes
 */
function processFontName(name_str) {
  let processed_name = name_str
    // Remove quotes
    .replace(/"/g, "");
  // remove non-alphanumeric characters
  // .replace(/[^a-z0-9]/gi, "")
  processed_font_names_map[processed_name] = true;
  return processed_name;
  // Old approach:
  // Format a name such as 'Helvetica Neue LT W05_77 Bd Cn' into 'HNLWBC'
  // split by space and join two characters at a ti
  // .split(" ")
  // .map((word) => word[0])
  // .join("");
}

function getProcessedFontNames() {
  return Object.keys(processed_font_names_map);
}

/**
 * "Inactive" tabs are meant to activate their respective slides, and they should not
 * show text because that is to be handled by EvoPDF.
 * There can only be one active slide at a time, so we make the first tab active.
 */
function createCarouselTab(
  tab,
  configs,
  tab_idx = 0,
  active = false,
  options = {}
) {
  const tab_rect = tab.getBoundingClientRect();
  const name_pfx = active ? "" : "in";
  let text_content = {};

  const { page, module_base_name } = configs;
  const text_styles = window.getComputedStyle(tab);
  if (active) {
    // const tab_parent_rect = tab.parentElement.getBoundingClientRect();
    const overrides = {
      color: tab.getAttribute("data-active-color"),
      content: tab.textContent.toUpperCase()
    };
    text_content = createText(tab, configs, `tab-${name_pfx}active`, overrides);
    Object.assign(text_content, {
      backgroundColor: tab.getAttribute("data-active-background"),
      borderRadius: getRoundedRadius(text_styles),
      ...getBorderProps(text_styles.border)
    });
  } else {
    const overrides = {
      color: "#6B757A",
      content: tab.textContent.toUpperCase()
    };
    text_content = createText(
      tab,
      configs,
      `${tab_idx}_tab-${name_pfx}active`,
      overrides
    );
    // Remove alpha channel
    const bg_color = "#ffffff";
    Object.assign(text_content, {
      backgroundColor: bg_color,
      borderRadius: getRoundedRadius(text_styles),
      ...getBorderProps(text_styles.border)
    });
  }
  return {
    name: `tab-${name_pfx}active`,
    form: {
      type: "button",
      action: options.action
    },
    ...text_content,
    position: getRelativeElementPosition(tab_rect, configs.page)
  };
}

/**
 * Creates inactive tab objects given tab_elements, module configuration and
 * a set of options. We create inactive tabs so the rendering is identical between
 * different states of dynamic elements. Otherwise, appearances are slightly different
 *
 *
 * tab_elements - DOM nodes
 * configs - { page, module_base_name, ... }
 * Options - object of functions, so that the "actions" can be populated
 *           based on loop parameters.
 *           e.g. { action : { mouseClick: (name, idx) => `tabbedContainer_goToSlide("${name}", ${idx})`} }
 */
function createInactiveTabs(tab_elements, configs, options = {}) {
  const { module_base_name } = configs;
  let elements = [];

  tab_elements.forEach((tab_element, slide_idx) => {
    let tab_options = "";
    if (options?.action?.mouseClick) {
      tab_options = {
        action: {
          mouseClick: options.action.mouseClick(module_base_name, slide_idx)
        }
      };
    }

    const tab_inactive = createCarouselTab(
      tab_element,
      configs,
      slide_idx,
      false,
      tab_options
    );
    tab_inactive.alwaysVisible = slide_idx > 0;
    elements.push(tab_inactive);
  });

  return elements;
}

function createCarouselArrow(arrow, configs, label = "left") {
  const arrow_rect = arrow.getBoundingClientRect();
  const { page, module_base_name } = configs;
  const fnNamePart = label === "left" ? "Prev" : "Next";
  const clickFn = `carousel_goTo${fnNamePart}Slide("${module_base_name}")`;

  return {
    name: `carousel-arrow-${label}`,
    alwaysVisible: true,
    form: {
      type: "button",
      action: {
        mouseClick: clickFn
      }
    },
    position: getRelativeElementPosition(arrow_rect, configs.page)
  };
}

function createCarouselArrows(arrows, configs, show_arrows) {
  if (!show_arrows || arrows.length == 0) return [];
  const left = arrows[0];
  const right = arrows[1];
  return [
    createCarouselArrow(left, configs, "left"),
    createCarouselArrow(right, configs, "right")
  ];
}

/**
 * Fills a script with given data using a predefined delimiter of two
 * curly braces around variables (e.g. {{some_var}}).
 *
 * For example, the output would be "var some_data = [1,2,3]"
 * given the following config:
 *  script: "var some_data = {{some_data_key}}"
 *  data: {some_data_key: [1,2,3]}
 */
const fillScript = (script = "", data = {}) => {
  for (const [key, value] of Object.entries(data)) {
    const regexp = new RegExp(`\{\{ *${key} *\}\}`, "g");
    script = script.replace(regexp, value);
  }
  return script;
};

/**
 * Offsets element positions given a list of elements and an offsets object.
 *
 * offsets is expected to have the following form: { x, y, width, height }
 * so that each property can be used to offset the related value within each element.
 */
const offsetElementPositions = (elements = [], offsets = {}) => {
  const { x = 0, y = 0, width = 0, height = 0 } = offsets || {};
  const total_elements = elements.length;
  for (var i = 0; i < total_elements; ++i) {
    // Nested elements list
    if (elements[i].elements) {
      offsetElementPositions(elements[i].elements, offsets);
    } else {
      let { position } = elements[i];
      position.x += x;
      position.y += y;
      position.width += width;
      position.height += height;
      elements[i].position = position;
    }
  }
  return elements;
};

export {
  getProcessedFontNames,
  fillScript,
  processFontName,
  isHexColorTransparent,
  getElementPosition,
  getRelativeElementPosition,
  getRgbColorAsHex,
  getBorderProps,
  getBgProps,
  getRoundedRadius,
  pxToPt,
  processNumber,
  createImage,
  createImageElementsFromContainer,
  createText,
  _createTextStructure,
  createTextElementsFromContainer,
  createTextElementsFromContainer_v2,
  createCta,
  createCta_v2,
  createCtaElementsFromContainer,
  createButton,
  createButton_v2,
  createBG,
  createBG_v2,
  createBorder,
  createBorder_v2,
  createHorizontalBorder,
  createCarouselTab,
  createInactiveTabs,
  createCarouselArrows,
  offsetElementPositions
};
