import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ProjectValidator from './ProjectValidator';

function ProjectRouter() {
  return (
    <Router>
      <Switch>
        <Route path={`/:project/:skipAC?/:possibleBundleId?`}>
          <ProjectValidator />
        </Route>
        <Route>
          <ProjectValidator />
        </Route>
      </Switch>
    </Router>
  );
}

export default ProjectRouter;
