
import { find, filter } from 'lodash';
import { is_set } from './Functions';

// Aliases for readability & code
const valid_param = (p) => is_set(p);
const vp = (p) => valid_param(p);

export const win_conditions = {
  '<'  : (a, b) => vp(a) && vp(b) && a < b,
  '>'  : (a, b) => vp(a) && vp(b) && a > b,
  '<=' : (a, b) => vp(a) && vp(b) && a <= b,
  '>=' : (a, b) => vp(a) && vp(b) && a >= b,
  'Set': (a, b) => !!a && !b,
  'Order': (a, b, l) => vp(a) && vp(b) && l.indexOf(a) < l.indexOf(b),
  'Order+Tie': (a, b, l) => vp(a) && vp(b) && l.indexOf(a) <= l.indexOf(b),
  'Has': (a, b) => a.some((_a) => b.indexOf(_a) === -1)
};

const value_from_spec = (spec) => {
  // Specification value not set
  if (!spec) return '';

  switch (spec.input_type) {

    // 'text-input' is currently treated as 'numeric'
    case 'text-input':
      // Convert year/month/week values to days for very general cases
      let value = parseFloat(spec.value);
      if (isNaN(value)) return null;
      // Remove commas from value, in case it was entered with one
      value = parseFloat(String(spec.value).replace(/,/g, ''))
      return value * (spec.weight || 1);

    case 'currency-display': return spec.currency_value || spec.value;
    case 'numeric':
      if (spec.original_specification_numeric_value !== undefined)
        return spec.original_specification_numeric_value || spec.value;
      return spec.specification_numeric_value || spec.value;

    case 'dropdown':
      if (spec.choice === 'Yes') return true;
      if (spec.choice === 'No') return false;
      return spec.choice || spec.value;
    // case 'checkboxes':
    default: return spec.choices;
  }
}

const numeric_operators = ['<','>','<=','>='];

const valid_operands_by_input_type = {
  'text-input': numeric_operators,
  'numeric': numeric_operators,
  'currency-display': numeric_operators,
  'dropdown': ['Set', 'Order'],
  'checkboxes': ['Has']
};

// Expands the operands in valid_operands_by_input_type mapping as follows
// { 'text-input': { '<': true, '>': true, ... } }
const valid_operands_by_input_type_map = Object.keys(valid_operands_by_input_type).reduce((types_map, input_type) => {
  return {
    ...types_map,
    // Map operands
    [input_type]: valid_operands_by_input_type[input_type]
      .reduce((operands_map, operand) => { return { ...operands_map, [operand]: true } }, {})
  }
}, {});

const always_highlight = 'Always';
const target_win_against_one = 'Any Other Product';
const target_win_against_all = 'All Other Products';
const valid_targets = [target_win_against_one, target_win_against_all];
// Map targets
const valid_targets_map = valid_targets.reduce((targets_map, target) => {
  return { ...targets_map, [target]: true }
}, {});

const perform_compare = (operator, is_single_target, primary_value, secondary_values, treat_empty_as_inferior, order = [], tie_order) => {

  if (order?.length && tie_order && win_conditions[operator + '+Tie']) {
    operator += '+Tie';
  }

  // Invalid operator
  if (!win_conditions[operator])
    return false;
  let at_least_one_match = false;

  let all_secondary_values_empty = true;
  let at_least_one_empty = false;
  for (let i = 0; i < secondary_values.length; ++i) {
    let secondary_value = secondary_values[i];

    if (secondary_value === '') {
      at_least_one_empty = true;
      continue;
    }


    all_secondary_values_empty = false;

    // Perform comparison
    let primary_wins = win_conditions[operator](primary_value, secondary_value, order);
    if (primary_wins)
      at_least_one_match = true;

    // Single win needed
    if (is_single_target && primary_wins)
      return true;

    // All wins needed
    if (!is_single_target && !primary_wins)
      return false;
  }

  // primary-vs-primaries case where only one column has value
  if (all_secondary_values_empty && treat_empty_as_inferior) {
    return true;
  }

  // Matching "Any Other Product" against at least one empty value
  if (is_single_target && treat_empty_as_inferior && at_least_one_empty)
    return true;

  // All matched at this point
  if (at_least_one_match && !is_single_target)
    return true;

  return false;
};

const compare_spec = (operator, is_single_target, spec, secondary_specs, treat_empty_as_inferior, order, tie_order) => {
  // Invalid operator for input type
  if (!valid_operands_by_input_type[spec.input_type] ||
      !valid_operands_by_input_type_map[spec.input_type][operator]) {
    return false;
  }

  // Collect primary & secondary spec values
  let primary_value = value_from_spec(spec);
  let secondary_values = secondary_specs.map((secondary_spec) => value_from_spec(secondary_spec));

  return perform_compare(operator, is_single_target, primary_value, secondary_values, treat_empty_as_inferior, order, tie_order);
};

/**
 * Compares a primary product's spec against the same spec of secondary products.
 * Returns true if primary_spec "wins" against secondary_specs given a condition
 */
export const compare_grouping = (win_logic_config, grouping_specs_primary, grouping_specs_secondaries) => {
  // Iterate through primary product specifications
  for (let i = 0; i < grouping_specs_primary.length; ++i) {
    const primary_spec = grouping_specs_primary[i];
    const primary_spec_id = primary_spec.specification?.id || primary_spec.specification;

    // Input type not configured
    if (!valid_operands_by_input_type_map[primary_spec.input_type])
      continue;

    let spec_win_logic = find(win_logic_config, (conf) => conf.specification === primary_spec_id || conf.specification?.id === primary_spec_id);

    // No logic for spec
    if (!spec_win_logic)
      continue;

    // 

    // Always highlight!
    if (spec_win_logic.when_to_highlight === always_highlight)
      return true;

    // Target(s) for condition & condition operator
    let target = valid_targets_map[spec_win_logic.compare_against] ?
      spec_win_logic.compare_against : target_win_against_one;
    let is_single_target = target === target_win_against_one;
    let operator = spec_win_logic.condition_display;

    // Secondary specifications with same ID as current primary (spec)
    let secondary_specs = grouping_specs_secondaries.map((grouping_specs_secondary) =>{
      let facet_spec = find(grouping_specs_secondary, (spec) => (spec.specification?.id === primary_spec_id || spec.specification === primary_spec_id) && spec.facet === primary_spec.facet);
      if (primary_spec.facet && facet_spec)
        return facet_spec;
      return find(grouping_specs_secondary, (spec) => (spec.specification?.id === primary_spec_id || spec.specification === primary_spec_id));
    });

    if (!primary_spec)
      return false;

    if (secondary_specs.length === 0)
      return true;

    const treat_empty_as_inferior = spec_win_logic.treat_empty_values_as === 'inferior';
    const locale = Object.keys(spec_win_logic.order || {})[0];
    const order = spec_win_logic.order?.[locale] || [];
    const tie_order = spec_win_logic.treat_matching_values_as_win;

    // Compare primary spec against secondaries using the operator
    let spec_matched = compare_spec(operator, is_single_target, primary_spec, secondary_specs, treat_empty_as_inferior, order, tie_order);

    // Any valid match should highlight the entire spec grouping
    if (spec_matched)
      return true;
  }

  return false;
};

/**
 * Helper to map spec data for compare_grouping
 */
export function mapProductSpecsForComparison(product_specs, feature) {
  return filter(product_specs, { specification: { id: feature.id } })
    // Hotfix for BIJ product data
    .concat(filter(product_specs, { specification: feature.id }))
    
    .map((found_spec) => {
      let value = '';
      switch (feature.input_type) {
        case 'numeric': value = found_spec.specification_numeric_value; break;
        case 'currency-display': value = found_spec.currency_value; break;
        
        case 'dropdown': value = found_spec.choice; break;
        case 'checkboxes': value = found_spec.choices.join(found_spec.specification?.value_separator || ''); break;

        default:
          value = found_spec.display_value;
      }
      return {
        ...found_spec,
        specification: found_spec.specification,
        input_type: feature.input_type,
        value: value
      }
    })
}


/**
 * Returns true when **any** secondary price is higher than the primary
 */
export const compare_price = (primary_price, secondary_prices, locale_options, treat_empty_as_inferior) => {
  let operator = locale_options?.operator;
  let target = locale_options?.target;

  // Operator/target not setup or invalid
  if (!operator || !win_conditions[operator] ||
      !target   || !valid_targets_map[target])
    return false;

  let is_single_target = target === target_win_against_one;

  return perform_compare(operator, is_single_target, primary_price, secondary_prices, treat_empty_as_inferior);
};

/**
 * Helper for compare_price for destructuring locale object
 */
export const compare_price_withLocaleObject = (primary_price, secondary_prices, locale, treat_empty_as_inferior) => {
  let locale_options = {
    operator: locale?.currency?.condition,
    target: locale?.currency?.compare_against
  };
  return compare_price(primary_price, secondary_prices, locale_options, treat_empty_as_inferior);
}