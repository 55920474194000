const scripts = `
try {
  // Expected structure:
  // {
  //   dealers: [{
  //     // Phone
  //     p: '+5555555555',
  //     // Email
  //     e: 'example@example.com'
  //   }, ...]
  // }
  var form_rq_vars = {{script_vars}};

  function form_rq_emailDealer(module_name_pfx) {
    const { dealers } = form_rq_vars[module_name_pfx];
    const idx = form_rq_getSelectedDealerIdx(module_name_pfx);
    const dealer = dealers[idx];

    if (!dealer) return;

    const contact_name_field = getField(buildFieldName([module_name_pfx, 'form_name']));
    const contact_name = contact_name_field.value;

    const subject = 'ColorWorks Media Inquiry: ' + (contact_name || '');

    const dealer_email = dealer.e;
    const dealer_name = dealer.n;

    const message = 'Dear ' + dealer_name + ',\\n'
     + '\\tI am interested in purchasing media for my Epson ColorWorks label printer. Attached is a form with further information.\\n\\n'
     + 'Thank you,\\n'
     + contact_name;

    mailDoc({
      bUI: true,
      cTo: dealer_email,
      cSubject: subject,
      cMsg: message
    });
  }

  function form_rq_getSelectedDealerIdx(module_name_pfx) {
    const field = form_rq_getDealerSelectionField(module_name_pfx);
    const value = String(field.value).trim();
    const match = value.substring('Choice'.length);
    
    // Leaving below approach commented for posterity.
    // For some reason, this approach would work in the debugger
    // but not within the automated execution: match is always
    // null.
    // const match = value.match(/(\d+$)/);
    // const matched_idx = (match && match[1]) || 0;

    const matched_idx = parseInt(match) || 0;
    const idx = matched_idx - 1;
    return idx;
  }

  function form_rq_reloadPseudoRadio(module_name_pfx, field, item_idx) {
    const radio_id = 'dealer-' + item_idx;
    const layer_name = buildFieldName([module_name_pfx, radio_id, 'icon']);

    const enable_layer = field.value === ('Choice' + (item_idx + 1));
    toggleLayer(layer_name, enable_layer);
  }

  function form_rq_getDealerSelectionField(module_name_pfx) {
    const field_name = buildFieldName([module_name_pfx, 'dealer_selection']);
    return getField(field_name);
  }

  function form_rq_reloadContactDisplay(module_name_pfx, field, item_idx) {
    const layer_name = buildFieldName([module_name_pfx, 'tooltips', item_idx]);

    const enable_layer = field.value === ('Choice' + (item_idx + 1));
    toggleLayer(layer_name, enable_layer);
  }

  function form_rq_reloadContactDisplayButtons(module_name_pfx, field) {
    const button_names = ['email', 'email-button'/*, 'phone'*/];
    const dealer_idx = form_rq_getSelectedDealerIdx(module_name_pfx);
    const enable_buttons = dealer_idx >= 0;
    button_names.forEach((button_name) => {
      const field_name = buildFieldName([module_name_pfx, button_name]);
      toggleFieldVisibility(field_name, enable_buttons);
    });
  }

  function form_rq_reloadDealerSection(module_name_pfx) {
    const { dealers } = form_rq_vars[module_name_pfx];
    const items_count = dealers.length;
    var field = form_rq_getDealerSelectionField(module_name_pfx);

    for (var i = 0; i < items_count; ++i) {
      form_rq_reloadPseudoRadio(module_name_pfx, field, i);
      form_rq_reloadContactDisplay(module_name_pfx, field, i);
    }

    form_rq_reloadContactDisplayButtons(module_name_pfx, field);

    // Show the tooltip background layer
    const value_is_unset = !field.value;
    toggleLayer(buildFieldName([module_name_pfx, 'tooltips_misc']), !value_is_unset);
  }

  function form_rq_dealerSelected(module_name_pfx, dealer_idx) {
    var field = form_rq_getDealerSelectionField(module_name_pfx);
    if (field) {
      field.value = 'Choice' + (dealer_idx + 1);
      form_rq_reloadDealerSection(module_name_pfx);
    }
  }

  function form_rq_init() {
    for (module_name_pfx in form_rq_vars) {
      form_rq_reloadDealerSection(module_name_pfx);
    }
  }

  form_rq_init();
} catch (e) {
  app.alert('Form [Request Quote] module error: ' + e);
}
`;

export default scripts;
