import {
  getElementPosition,
  getRelativeElementPosition,
  getRgbColorAsHex,
  getBorderProps,
  pxToPt,
  processNumber,
  createText,
  createTextElementsFromContainer,
  getRoundedRadius,
  createImage,
  createCta,
  createCarouselTab,
  createInactiveTabs,
  createCarouselArrows
} from "../util";
import scripts from "./scripts";

function createCarouselSlide(slide, configs, slide_idx) {
  let elements = [];
  const {
    page: { idx: page_idx, height },
    element,
    tab_element,
    module_base_name
  } = configs;
  const content_prefix = `slide-${slide_idx}-`;

  const tab_options = {
    action: {
      mouseClick: `carousel_goToSlide("${module_base_name}", ${slide_idx})`
    }
  };

  const tab_active = createCarouselTab(
    tab_element,
    configs,
    slide_idx,
    true,
    tab_options
  );
  tab_active.alwaysVisible = slide_idx == 0;
  elements.push(tab_active);

  // Image
  const figure = configs.element.querySelector("figure");
  const image = figure?.querySelector?.("img");
  if (image && image.src) {
    const slide_image = createImage(configs, image, `${content_prefix}image`);
    elements.push(slide_image);
  }
  // // Header
  // const header_element = element.querySelector(".header");
  // if (header_element) {
  //   const header = createText(
  //     header_element,
  //     configs,
  //     `${content_prefix}header`
  //   );
  //   elements.push(header);
  // }

  // // Body
  // const body_element = element.querySelector(".body");
  // if (body_element) {
  //   const body = createText(body_element, configs, `${content_prefix}body`);
  //   elements.push(body);
  // }

  const text_elements = createTextElementsFromContainer(
    configs.element,
    content_prefix,
    configs
  );
  elements = elements.concat(text_elements);

  // CTA 1
  const cta1 = makeCta(element.querySelector(".cta"), configs, "cta-1");
  if (cta1) {
    elements.push(cta1);
  }

  // Image CTA
  const imageCta = makeCta(
    element.querySelector("figure a"),
    configs,
    "image-cta"
  );
  if (imageCta) {
    delete imageCta.text;
    elements.push(imageCta);
  }

  return elements;
}

function makeCta(cta_element, configs, name) {
  if (!cta_element) return;
  let options = {
    action: {}
  };
  const href = cta_element.href.replace(window.location.href, "");
  if (cta_element.href && href !== "#") {
    options.action.mouseClick = `app.launchURL('${cta_element.href}');`;
  }

  return createCta(cta_element, configs, name, {}, options);
}

function createCarouselSlideElements(
  slides,
  configs,
  slide_elements,
  tab_elements
) {
  const mappedSlides = slides.map((slide, slide_idx) => {
    const slide_element = slide_elements[slide_idx];
    if (!slide_element) return null;
    const enhanced_configs = {
      ...configs,
      element: slide_element,
      element_rect: slide_element.getBoundingClientRect(),
      tab_element: tab_elements[slide_idx]
    };

    return {
      name: String(slide_idx),
      // All slides except the first are initially hidden
      hidden: slide_idx > 0,
      elements: createCarouselSlide(slide, enhanced_configs, slide_idx)
    };
  });
  return mappedSlides.filter((e) => e !== null);
}

function loadCarouselConfig(carousel_module, configs) {
  const { page, fonts, containing_element } = configs;
  const { idx: page_idx } = page;
  const name = `carousel_${page_idx}`;
  const enhanced_configs = {
    ...configs,
    module_base_name: name
  };
  // Disregard background image and arrow icons since those are for EvoPDF
  const { html_id, content = {} } = carousel_module;
  const { slides = [], show_arrows } = content;

  const tab_elements = [...containing_element.querySelectorAll(".tab")];

  const arrow_elements = [...containing_element.querySelectorAll(".arrow")];
  const created_arrows = createCarouselArrows(
    arrow_elements,
    enhanced_configs,
    show_arrows
  );

  const inactive_tab_options = {
    action: {
      mouseClick: (name, idx) => `carousel_goToSlide("${name}", ${idx})`
    }
  };
  const created_inactive_tabs = createInactiveTabs(
    tab_elements,
    enhanced_configs,
    inactive_tab_options
  );

  const slide_elements = [...containing_element.querySelectorAll(".slide")];
  const created_slides = createCarouselSlideElements(
    slides,
    enhanced_configs,
    slide_elements,
    tab_elements
  );

  const carousel_elements = [
    // ...created_tabs,
    ...created_inactive_tabs,
    ...created_arrows,
    ...created_slides
  ];

  return {
    config: {
      name: name,
      page: page_idx,
      position: getElementPosition(containing_element),
      // Contain all content of a slide within the same element (and PDF layer)
      elements: carousel_elements
    }
  };
}
export { loadCarouselConfig, scripts };
