import {
  determineSameDimensions,
  expandSettings
} from "../../../ModularFunctions";
import {
  createBG,
  createButton,
  createButton_v2,
  createCta,
  createHorizontalBorder,
  createImage,
  createImageElementsFromContainer,
  // processNumber,
  createText,
  createTextElementsFromContainer,
  // getBorderProps,
  // getRoundedRadius,
  getBgProps,
  getRelativeElementPosition,
  // getRelativeElementPosition,
  getRgbColorAsHex,
  pxToPt
} from "../util";
import scripts from "./scripts";
const PRIMARY_FILTER = "printer-model";
const MAX_TABLE_ELEMENTS = 14;
/**
 * Returns a condensed version of given json_logic for the PDF.
 * e.g. [{ 0: "> 0", 1: "= 0", 2: "= 0", 3: "= 0" }, ...]
 */
export const condenseLogic = (settings = [], name_indices_map = {}) => {
  return settings.map(({ formula, image }) => ({
    ...condenseLogicItem(formula, name_indices_map)
  }));
};
/**
 * Returns a condensed version of given json_logic item for the PDF.
 * e.g. { 0: "> 0", 1: "= 0", 2: "= 0", 3: "= 0" }
 */
const condenseLogicItem = (json_logic = {}, name_indices_map = {}) => {
  let vars = {};
  for (var k in json_logic) {
    const value = json_logic[k];
    // Variable name
    if (k === "==") {
      vars[name_indices_map[value[0].var]] = `= ${value[1]}`;
    } else if (k === ">") {
      vars[name_indices_map[value[0].var]] = `> ${value[1]}`;
    } else if (k === "!") {
      vars[name_indices_map[value[0].var]] = "= 0";
    }
    // None of the elements match
    // Format example, where no "additional-options" value is > 0:
    //   { "none": [
    //     { "var": "additional-options" },
    //     { ">": [{ "var": "" }, 0 ] }
    //   ]}
    else if (k === "none") {
      vars[name_indices_map[value[0].var]] = "= 0";
    }
    // Element exists within given list
    // Format example, where "10" must be in the "additional-options" array:
    //   { "in": [10, {"var": "additional-options" } ] }
    else if (k === "in") {
      vars[name_indices_map[value[1].var]] = `= ${value[0]}`;
      // const exampleNewLogicFormula = {
      //   newLogic: [
      //     { var: product_ids },
      //     [
      //       ["any", [1, 2, 3]],
      //       ["includes", [6, 13]],
      //       [("excludes", [8, 9])],
      //       [("disabled", [10, 15])]
      //       [("deselected", [10, 15])]
      //     ]
      //   ]
      // };
    } else if (k === "newLogic") {
      vars[k] = true;
      for (let i = 0; i < value[1].length; i++) {
        const v = value[1][i];
        // all formulas should have 1 disabled clause
        if (v[0] === "disabled") vars[v[0]] = v[1];
        if (v[0] === "deselected") vars[v[0]] = v[1];
        //add index to operations that could show up multiple times
        else vars[`${v[0]}_${i}`] = v[1];
      }
    }
    // Nested logic
    else if (Array.isArray(value) || (!!value && typeof value === "object")) {
      Object.assign(vars, condenseLogicItem(value, name_indices_map));
    }
  }
  return vars;
};
const getContentElements = (mod, configs, project) => {
  const { page, fonts, containing_element, localizeNumber } = configs;
  const { idx: page_idx } = page;
  const { html_id, content = {} } = mod;
  const {
    background_color,
    product_filters,
    settings,
    dimension_unit,
    weight_unit
  } = content;
  const content_prefix = "";
  const dummy_container = containing_element.querySelector(
    ".selected-model-container.dummy"
  );
  let elements = [];
  // Export icon
  const parent_sheet = containing_element.closest(".sheet");
  const export_el = parent_sheet.querySelector(".export-sheet-btn");
  let export_button = createButton_v2(export_el, configs, {
    name: "export-btn",
    action: {
      mouseClick: `cfgTool_export('${html_id}');`
    }
  });
  export_button.alwaysVisible = true;
  export_button.page = configs.page.idx;
  elements.push(export_button);
  // "Enabled" BG (solid white)
  const computed_bg_style = window.getComputedStyle(dummy_container);
  const bg_rect = dummy_container.getBoundingClientRect();
  const relative_bg_pos = getRelativeElementPosition(bg_rect, configs.page);
  const bg = {
    name: "bg",
    page: configs.page.idx,
    position: relative_bg_pos,
    ...getBgProps(computed_bg_style, true),
    backgroundColor: "#ffffff"
  };
  elements.push(bg);
  // "Your Configuration<sup>1</sup>"
  const your_config_el = dummy_container.querySelector(".your_config");
  const your_config = createText(
    your_config_el,
    configs,
    `${content_prefix}your_config`
  );
  elements.push(your_config);
  // Icons for width, height, depth & weight
  const attribute_images = createImageElementsFromContainer(
    dummy_container,
    configs,
    "config-tool",
    "attribute-icons"
  );
  elements = elements.concat(attribute_images);
  // "Email This Config" CTA
  const cta = dummy_container.querySelector(".cta.primary");
  const cta_options = {
    ignoreText: true
  };
  const email_cta = createCta(cta, configs, "email-cta", null, cta_options);
  elements.push(email_cta);
  // Images (currently just the icon for "Email This Config")
  const images = createImageElementsFromContainer(
    cta,
    configs,
    "config-tool",
    "email-icon"
  );
  elements = elements.concat(images);
  const email_cta_button = createButton_v2(cta, configs, {
    name: "email-btn",
    action: {
      mouseClick: `cfgTool_emailConfig('${html_id}');`
    }
  });
  elements.push(email_cta_button);
  // Overline
  const overline = dummy_container.querySelector(".overline");
  if (overline) {
    const new_el = createText(overline, configs, `${content_prefix}overline`);
    elements.push(new_el);
  }
  // Title
  const title = dummy_container.querySelector(".title");
  if (title) {
    const products_sorted = product_filters[0]?.products || [];
    products_sorted.forEach((product, product_idx) => {
      const { model, id } = product;
      const layer_name = `title-${id}`;
      const new_el = createText(title, configs, layer_name, { content: model });
      elements.push(new_el);
    });
  }
  const table_headers = [
    ...dummy_container.querySelectorAll("table tbody th span")
  ];
  table_headers.forEach((th, th_idx) => {
    const new_el = createText(th, configs, `${content_prefix}th-${th_idx}`);
    elements.push(new_el);
  });
  const table_values = [
    ...dummy_container.querySelectorAll("table tbody td span")
  ];
  table_values.forEach((td, td_idx) => {
    const new_el = createText(td, configs, `${content_prefix}td-${td_idx}`);
    elements.push(new_el);
  });
  // "Email this Config" and "Part Numbers"
  const text_elements = createTextElementsFromContainer(
    dummy_container,
    "content",
    configs
  );
  elements = elements.concat(text_elements);
  // Table backgrounds
  const table_rows = [...dummy_container.querySelectorAll("table tr")];
  table_rows.forEach((row, row_idx) => {
    let row_elements = [];
    const bg = createBG(row, configs, "bg");
    if (bg) row_elements.push(bg);
    // Acquire top border only for first row
    if (row_idx === 0) {
      const border_top = createHorizontalBorder(
        row,
        configs,
        "borderTop",
        "border-top"
      );
      if (border_top) {
        row_elements.push(border_top);
      }
    }
    const border_bottom = createHorizontalBorder(
      row,
      configs,
      "borderBottom",
      "border-bottom"
    );
    if (border_bottom) row_elements.push(border_bottom);
    if (row_elements.length > 0) {
      let row_info = {
        name: `row-${row_idx}`,
        position: getRelativeElementPosition(row, configs.page),
        // Contain all content of a hotspot within the same element (and PDF layer)
        elements: row_elements
      };
      elements.push(row_info);
    }
  });
  // Config images
  const images_ph = dummy_container.querySelector("figure img");
  const images_ph_parent = images_ph.parentElement;
  const images_ph_parent_rect = images_ph_parent.getBoundingClientRect();
  const relative_images_ph_parent_pos = getRelativeElementPosition(
    images_ph_parent_rect,
    configs.page
  );
  const val_containers = {
    width: dummy_container.querySelector(".width .value"),
    height: dummy_container.querySelector(".height .value"),
    depth: dummy_container.querySelector(".depth .value"),
    weight: dummy_container.querySelector(".weight .value")
  };
  const units = {
    width: dimension_unit,
    height: dimension_unit,
    depth: dimension_unit,
    weight: weight_unit
  };
  const val_keys = Object.keys(val_containers);
  let image_elements = [];
  settings.forEach((setting, idx) => {
    const { image } = setting;
    // if (!image) return;
    const overrides = {
      container: images_ph,
      img_src: image?.data?.full_url
    };
    const img = image ? createImage(configs, image, "image", overrides) : null;
    // Values for width, height, depth & weight
    const text_elements = val_keys.map((key) => {
      let text_obj = createText(val_containers[key], configs, key);
      // e.g. "width_value", "height_value", ...
      const value = localizeNumber(setting[`${key}_value`] || "");
      const unit = units[key] || "";
      const value_with_unit = `${value} ${unit}`.trim();
      text_obj.text.content = value_with_unit;
      return text_obj;
    });
    elements.push({
      name: `image-${idx}`,
      position: relative_images_ph_parent_pos,
      elements: img ? [img, ...text_elements] : [...text_elements]
    });
  });
  return elements;
};
const getVisibilityElements = (mod, configs) => {
  const { containing_element } = configs;
  const elements = [];
  const dropdowns = document.querySelectorAll(
    '[data-field-type="content_dropdown"]'
  );
  [...dropdowns].slice(1).forEach((dropdown) => {
    const dropdown_container = dropdown.parentElement;
    const dropdown_container_rect = dropdown_container.getBoundingClientRect();
    const dropdown_container_position = getRelativeElementPosition(
      dropdown_container_rect,
      configs.page
    );
    const module_styles = window.getComputedStyle(containing_element);
    const dropdown_hiding_color = getRgbColorAsHex(
      module_styles.backgroundColor
    );
    const alpha = 0.2;
    const alpha_hex = getRgbColorAsHex("" + (1 - alpha) * 255).substring(1);
    const dropdown_hiding_color_with_alpha = `${dropdown_hiding_color}${alpha_hex}`;
    const { x, y, width, height } = dropdown_container_position;
    const additional_spacing = 5;
    const dropdown_hiding_layer = {
      name: `${dropdown.getAttribute("name")}_visibility`,
      position: {
        x: x - additional_spacing,
        y: y - additional_spacing,
        height: height + additional_spacing,
        width: width + additional_spacing
      },
      backgroundColor: dropdown_hiding_color_with_alpha
      // backgroundColor: hp_hiding_color
    };
    elements.push(dropdown_hiding_layer);
  });
  // Hide "Export" button (along with icon and divider) with a layer
  const parent_sheet = containing_element.closest(".sheet");
  const export_el = parent_sheet.querySelector(".export-sheet-btn");
  const export_el_rect = export_el.getBoundingClientRect();
  let export_button = createButton(export_el, configs, "export-btn-visibility");
  export_button.alwaysVisible = true;
  export_button.page = configs.page.idx;
  // Layer to hide the export icon + divider (or rather, right before the "Top" icon)
  const export_divider_el = export_el.nextSibling;
  const export_divider_rect = export_divider_el.getBoundingClientRect();
  let export_button_hider = JSON.parse(JSON.stringify(export_button));
  export_button_hider.backgroundColor = "#ffffff";
  // Offset to support covering the rest of the button for longer strings
  const arbitrary_left_offset_percent = 0.1;
  const tentative_new_width = export_divider_rect.x - export_el_rect.x;
  const new_width_offset = tentative_new_width * arbitrary_left_offset_percent;
  const new_width = tentative_new_width + new_width_offset;
  export_button_hider.position.x -= pxToPt(new_width_offset);
  export_button_hider.position.width = pxToPt(new_width);
  elements.push(export_button_hider);
  return elements;
};
const getElements = (mod, configs, project) => {
  const content = getContentElements(mod, configs, project);
  const visibility = getVisibilityElements(mod, configs);
  const all_elements = [...content, ...visibility];
  return [
    {
      name: "content",
      hidden: true,
      elements: content
    },
    {
      name: "helper",
      elements: visibility
    }
  ];
};
function getScriptVariables(mod, configs, project, collections) {
  const isAMA3 = project === "wf-enterprise-am-a3";
  const isAmA4 = project === "wf-enterprise-am-a4";
  const { containing_element } = configs;
  // Disregard background image and hotspot icons since those are for EvoPDF
  const { html_id, content = {} } = mod;
  const {
    background_color,
    product_filters,
    settings,
    multiple_skus_separator,
    multiple_skus_disclaimer
  } = content;
  const products_sorted = product_filters
    // Extract just the products into a single list
    .flatMap(({ products = [] }) => {
      const products_with_relations = products.flatMap((product) => {
        const { related_models = [] } = product;
        return [product, ...related_models];
      });
      return products_with_relations;
    });
  // Also include related models
  const product_names_sorted = products_sorted.map(({ name }) => name);
  // Product ID to product name. This aids the PDF approach in
  // locating the product for a checkbox.
  const product_ids_name_map = products_sorted.reduce(
    (acc, { id, name }) => ({
      ...acc,
      [id]: name
    }),
    {}
  );
  const product_ids_sku_map = products_sorted.reduce(
    (acc, { id, sku }) => ({
      ...acc,
      [id]: sku
    }),
    {}
  );

  // Create a map from product names to IDs for the PDF script since
  // over there the dropdown labels also become the value.
  // This way we have something like { 'Epson WF-C20600': {id: 1, sku: 'C11CH86201', ... }
  const product_names_map = products_sorted
    // Extract name and ID of each product, and put into map
    .reduce(
      (acc, { id, name, sku, model = "", variant = "", shortname = "" }) => {
        // Shrink SKU
        let modifiedSku = sku;
        if (!!multiple_skus_separator) {
          const skus = sku.split(multiple_skus_separator);
          if (skus.length > 1) {
            //if AM project, keep all skus together (will be separated out into "sku" property on pdf)
            if (isAMA3 || isAmA4) {
              modifiedSku = skus;
            } else {
              modifiedSku = skus[0] + (multiple_skus_disclaimer?.symbol || "");
            }
          }
        }

        return {
          ...acc,
          [name]: {
            id,
            skus: Array.isArray(modifiedSku) ? modifiedSku : [modifiedSku],
            model,
            shortname
            // model: `${model} ${variant}`.trim()
          }
        };
      },
      {}
    );
  // need all products since bundled products aren't part of config tool settings
  const all_product_names_by_sku_map = collections.products.reduce(
    (acc, { sku, individual_sku, shortname, model }) => {
      const skuToUse = individual_sku || sku.split(multiple_skus_separator)[0];
      return {
        ...acc,
        [skuToUse]: shortname || model
      };
    }
  );
  // From base product to related model
  const related_models_map = products_sorted.reduce(
    (acc, { id, related_models = [] }) => {
      if (related_models.length == 0) return acc;
      return {
        ...acc,
        [id]: related_models.map(({ id }) => id)
      };
    },
    {}
  );
  // With the above map from product names to IDs, we can relay a sort of "simple"
  // matrix to the Generator for determining what to show and when given selected
  // options
  const dropdown_filters = product_filters.filter((f) => !f.show_as_checkboxes);
  const dropdowns_count = dropdown_filters.length;
  const checkbox_filters = product_filters.filter((f) => f.show_as_checkboxes);
  const checkboxes_count = checkbox_filters.length;
  // Dropdowns info
  const product_id_list_by_dropdown = dropdown_filters.reduce((acc, next) => {
    const { key, products } = next;
    const element = containing_element.querySelector(
      `[data-filter-name=${key}]`
    );
    const element_id = element?.id || "";
    return {
      ...acc,
      [element_id]: products.map(({ id }) => id)
    };
  }, {});
  // Checkboxes info
  const product_id_by_checkbox = checkbox_filters.reduce(
    (acc, { key, products }) => ({
      ...acc,
      ...products.reduce((p_acc, { id }) => {
        const element = containing_element.querySelector(
          `[data-filter-key="${key}-${id}"]`
        );
        const element_id = element?.id || "";
        return {
          ...p_acc,
          [element_id]: id
        };
      }, {})
    }),
    {}
  );
  const filter_indices_map = product_filters.reduce(
    (acc, { key }, idx) => ({
      ...acc,
      [key]: idx
    }),
    {}
  );
  const condensed_logic = condenseLogic(settings, filter_indices_map);
  // Collect only field names which are used within logic
  let filter_name_to_field_name = {};
  let condensed_logic_field_names = [];
  // Dropdowns
  const dropdown_field_names = Object.keys(product_id_list_by_dropdown);
  dropdown_filters.forEach(({ key }, idx) => {
    for (let i = 0; i < condensed_logic.length; ++i) {
      const condensed_logic_item = condensed_logic[i];
      if (condensed_logic_item[key]) {
        const dropdown_name = dropdown_field_names[idx];
        condensed_logic_field_names.push(dropdown_name);
        filter_name_to_field_name[key] = dropdown_name;
        break;
      }
    }
  });
  // Checkboxes
  const checkbox_field_names = Object.keys(product_id_by_checkbox);
  checkbox_filters.forEach(({ key }, idx) => {
    for (let i = 0; i < condensed_logic.length; ++i) {
      const condensed_logic_item = condensed_logic[i];
      if (condensed_logic_item[key]) {
        const target_id_match = condensed_logic_item[key].match(/(\d+)$/);
        const target_id = parseInt(target_id_match?.[0]) || 0;
        if (target_id > 0) {
          const checkbox_name = checkbox_field_names.find(
            (k) => product_id_by_checkbox[k] === target_id
          );
          condensed_logic_field_names.push(checkbox_name);
          filter_name_to_field_name[key] = checkbox_name;
          break;
        }
      }
    }
  });
  // Build "logic" array for deciding which image to show
  // e.g. ['module-10_dropdown-0', 'module-10_checkboxes-0-1', ...]
  const logic_field_names = getLogicFieldNames(
    containing_element,
    mod,
    condensed_logic,
    filter_indices_map,
    dropdown_filters,
    checkbox_filters
  );
  // e.g. { 0: 'module-10_dropdown-0', 1: 'module-10_checkboxes-0-1', ... }
  const logic_fields_map = logic_field_names.reduce(
    (acc, n, idx) => ({
      ...acc,
      [idx]: n
    }),
    {}
  );
  // const logic_cfg = [
  //   // Default...
  //   { 0: "> 0", 1: "= 0", 2: "= 0", 3: "= 0" },
  //   // Default + High Capacity Input...
  //   { 0: "> 0", 1: "= 0", 2: "= 0", 3: "= 10" },
  //   // Staple Finisher
  //   { 0: "> 0", 1: "= 6", 2: "= 0", 3: "= 0" },
  //   // Staple Finisher + High Capacity Input
  //   { 0: "> 0", 1: "= 6", 2: "= 0", 3: "= 10" },
  //   // Staple Finisher + Hole punch
  //   { 0: "> 0", 1: "= 6", 2: "> 0", 3: "= 0" },
  //   // Staple Finisher + Hole punch + High Capacity Input
  //   { 0: "> 0", 1: "= 6", 2: "> 0", 3: "= 10" },
  //   // Booklet Finisher
  //   { 0: "> 0", 1: "= 7", 2: "= 0", 3: "= 0" },
  //   // Booklet Finisher + High Capacity Input
  //   { 0: "> 0", 1: "= 7", 2: "= 0", 3: "= 10" },
  //   // Booklet Finisher + Hole punch
  //   { 0: "> 0", 1: "= 7", 2: "> 0", 3: "= 0" },
  //   // Booklet Finisher + Hole punch + High Capacity Input
  //   { 0: "> 0", 1: "= 7", 2: "> 0", 3: "= 10" }
  // ];

  const product_family_model_variants_map = (
    product_filters[0]?.products || []
  ).reduce(
    (acc, product) => ({
      ...acc,
      [product.id]: product.family_model_variant
    }),
    {}
  );
  const scriptVarsObj = {
    //list of product names sorted
    product_names_sorted,
    //field_name mapped to list of product ids
    dropdown_products: product_id_list_by_dropdown,
    //field name mapped to product ids
    checkbox_products: product_id_by_checkbox,
    //field name mapped to product ids
    all_products: { ...product_id_list_by_dropdown, ...product_id_by_checkbox },
    //product id mapped to its field name
    reverse_products: Object.entries({
      ...product_id_list_by_dropdown,
      ...product_id_by_checkbox
    }).reduce((acc, [k, v]) => {
      if (Array.isArray(v)) {
        for (const vv of v) {
          acc[vv] = k;
        }
      } else acc[v] = k;
      return acc;
    }, {}),
    //product name mapped to its info (id, sku, etc)
    max_products: MAX_TABLE_ELEMENTS,
    product_names_map,
    product_family_model_variants_map,
    //wf pro hc thing
    related_models_map,
    //product id mapped to its name
    product_ids_name_map,
    //product id mapped to its sku
    product_ids_sku_map,
    all_product_names_by_sku_map,
    // We provide a map of field name to truthy value to determine whether
    // a field is used in a given formula.
    // This way, we have something like { 'field-dropdown-1': true, ... }
    // and we therefore do not have to search an array on the PDF.
    logic_field_names: logic_field_names.reduce(
      (acc, n) => ({
        ...acc,
        [n]: true
      }),
      {}
    ),
    //array of relevant field names (newlogic approach)
    logic_field_names_v2: [],
    //condensed version of the settings formulas
    logic_cfg: condensed_logic,
    //n/a
    logic_fields_map,
    //the project ("wf-enterprise")
    project,
    //boolean of if all primary products are the same dimensions
    same_dimensions: determineSameDimensions(settings)
  };
  scriptVarsObj.logic_field_names_v2 = Array.from(
    new Set(
      getNewLogicIds(condensed_logic).map(
        (e) => scriptVarsObj.reverse_products[e]
      )
    )
  );
  return scriptVarsObj;
}
/**
 * Extract the numeric value from condensed logic pertaining to
 * checkboxes.
 *
 * For example, for [{3: '= 10', 4: '= 2'}, {3: '= 0', 4: '= 2'}] it would return [[10, 0], [2,2]].
 *
 * condensed_logic - [{3: '= 10', 4: '= 2'}, {3: '= 0', 4: '= 2'}]
 * checkbox_filters -
 *          [{key: 'additional-options', ...] ... },
 *           {key: 'another-checkbox-list', ...]}, ...]
 * filter_indices_map - { 'additional-options': 3, 'another-checkbox-list': 4, ... }
 */
function getCheckboxLogicValues(
  condensed_logic,
  checkbox_filters,
  filter_indices_map
) {
  const vals = checkbox_filters.map(({ key, products }, checkbox_idx) => {
    const filter_idx = filter_indices_map[key];
    // Identify unique existing values by mapping them as follows
    // { 10: true, 0: true }
    const cb_logic_values_map = condensed_logic.reduce((acc, logic_row) => {
      // Extract string value from condensed logic row, e.g. from { 3: '= 10' }
      const cb_logic = logic_row[filter_idx];
      // Extract the '10' from '= 10'
      const [operator, product_id] = cb_logic?.split(" ") || [];
      // Do not add '0' to the map
      if (product_id === "0") return acc;
      return {
        ...acc,
        [product_id]: true
      };
    }, {});
    // provide single list of found values: [10, 0]
    return Object.keys(cb_logic_values_map);
  });
  return vals;
}
/**
 * Converts checkbox product id lists to product index lists.
 *
 * checkbox_values - value from getCheckboxLogicValues: [[10, 0], [2, 2]]
 * checkbox_filters - checkbox logic filters: [{key: 'additional-options', products: [{id}, ...] ... },
 */
function getIndexedCheckboxLogicValues(checkbox_values, checkbox_filters) {
  return checkbox_values.map((indices, values_idx) => {
    const checkbox_filter = checkbox_filters[values_idx];
    const { products } = checkbox_filter;
    const product_ids = products.map(({ id }) => id);
    return indices.map((cb_idx) => product_ids.indexOf(parseInt(cb_idx)));
  });
}

//gets all products ids used in newlogic condensed logic
function getNewLogicIds(settings) {
  const ids = [];
  for (const s of settings) {
    const { newLogic, ...other } = s || {};
    if (!newLogic) continue;
    for (const [k, v] of Object.entries(other)) {
      ids.push(...v);
    }
  }
  return Array.from(new Set(ids));
}

function getLogicFieldNames(
  containing_element,
  mod,
  condensed_logic,
  filter_indices_map,
  dropdown_filters,
  checkbox_filters
) {
  const { html_id } = mod;
  // Map from index to filter name...
  const kv_pairs = Object.entries(filter_indices_map);
  const filter_idx_names_map = kv_pairs.reduce((acc, [k, v]) => ({
    ...acc,
    [v]: k
  }));
  // const used_variable_names = settings.reduce(
  //   (acc, { formula }) => ({
  //     ...acc,
  //     ...extractVariablesFromLogic(formula)
  //   }),
  //   {}
  // );
  const filter_elements = [
    ...containing_element.querySelectorAll("[data-filter-name]")
  ];
  // Get possible values for additional-options
  const checkbox_values = getCheckboxLogicValues(
    condensed_logic,
    checkbox_filters,
    filter_indices_map
  );
  const checkbox_ids = getIndexedCheckboxLogicValues(
    checkbox_values,
    checkbox_filters
  );
  return [
    // "module-10_content_dropdown-0",
    // "module-10_content_dropdown-1",
    // "module-10_content_dropdown-2",
    ...dropdown_filters.map((_, idx) => `${html_id}_content_dropdown-${idx}`),
    // "module-10_content_checkboxes-0-1"
    ...checkbox_ids.flatMap((indices, filter_idx) =>
      indices.map(
        (cb_idx) => `${html_id}_content_checkboxes-${filter_idx}-${cb_idx}`
      )
    )
  ];
}
function loadConfigToolConfig(mod, configs, project, collections) {
  const { settings } = mod.content;
  mod.content.settings = expandSettings(settings);
  const { page, containing_element } = configs;
  const { html_id } = mod;
  const { idx: page_idx } = page;
  const elements = getElements(mod, configs, project);
  const script_variables = getScriptVariables(
    mod,
    configs,
    project,
    collections
  );
  return {
    config: {
      name: html_id,
      page: page_idx,
      position: getRelativeElementPosition(containing_element, configs.page),
      // Contain all content of a hotspot within the same element (and PDF layer)
      elements: elements
    },
    prefix: html_id,
    script_variables
  };
}
export { loadConfigToolConfig, scripts };
