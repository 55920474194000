import {
  index_of_sheet,
  localizeNumber,
  findComparisonFeaturingProduct
} from "../Functions";
import jsonLogic from "../CustomJSONLogic";

import { find, filter, findIndex } from "lodash";
import { usesOldAttributesKey } from "../ScannersBijFunctions";

export function getRecommendedModelsPagesStr() {
  var pagesMap = {};
  var recModelLinks = document.querySelectorAll(".visibilityItem");
  // extract sheet index for recommended model links
  for (var i = 0; i < recModelLinks.length; ++i) {
    var parentSheet = recModelLinks[i].closest(".sheet");
    var parentSheetIdx = index_of_sheet(`#${parentSheet.id}`);
    pagesMap[parentSheetIdx] = true;
  }
  var pageIndices = Object.keys(pagesMap).map((k) => parseInt(k));
  pageIndices = pageIndices.join(",");
  return pageIndices;
}

export function _getProductFilters(selector) {
  return [...document.querySelectorAll(selector)].map(
    (element) => element.name
  );
}

export function getProductFilters() {
  return _getProductFilters(".product-filter");
}

export function getProductOptions(
  visibilityControllers,
  products,
  maps,
  selectedLocale,
  project
) {
  var productOptionConfigurations = {};
  let products_mapped_by_display_index = {};
  let running_idx = 0;
  const comparison_pages = Object.values(
    maps.comparison_pages_map_by_id || maps.comparison_pages
  );
  const productIsFeaturedInExistingComparison = (product) => {
    return (
      maps.comparison_pages_map_by_id?.[
        product.linked_comparison?.id || product.linked_comparison
      ] ||
      maps.comparison_pages?.[
        product.linked_comparison?.id || product.linked_comparison
      ] ||
      findComparisonFeaturingProduct(product, comparison_pages)
    );
  };

  products.forEach((product) => {
    // A magical "if project == p/t" might be a quick fix here for BIJ.
    // Scanners may be separate as that was already in Prod
    if (!product || !productIsFeaturedInExistingComparison(product)) {
      return;
    }
    running_idx += 1;
    products_mapped_by_display_index[product.id] = running_idx;
  });

  // for all field configurations (e.g. Color, Users, Volume)
  visibilityControllers.forEach(function (controller) {
    let field = controller.field;
    let feature = controller.feature;
    if (!field || !feature) return;

    const { name: feature_name, groupings = [] } =
      controller.feature.specification || {};

    // T-Series format || BIJ format
    let input_type = feature.input_type || feature?.specification?.input_type;
    if (!productOptionConfigurations[field.name])
      productOptionConfigurations[field.name] = {};
    // get a list of hidden items for all field
    // options (e.g. Color: yes/no; Users; 1,2-10)
    for (var i = 1; i < field.options.length; ++i) {
      const optionValue = field.options[i].textContent;
      const trimmedOptionValue = String(optionValue).trim();
      // Skip "None"
      if (trimmedOptionValue === "None") continue;
      if (!productOptionConfigurations[field.name][trimmedOptionValue])
        productOptionConfigurations[field.name][trimmedOptionValue] = [];

      products.forEach(function (prod) {
        if (!prod) return;
        // add index of product to hide for this configuration
        // let specs = filter(prod.features, { specification_id: feature.id });
        // Format 1 - T-Series
        let specs = filter(prod.features, { specification_id: feature.id });

        // Format 2 - BIJ
        if (specs.length === 0) {
          const feature_key = usesOldAttributesKey(project);
          specs =
            filter(
              prod?.getLocalized?.(feature_key, selectedLocale?.language),
              {
                specification: { id: feature?.specification?.id }
              }
            ) || prod[feature_key];
        }

        let product_has_feature = false;

        // Highlight product if any of the selected feature's groupings match
        if (groupings.length > 0) {
          const target_grouping = groupings.find(
            ({ id }) => id == field.options[i].value
          );
          // Invalid grouping?
          if (!target_grouping) return false;

          const { logic = {} } = target_grouping;
          const { formula = logic } = logic;
          const variables = {
            product: {
              ...prod,
              features: specs
            }
          };
          try {
            product_has_feature = jsonLogic.apply(formula, variables);
          } catch ({ name, stack, message }) {
            const error_obj = { name, stack, message };
            console.error(`Error applying logic on ${feature_name}`, error_obj);
          }
        } else {
          switch (input_type) {
            case "currency-display":
              product_has_feature = specs.some(
                (product_feature) =>
                  product_feature.currency_display === trimmedOptionValue
              );
              break;
            case "checkboxes":
              product_has_feature = specs.some(
                (product_feature) =>
                  product_feature.choices.indexOf(trimmedOptionValue) > -1
              );
              break;
            case "dropdown":
              product_has_feature = specs.some(
                (product_feature) =>
                  product_feature.choice === trimmedOptionValue
              );
              break;

            case "numeric":
            case "text-input":
              product_has_feature = specs.some(
                (product_feature) =>
                  product_feature.display_value === trimmedOptionValue
              );
              break;
            default:
              break;
          }
        }

        if (!product_has_feature) {
          var idxOfProductToHide = products_mapped_by_display_index[prod.id];
          productOptionConfigurations[field.name][trimmedOptionValue].push(
            idxOfProductToHide
          );
        }
      });
    }
  });

  return productOptionConfigurations;
}

// promotions for PDFs
export function getPdfPromotions(clonedDoc) {
  var allPdfPromoLinks = {};
  var pdfPromotionIndex = 0;
  var totalPages = clonedDoc.getElementsByClassName("sheet").length;

  var pdf_linkCtr = 0;

  // collect pdfs
  var pdfs = [...clonedDoc.getElementsByClassName("pdf-link")];
  pdfs.forEach(function (pdf_link, cta_idx) {
    // keep track of known promotions
    if (allPdfPromoLinks[pdf_link.href] === undefined)
      allPdfPromoLinks[pdf_link.href] = pdfPromotionIndex++;

    var tgtPage = totalPages + allPdfPromoLinks[pdf_link.href];
    pdf_link.setAttribute("data-mapping-enabled", true);
    pdf_link.setAttribute("data-mapping-id", "promoAd_" + pdf_linkCtr++);
    pdf_link.setAttribute("data-target-page-" + tgtPage, true);
    pdf_link.removeAttribute("href");
  });

  return Object.keys(allPdfPromoLinks);
}

export function productLinksByBrand(
  products,
  brands,
  comparison_pages,
  index_offset
) {
  if (products.length === 0) return {};
  comparison_pages = comparison_pages.filter(
    (page) => page.primary_product !== undefined
  );
  var products_by_brand = {};
  // collect brand names
  brands.forEach((brand) => {
    if (!brand.brand) return;
    var products_for_brand = filter(products, {
      brand: { id: brand.id }
    });

    // collect products for brand with valid href
    var brandProducts = [];
    products_for_brand.forEach((product) => {
      if (!product.href) return;

      let page_target = findIndex(
        comparison_pages,
        (page) => `#${page.html_id}` === product.href
      );

      // add product to brand list (it has a valid href)
      brandProducts.push({
        name: product.family_model_variant,
        pageIndex: page_target + index_offset - 1
      });
    });

    // save products list for brand
    products_by_brand[brand.brand] = brandProducts.sort((e1, e2) => {
      const model_match_e1 = e1.name.match(/\d+/);
      const model_match_e2 = e2.name.match(/\d+/);
      e1.model_prefix =
        model_match_e1 && model_match_e1.index
          ? e1.name.slice(0, model_match_e1.index)
          : e1.name;
      e1.model_number = model_match_e1 ? model_match_e1[0] : "";
      e2.model_prefix =
        model_match_e2 && model_match_e2.index
          ? e2.name.slice(0, model_match_e2.index)
          : e2.name;
      e1.model_number = model_match_e1 ? model_match_e1[0] : "";
      return (
        e1.model_prefix.localeCompare(e2.model_prefix) ||
        e1.model_number - e2.model_number
      );
    });
  });

  return products_by_brand;
}

export function mapQuickCompareExportData(collections, data_pivots = {}) {
  let qcData = {
    competitor: collections.global_settings.data_source_disclaimer.symbol,
    productsMap: {}
  };

  let quick_compare_page = collections.quick_compare_page;
  collections.products.forEach((product) => {
    let is_primary_brand =
      product.brand.id === collections.global_settings.primary_brand.id;
    let name = `${product.brand.brand} ${product.model} ${
      product.variant || ""
    }`
      .replace(/ +/g, " ")
      .trim();

    const { selectedLocale, selectedAudience } = data_pivots;
    const qc_pricing =
      quick_compare_page.selected_pricing?.(selectedAudience, selectedLocale) ||
      quick_compare_page.selected_pricing;

    const qc_attributes =
      quick_compare_page.selected_specifications?.(
        selectedAudience,
        selectedLocale
      ) || quick_compare_page.selected_specifications;

    qcData.productsMap[name] = {
      // "ID"
      i: product.id,
      // "Features" - including price
      f: [
        // Load prices
        ...qc_pricing.map(({ id }) => {
          return (
            find(product.getLocalized("prices", selectedLocale.language), {
              type: { id: id }
            })?.currency_display || ""
          );
        }),
        // Load features
        ...qc_attributes.map((spec) => {
          let related_product_features = filter(
            product.getLocalized("attributes", selectedLocale.language),
            {
              specification: { id: spec.id }
            }
          );
          // if (product.id === 16 && spec.id === 27) debugger;
          if (related_product_features.length === 0)
            return spec.default_value || "";
          let separator =
            spec.getLocalized("value_separator", selectedLocale.language) ||
            spec.value_separator ||
            "";
          // Replace "<br>" with "\n"
          separator = separator.replace(/<br[^>]*\/?>/g, "\n");
          return related_product_features
            .map((feature, feature_idx) => {
              let spec_value = "";
              let new_feature_pivoted_value = feature.pivoted_value;
              if (feature.pivoted_field === "specification_numeric_value") {
                const decimal_separator =
                  collections.global_settings.getLocalized(
                    "decimal_separator",
                    selectedLocale.language
                  );
                new_feature_pivoted_value = localizeNumber(
                  feature.pivoted_value,
                  selectedLocale.language,
                  decimal_separator
                );
              }

              if (Array.isArray(feature.pivoted_value)) {
                spec_value = new_feature_pivoted_value.join(separator);
              } else {
                spec_value = `${new_feature_pivoted_value}${
                  spec.getLocalized(
                    "unit_separator",
                    selectedLocale.language
                  ) ||
                  spec.unit_separator ||
                  ""
                }${feature.specification_unit || ""}`;
              }

              if (!!feature.facet && spec_value.indexOf(feature.facet) === -1) {
                switch (spec.facet_placement) {
                  case "hidden":
                    spec_value = spec_value.trim();
                  case "before":
                    spec_value = `${feature.facet}${
                      spec.getLocalized(
                        "facet_separator",
                        selectedLocale.language
                      ) || spec.facet_separator
                    }${spec_value}`.trim();
                  default:
                    spec_value = `${spec_value}${
                      spec.getLocalized(
                        "facet_separator",
                        selectedLocale.language
                      ) || spec.facet_separator
                    }${feature.facet}`.trim();
                }
              }
              return spec_value;
            })
            .join(separator);
        }),
        // Load Inks
        (product.ink_refs || []).map((ink) => ink.shortname).join(", ")
      ]
    };

    if (!is_primary_brand) qcData.productsMap[name].s = true;
  });
  return qcData;
}
