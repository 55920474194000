import React from "react";
import { filter, uniq, difference } from "lodash";
import ProductCompareData from "../projects/scanners-v2/data/pages/ProductCompare";
import { isIdBundle } from "./Functions";
import { collectTableData, formatTableData } from "./Tables";

export function usesOldAttributesKey(project_type) {
  return ["latin-bij", "bij"].indexOf(project_type) > -1
    ? "features_and_specifications"
    : "attributes";
}

export function localizePromos(index_page, selected_locale) {
  let local_promo_items = [];
  index_page.promos
    .filter((promo) => promo)
    .slice(0, 3)
    .map((promo) => {
      const local_body_disclaimer = promo?.getLocalized(
        "body_disclaimer",
        selected_locale
      );
      const local_image_url = promo?.getLocalized("image", selected_locale);
      local_promo_items.push({
        heading: promo?.getLocalized("heading", selected_locale),
        body: promo?.getLocalized("body", selected_locale),
        body_disclaimer: local_body_disclaimer?.id,
        image_url: local_image_url?.data?.full_url,
        cta_label: promo?.getLocalized("link_text", selected_locale),
        cta_url:
          promo?.getLocalized("href", selected_locale) ||
          promo?.getLocalized("link_url", selected_locale)
      });
    });
  return local_promo_items;
}

export function localizeNavigationItems(
  navigation_items,
  index_page,
  selected_locale
) {
  for (let i = 0; i < navigation_items.length; i++) {
    navigation_items[i].heading = index_page.getLocalized(
      `nav_${i + 1}_heading`,
      selected_locale
    );
    navigation_items[i].subheading = index_page.getLocalized(
      `nav_${i + 1}_body`,
      selected_locale
    );
    const nav_image = index_page.getLocalized(
      `nav_${i + 1}_image`,
      selected_locale
    );
    navigation_items[i].image_url = nav_image.data.url;
    navigation_items[i].cta_label = index_page.getLocalized(
      `nav_${i + 1}_cta_label`,
      selected_locale
    );
    if (i == 1) {
      navigation_items[i].brand_models_info.brand_dropdown_placeholder_text =
        index_page.getLocalized(
          `brand_dropdown_placeholder_text`,
          selected_locale
        );
      navigation_items[i].brand_models_info.model_dropdown_placeholder_text =
        index_page.getLocalized(
          `model_dropdown_placeholder_text`,
          selected_locale
        );
    }
  }
  return navigation_items;
}

export function localizeProductsByType(
  find_by_features,
  selected_locale,
  project_type,
  obj
) {
  let product_types = find_by_features.selected_products.map(
    (product) => product.product_type
  );
  let product_types_uniq = uniq(product_types);
  const local_products = product_types_uniq.map((product_type) => ({
    type: product_type.getLocalized("label", selected_locale),
    products: filter(find_by_features.selected_products, {
      product_type: { name: product_type.name }
    }).map((product) => ({
      // label: `${product.family} ${product.model}`
      product_type: product.product_type,
      family: product.family,
      model_with_variant: `${product.model} ${product.variant || ""}`
        // Replace multiple spaces with single space, and trim excess
        .replace(/ +/g, " ")
        .trim(),

      image_url: product.getLocalized("product_image", selected_locale).data
        .full_url,
      // (product_image_proportion_thumb_key),
      image_alt: product.getLocalized("product_image", selected_locale).title,
      href: product.href || `#${obj.html_id}`,

      // Simplify product feature objects
      features: product
        .getLocalized(usesOldAttributesKey(project_type), selected_locale)
        .reduce((acc, next_feature) => {
          let value = "";
          const input_type = next_feature.specification?.input_type;
          if (!input_type) return acc;
          switch (input_type) {
            case "text-input":
              value = next_feature.specification_value;
              break;
            case "currency-display":
              value = next_feature.currency_value;
              break;
            case "numeric":
              value = next_feature.specification_numeric_value;
              break;
            case "dropdown":
              value = next_feature.choice;
              break;
            case "checkboxes":
              value = next_feature.choices;
              break;
          }

          // Stringify values for comparison
          if (Array.isArray(value)) {
            value = value.map((v) => `${v}`);
          } else {
            value = `${value}`;
          }

          return [
            ...acc,
            ["latin-bij", "bij"].indexOf(project_type) > -1
              ? {
                  feature_id: next_feature.specification?.id,
                  value: value
                }
              : {
                  specification: next_feature.specification,
                  facet: next_feature.facet,
                  feature_id: next_feature.specification?.id,
                  value: value,
                  specification_numeric_value_raw:
                    next_feature.specification_numeric_value_raw,
                  display_value: next_feature.display_value
                }
          ];
        }, [])
    }))
  }));
  return local_products;
}

export function localizeProductFeatures(
  project_type,
  collections,
  maps,
  language,
  attributes,
  prices,
  other_props
) {
  const feature_key = usesOldAttributesKey(project_type);
  let sorted_features = [];
  collections[feature_key].forEach((feature) => {
    feature.specification = maps[feature_key][feature.specification?.id];
    let found_product_features = filter(attributes, {
      specification: { id: feature.id }
    });
    if (found_product_features.length === 0) return;
    if (found_product_features.length === 1) {
      sorted_features.push(found_product_features[0]);
      return;
    }
    const feature_facets = feature.facets.map((facet) => facet.value);
    found_product_features.sort(
      (a, b) =>
        feature_facets.indexOf(a.facet) - feature_facets.indexOf(b.facet)
    );

    // Include attributes, sorted
    found_product_features.forEach((product_feature) => {
      sorted_features.push({
        ...product_feature,
        value: getProductFeatureFieldOrValue(product_feature, "value"),
        // Include property for showing the label for
        // a facet rather than the selected value
        facet_display: feature.facets.find(
          ({ value }) => value === product_feature.facet
        )?.display_value
      });
    });
  });

  return {
    ...other_props,
    language: maps.locales[language.id],
    [feature_key]: sorted_features.map((feature) => {
      // Re-configure "features" to reference "attributes" and "disclaimers"
      feature.specification = maps[feature_key][feature.specification?.id];
      feature.disclaimer = maps.disclaimers[feature.disclaimer?.id];
      feature.pivoted_value =
        getProductFeatureFieldOrValue(feature, "value") || "";
      feature.pivoted_field = getProductFeatureFieldOrValue(feature) || "";

      feature.specification_numeric_value_raw =
        feature.specification_numeric_value;
      // Process numeric_value for when insert_thousands_separator is set to true
      if (feature.specification?.insert_thousands_separator) {
        const numeric_value_str = "" + feature.specification_numeric_value;
        const number_with_separators =
          feature.specification_numeric_value?.toLocaleString?.("en-US");

        // Replace with formatted version, while retaining original value separately
        feature.original_specification_numeric_value =
          feature.specification_numeric_value;
        feature.specification_numeric_value = number_with_separators;
      }
      return feature;
    }),
    // Re-configure "prices" field to reference "price_types"
    prices: prices.map(({ amount, type, currency_display }) => ({
      amount,
      type: maps.price_types[type?.id],
      currency_display
    }))
  };
}

function getProductFeatureFieldOrValue(product_feature, info_key) {
  // if info_key is "value" return field value else return the field name
  const want_value = info_key === "value";
  const { specification } = product_feature;
  switch (specification?.input_type) {
    case "text-input":
      return want_value
        ? product_feature.specification_value
        : "specification_value";
    case "currency-display":
      return want_value ? product_feature.currency_value : "currency_value";
    case "numeric":
      return want_value
        ? product_feature.specification_numeric_value
        : "specification_numeric_value";
    case "dropdown":
      return want_value ? product_feature.choice : "choice";
    case "checkboxes":
      return want_value ? product_feature.choices : "choices";
    default:
      return want_value ? product_feature.display_value : "display_value";
  }
}

export function localizeFeatureDropdowns(
  find_by_features,
  selectedLocale,
  project_type
) {
  const feature_key = usesOldAttributesKey(project_type);
  const selected_locale = selectedLocale.language;
  const local_features = find_by_features.selected_features.map(
    (selected_feature) => {
      let feature_values = [];
      const { specification = {} } = selected_feature;
      const { groupings = [] } = specification;

      // Show grouping names
      if (groupings.length > 0) {
        feature_values = groupings.map(({ id, name, label }) => ({
          label: label(selectedLocale.id),
          value: id
        }));
      }
      // Values come from products - scan products list for selected
      // features
      else if (specification?.values_come_from_products) {
        let unique_values = {};

        // Iterate through products
        find_by_features.selected_products.forEach((selected_product) => {
          // Collect relevant product features
          let relevant_product_features = selected_product
            .getLocalized(feature_key, selected_locale)
            .filter((f) => f.specification.id === specification.id);

          relevant_product_features.forEach((feature) => {
            // skip empty values
            if (!feature.display_value) return;

            unique_values[feature.display_value] = true;
          });
        });

        feature_values = Object.keys(unique_values);

        // numeric / text input fields
        switch (specification?.input_type) {
          case "text-input":
          case "numeric":
            // Convert to expected format for dropdowns: {label, value}
            feature_values = feature_values.map((v) => ({
              label: v,
              value: v
            }));
            break;
          default:
        }
      } else {
        feature_values = specification.getLocalized("choices", selectedLocale);
      }

      return {
        feature_id: specification?.id,
        groupings: groupings,
        //if nothing is there for label in locales, use default name
        label:
          selected_feature.label ||
          specification?.getLocalized("label", selected_locale) ||
          specification?.label,
        values: feature_values.filter((e) => e.label != "")
      };
    }
  );
  return local_features;
}

export function includeInDropdown(model, comparison_pages) {
  comparison_pages = comparison_pages.filter(
    (page) => page.primary_product !== undefined
  );
  for (const page of comparison_pages) {
    if (page.secondary_products.includes(model)) return true;
  }
  return false;
}

function addPageToProduct(productMap, page, product, brandId) {
  if (product.brand.id !== brandId) return;
  const { model, variant } = product;
  const name = `${model} ${variant || ""}`;
  productMap.set(name, {
    id: product.id,
    pages: new Set([...(productMap.get(name)?.pages || []), page])
  });
}
// return an array of objects in the form {name: productName, pages: pages where product is either primary or secondary product}
export function pagesForProducts(comparisonPages, brandId) {
  //map product name to pages it appears in
  const productMap = new Map();
  const pageQueue = comparisonPages.map((page) => {
    return { page: page, product: page.primary_product, type: "primary" };
  });
  for (const pqObj of pageQueue) {
    const { page, product, type } = pqObj;
    if (type === "primary") {
      //add secondary products to end of queue so all primaries are added first
      for (const secondary of page.secondary_products) {
        pageQueue.push({ page: page, product: secondary, type: "secondary" });
      }
    }
    addPageToProduct(productMap, page, product, brandId);
  }
  //return map as array of objects
  return Array.from(productMap).map(([key, value]) => ({
    name: key,
    id: value.id,
    pages: [...value.pages]
  }));
}

//adds/removes page to selected pages to export
export function pageToDropdown(dropdownList, page, allOption, options) {
  if (page === allOption) return [];
  const dropdownSet = new Set(dropdownList);
  dropdownSet.has(page) ? dropdownSet.delete(page) : dropdownSet.add(page);
  const oneLessItem = dropdownSet.size === options.length - 1;
  !dropdownSet.has(allOption) && oneLessItem
    ? dropdownSet.add(allOption)
    : dropdownSet.delete(allOption);
  return [...dropdownSet];
}

//return list of page components to export
export function pageComponentsToExport(
  pagesToExport,
  productComparePages,
  Component,
  globalPageProps
) {
  let pageList = [];
  const modifiedGlobalPageProps = {
    ...globalPageProps,
    disableComparisonPageExportAndTop: true
  };
  for (const p of pagesToExport) {
    if (p.pages) pageList = pageList.concat(p.pages);
  }
  const pageSet = new Set(pageList);
  pageList = productComparePages.filter((page) => pageSet.has(page));
  pageList.sort((a, b) => {
    //"comparison-110" to 110
    const aId = parseInt(a.id.split("-")[1]);
    const bId = parseInt(b.id.split("-")[1]);
    return aId - bId;
  });
  return pageList.map((pageData) => (
    <Component data={pageData} {...modifiedGlobalPageProps} />
  ));
}

//returns array of all pages to export in array
export function pagesToExportArray(pagesToExport) {
  return (
    pagesToExport
      .flatMap(({ pages }) => pages)
      // Only get unique pages
      .filter((page, index, allPages) => {
        const idxOfOtherPageWithSameId = allPages.findIndex(
          (p) => p.id === page.id
        );
        return idxOfOtherPageWithSameId === index;
      })
  );
}

//updates value for query string
export function updateSearchParam(
  urlParams,
  setUrlParams,
  paramKey,
  paramValue
) {
  const { history, location } = urlParams;
  const params = new URLSearchParams(history.location.search);
  if (paramValue) {
    let newParamValue = paramValue;
    if (paramKey === "lang" && typeof paramValue === "object") {
      newParamValue = paramValue.language;
    }
    if (paramKey === "spe") {
      newParamValue = paramValue.split("sheet-")[1];
    }
    params.set(paramKey, newParamValue);
  } else params.delete(paramKey);
  const search = params.toString();
  const { pathname, hash: oldHash } = location;
  let hash = oldHash;
  if (paramKey === "spe") {
    hash = paramValue ? `#${paramValue}` : "";
  }
  history.replace({ hash, pathname, search });
  setUrlParams({ history, location: history.location });
}

export function getQclProductIds(ids) {
  const bundleIdIsProductId = !isIdBundle(ids[0]);
  if (bundleIdIsProductId) return ids.slice(0, 4);
  return ids.slice(1);
}

export function gatherQclSpeData(
  ids,
  projectData,
  selectedLocale,
  selectedAudience
) {
  const compareData = {};
  const [productId0, productId1, productId2, productId3] =
    getQclProductIds(ids);
  var secondary_prod_ids = difference(
    [productId1, productId2, productId3],
    [productId0]
  );
  const product_ids = [productId0, ...secondary_prod_ids];
  const products = product_ids.map(
    (pid) => projectData.collections_maps.products[pid]
  );
  const primary_product = products[0];
  const secondary_products = products.slice(1);
  compareData.primary_product = primary_product;
  compareData.promos = [];
  compareData.secondary_products = secondary_products.filter((p) => !!p);
  compareData.html_id = `comparison-1`;
  compareData.title_formatting = "primary-model";
  compareData.template = "primary-vs-competitors";
  compareData.pricing = {};
  compareData.selected_features =
    projectData.collections.quick_compare_page.enabled_attributes(
      selectedAudience,
      selectedLocale?.language
    );

  compareData.price_types =
    projectData.collections.quick_compare_page.enabled_price_types(
      selectedAudience,
      selectedLocale?.language
    );
  compareData.show_tco_graph = {};
  compareData.gaptco_savings = {};
  let table_config = {
    primary_product: compareData.primary_product,
    secondary_products: compareData.secondary_products,

    attributes: compareData.selected_features,
    price_types: compareData.price_types
  };

  var tableData = collectTableData(
    table_config,
    selectedLocale,
    projectData.collections
  );
  formatTableData(tableData);
  compareData.table = tableData;
  return new ProductCompareData(
    compareData,
    projectData.collections,
    projectData.collections_maps
  );
}
