import { find, filter, pick, uniq } from "lodash";

import {
  getOrDefault,
  getLocalizedOrDefault
} from "../../../../helpers/Functions";
import { preparePromosData } from "../../../../helpers/ThirdLayer";

import DisclaimerProcessor from "../../../../helpers/DisclaimerProcessor";
import {
  collectTableData,
  collectGraphData,
  formatTableData,
  gapTCOSavings
} from "../../../../helpers/Tables";

function getComparisonOverline(display_format, product) {
  switch (display_format) {
    case "brand":
      return product?.brand?.brand || "";
    case "brand-family":
      return `${product?.brand?.brand || ""} ${product.family || ""}`.trim();
    case "family":
      return product.family;

    case "hidden":
    default:
      return "";
  }
}

export default function ProductCompareData(product_compare, collections, maps) {
  // Collect brand, family & model for primary & secondary products
  const { primary_product } = product_compare;
  this.primary_product = primary_product;
  this.template = product_compare.template;
  const global_settings = collections.global_settings;
  const available_features = global_settings.available_features;

  this.product_image_proportion =
    global_settings.product_image_proportion_dashed;
  this.product_image_height_type =
    global_settings.product_image_height_type_dashed;

  var secondary_products = [];

  const primary_brand = global_settings.primary_brand;
  // Remove non-primary products for primary-vs-primaries template
  if (this.template === "primary-vs-primaries") {
    secondary_products = filter(
      product_compare.secondary_products,
      (p) => p.brand?.id === primary_brand.id
    );
  } else {
    secondary_products = product_compare.secondary_products;
  }

  // Limit product count
  this.secondary_products = secondary_products.slice(0, 3);

  this.id = product_compare.html_id;

  let prev_brand = null;
  let prev_family = null;
  let secondary_product_names = secondary_products.map((product) => {
    let brand_string = "";
    if (product.brand && prev_brand !== product.brand.id) {
      prev_brand = product.brand.id;
      brand_string = product.brand.brand;
    }

    let family_string = "";
    if (product.family && prev_family !== product.family) {
      prev_family = product.family;
      family_string = product.family;
    }

    return (
      `${brand_string} ${family_string} ${product.model} ${product.variant}`
        // Remove excess whitespace and trim
        .replace(/ +/g, " ")
        .trim()
    );
  });

  let model_names = secondary_products.map((product) => {
    return product.model;
  });

  const comparison_options =
    global_settings.comparison_page_heading_overline_format;
  const comparison_option = comparison_options[this.template];

  this.overline = getComparisonOverline(comparison_option, primary_product);
  if (this.template !== "primary-vs-primaries") {
    this.subtitle = secondary_product_names.join(", ");
  } else {
    const compared_models = [primary_product.model, ...model_names];
    this.subtitle = `${compared_models.join(" vs. ")}`;
  }

  this.title = (audience_key) => {
    switch (product_compare.title_formatting) {
      case "families":
        const { model, family } = primary_product;
        return uniq([
          family || model,
          ...secondary_products
            .map(function (secondary_product) {
              return secondary_product.family;
            })
            // Remove invalid/empty values
            .filter((v) => !!v)
        ]).join(" + ");

      case "custom":
        const title =
          product_compare.title_override[audience_key] ||
          product_compare.title_override[default_audience];
        return title;

      case "primary-model":
      default:
        return `${primary_product.model} <small>${primary_product.variant}</small>`;
    }
  };

  this.footer_logo = {
    url: global_settings?.logo_url,
    title: global_settings?.logo_title
  };

  this.disclaimer_processor = new DisclaimerProcessor(
    maps.disclaimers,
    "comparison"
  );

  this.top_link = (selected_locale) => {
    return {
      top_link_label: global_settings?.getLocalized(
        "top_link_label",
        selected_locale
      ),
      top_link_icon_url: global_settings?.getLocalized(
        "top_link_icon",
        selected_locale
      )?.data?.full_url,

      export_link_label: global_settings?.getLocalized(
        "export_link_label",
        selected_locale
      ),
      export_link_icon_url: global_settings?.getLocalized(
        "export_link_icon",
        selected_locale
      )?.data?.full_url
    };
  };

  this.gaptco_logo = global_settings?.gaptco_logo;
  this.gaptco_header_text = (selected_locale) =>
    global_settings?.getLocalized("gaptco_header_text", selected_locale);

  this.hero_image_url = (locale) => {
    const pivoted_value = getLocalizedOrDefault(
      primary_product,
      "product_image",
      { locale: locale },
      { locale: default_locale.language }
    );
    return pivoted_value?.data?.url || primary_product?.image_url;
  };

  const currency = (selected_locale) =>
    selected_locale.currency || default_locale?.currency;

  const default_audience = product_compare.default_audience;
  const { default_locale } = global_settings;

  // results cache for computation-intensive functions
  const cache = {
    promos: {},
    pricing: {},
    selected_features: {},
    table: {},
    graph_data: {},
    tco_savings: {},
    gap_tco_savings_content: {}
  };

  this.set_tco_savings = (audience_key, value) => {
    cache.tco_savings[audience_key] = value;
  };
  this.gap_tco_savings = (audience_key) => {
    if (!cache.tco_savings[audience_key]) return "";
    return cache.tco_savings[audience_key];
  };
  this.set_gap_tco_savings_content = (audience_key, value) => {
    cache.gap_tco_savings_content[audience_key] = value;
  };
  this.gap_tco_savings_content = (audience_key) => {
    if (!cache.gap_tco_savings_content[audience_key]) return "";
    return cache.gap_tco_savings_content[audience_key];
  };

  // Description
  this.product_description = (audience, locale) => {
    const audienced_description_override =
      product_compare?.product_description_override?.[audience];
    if (audienced_description_override) return audienced_description_override;
    return (
      primary_product?.getLocalized("description", locale) ||
      primary_product?.description ||
      ""
    );
  };

  this.product_description_disclaimer = (audience, selected_locale) => {
    if (product_compare?.product_description_override?.[audience]) return null;
    return (
      primary_product?.getLocalized(
        "description_disclaimer",
        selected_locale
      ) || primary_product?.description_disclaimer
    );
  };

  this.table = (audience_key, selectedLocale) => {
    // if (cache.table[audience_key]) {
    //   return cache.table[audience_key];
    // }
    // Collect & format table data
    if (product_compare.table) return product_compare.table;
    let table = collectTableData(
      this,
      selectedLocale,
      collections,
      audience_key
    );
    
    // Collect gapTCO Savings data
    if (this.template !== "primary-vs-primaries") {
      gapTCOSavings(
        this,
        product_compare,
        table,
        collections,
        maps,
        audience_key,
        selectedLocale.language
      );
    }
    formatTableData(table, this.disclaimer_processor, selectedLocale);

    // Toggle Glossary header
    table.glossary_enabled = available_features?.glossary_terms_page;

    cache.table[audience_key] = table;

    return cache.table[audience_key];
  };

  this.show_tco_savings = (audience_key) => {
    return this.gaptco_savings(audience_key) !== "hidden";
  };

  this.gaptco_savings = (audience_key) => {
    const inherit_gaptco =
      product_compare?.inherit_default_audience_gaptco?.[audience_key] ===
      "inherit";
    const audienced_gaptco_savings = getOrDefault(
      product_compare?.gaptco_savings,
      audience_key,
      default_audience,
      inherit_gaptco
    );
    return audienced_gaptco_savings;
  };

  this.show_tco_graph = (audience_key) => {
    if (!audience_key) return null;
    const inherit_gaptco =
      product_compare?.inherit_default_audience_gaptco?.[audience_key] ===
      "inherit";
    const audienced_show_tco_graph = getOrDefault(
      product_compare?.show_tco_graph,
      audience_key,
      default_audience,
      inherit_gaptco
    );
    return audienced_show_tco_graph;
  };

  this.gaptco_disclaimer = (audience_key) => {
    if (this.show_tco_graph(audience_key)) {
      let tco_row_header_disclaimer = global_settings?.gaptco_disclaimer;
      return tco_row_header_disclaimer;
    }
    return null;
  };

  this.graph_data = (audience_key) => {
    if (cache.graph_data[audience_key]) return cache.graph_data[audience_key];

    var graph_data = undefined;
    // Collect graph data
    if (this.show_tco_graph(audience_key)) {
      graph_data = collectGraphData(this, audience_key);
    }

    cache.graph_data[audience_key] = graph_data;
    return cache.graph_data[audience_key];
  };

  this.promos_section = (audience_key, selected_locale) => {
    // if (cache.promos[audience_key]) return cache.promos[audience_key];

    const inherit_promos =
      product_compare?.inherit_default_audience_promos?.[audience_key] ===
      "inherit";
    const audienced_promos = getOrDefault(
      product_compare.promos,
      audience_key,
      default_audience,
      inherit_promos
    );
    // localize the promos
    let local_promo_items = [];
    const audienced_promo_slice =
      audienced_promos === undefined ? [] : audienced_promos.slice(0, 3);
    audienced_promo_slice.forEach((promo) => {
      if (!promo) return;
      const local_body_disclaimer = promo?.getLocalized(
        "body_disclaimer",
        selected_locale
      );
      const local_image_url = promo?.getLocalized("image", selected_locale);
      local_promo_items.push({
        heading: promo?.getLocalized("heading", selected_locale),
        body: promo?.getLocalized("body", selected_locale),
        body_disclaimer: local_body_disclaimer?.id,
        image_url: local_image_url?.data?.full_url,
        cta_label: promo?.getLocalized("link_text", selected_locale),
        cta_url: promo?.getLocalized("href", selected_locale)
      });
    });
    const promos = {
      items: local_promo_items
    };
    // cache.promos[audience_key] = promos;

    return promos;
  };

  this.pricing = (audience_key) => {
    if (cache.pricing[audience_key]) return cache.pricing[audience_key];

    const inherit_pricing =
      product_compare?.inherit_default_audience_pricing?.[audience_key] ===
      "inherit";
    const audienced_pricing = getOrDefault(
      product_compare.pricing,
      audience_key,
      default_audience,
      inherit_pricing
    );
    cache.pricing[audience_key] = audienced_pricing;
    return cache.pricing[audience_key];
  };

  this.selected_features = (audience_key) => {
    if (cache.selected_features[audience_key])
      return cache.selected_features[audience_key];

    const inherit_selected_features =
      product_compare?.inherit_default_audience_selected_features?.[
        audience_key
      ] === "inherit";
    const audienced_selected_features = getOrDefault(
      product_compare.selected_features,
      audience_key,
      default_audience,
      inherit_selected_features
    );

    cache.selected_features[audience_key] = audienced_selected_features;
    return cache.selected_features[audience_key];
  };
}
