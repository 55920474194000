import { createContext } from "react";
import { get_db_base } from "../Functions";
import axios from "axios";

const request_global_setting = (base, field) => {
  return axios
    .get(`${base}items/global_settings/1?fields=${field}`)
    .then((result) => ({ [field]: result?.data?.data?.[field] }))
    .catch((err) => ({ [field]: false }));
};

const request_ac_enabled = (base) =>
  request_global_setting(base, "preview_access");

const request_ac_hash = (base) => request_global_setting(base, "access_hash");

const request_mac_hash = (base) => request_global_setting(base, "m_a_c_h");

const request_ac_config = (project) => {
  const base = get_db_base(project);
  // Request "access code_enabled", access and m_a_c hashes
  return (
    Promise.all([
      request_ac_enabled(base),
      request_ac_hash(base)
      // request_mac_hash(base)
    ])
      // Collate into single object
      .then((items) => items.reduce((acc, next) => ({ ...acc, ...next }), {}))
  );
};

const validate_hash = (project, code, hash) => {
  const base = get_db_base(project);
  return axios
    .post(`${base}utils/hash/match`, { string: code, hash })
    .then((result) => result?.data?.data?.valid)
    .catch((err) => false);
};

const get_cookie = (name) => {
  const regexp = new RegExp(`${name}=([^;]*)`);
  const cookie = document.cookie.match(regexp)?.[1] || "";
  // Convert semicolons back to original state
  return cookie.replace("%3B", ";");
};

const set_cookie = (name, value) => {
  // Transform semicolons into a cookie-acceptable format
  value = value.replace(";", "%3B");
  document.cookie = `${name}=${value};path=/`;
};

const get_ac_cookie = () => get_cookie("comparisontool_ac");
const set_ac_cookie = (value) => set_cookie("comparisontool_ac", value);

const get_cte_cookie = () => get_cookie("comparisontool_e");
const set_cte_cookie = (value) => set_cookie("comparisontool_e", value);

const ACContext = createContext(null);

export {
  request_ac_config,
  validate_hash,
  get_ac_cookie,
  set_ac_cookie,
  get_cte_cookie,
  set_cte_cookie,
  ACContext
};
