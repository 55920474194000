import { createMuiTheme } from "@material-ui/core/styles";

let landingMuiTheme = createMuiTheme({
  spacing: 4,
  shadows: ["none"],
  typography: {
    fontFamily: "Helvetica Neue LT W05_47 Lt Cn", // 'Helvetica Neue LT W05_47 Lt Cn', 'Helvetica Neue LT W05_57 Cond', 'Helvetica Neue LT W05_77 Bd Cn',
    htmlFontSize: 16,
    fontSize: 14,
    body1: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "14px"
    },
    body2: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "9px",
      lineHeight: 1.2
    },
    //Document Title
    h1: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "90px",
      lineHeight: "73.8px"
    },
    h2: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "56px",
      lineHeight: 0.96,
      letterSpacing: "normal"
    },
    subtitle1: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "26px",
      lineHeight: "normal"
    },
    h3: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "28px",
      lineHeight: 1.2
    },
    h4: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: 1.25
    },
    h5: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: 1.2
    },
    h6: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: 1.2
    },
    subtitle2: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: 1.25
    },
    button: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: 1.25,

      textTransform: "capitalize"
    },
    caption: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: 1.4,

      textTransform: "initial"
    },
    overline: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "26px",
      lineHeight: "24.96px",
      textTransform: "uppercase"
    },
    symbol: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn"
    },
    //smMobile
    xsH1: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "80px",
      lineHeight: "73.8px"
    },
    xsTableRegular: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontSize: "12px",
      lineHeight: "120%"
    },
    xsTableMedium: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "16px"
    },
    xsTableLarge: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "18.66px",
      lineHeight: "120%"
    },
    //Mobile
    smTableRegular: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontSize: "14px",
      lineHeight: "120%"
    },
    smTableMedium: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "18.66px"
    },
    smTableLarge: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "24.88px",
      lineHeight: "120%"
    },
    //Tablet
    mdCaption: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: 1.4,
      textTransform: "initial"
    },
    mdTableRegular: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontSize: "14px",
      lineHeight: "95%"
    },
    mdTableMedium: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "19.80px",
      lineHeight: "91%"
    },
    mdTableLarge: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "27.99px",
      lineHeight: "120%",
      lineHeight: "90%"
    },
    //Desktop
    lgBody1: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "16px"
    },
    lgSubtitle1: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontStyle: "normal",
      fontSize: "31.99px",
      lineHeight: "normal"
    },
    lgH1: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "90px",
      lineHeight: "73.8px"
    },
    lgOverline: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "31.99px",
      lineHeight: "24.96px",
      textTransform: "uppercase"
    },
    lgCaption: {
      fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: 1.4,
      textTransform: "initial"
    },
    lgH1: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontStyle: "normal",
      fontSize: "110px",
      lineHeight: "90.2px"
    },
    lgTableRegular: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      fontSize: "16px",
      lineHeight: "95%"
    },
    lgTableMedium: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "22.62px",
      lineHeight: "91%"
    },
    lgTableLarge: {
      fontFamily: "Trade Gothic LT W01 Bold",
      fontSize: "31.99px",
      lineHeight: "90%"
    },

    disclaimer: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
      lineHeight: "auto",
      fontSize: "12px"
    }
  },
  palette: {
    primary: {
      light: "#86939A",
      main: "#2E3853"
    },
    secondary: {
      main: "#4BB7D3",
      light: "#B7E2ED"
    },
    disclaimer: {
      light: "#eeeeee",
      dark: "#111111"
    },
    text: {
      primary: "#000000",
      secondary: "#000000de"
    },
    background: {
      default: "#EDEDED"
    },
    gray: {
      main: "#CBCBCB"
    },
    divider: "#CBCBCB",
    biscay: {
      main: "#2e3853"
    },
    gblue: {
      dark: "#2F373B",
      main: "#86939A"
    },
    cyan: {
      light: "#4ab8d366",
      main: "#4BB7D3"
    },
    magenta: {
      main: "#EB165C"
    },
    yellow: {
      main: "#E9CE27"
    },
    dark: {
      main: "#2F373B"
    },
    black: {
      main: "#000000"
    },
    white: {
      main: "#FFFFFF"
    },
    CompareHighlight: "#4BB7D3",
    CompareHighlightSecondary: "#CBCBCB",
    CompareColorCode1: "#4BB7D3",
    CompareColorCode2: "#B7E2ED",
    CompareColorCode3: "#B7E2ED",
    CompareColorCode4: "#B7E2ED",
    CompareColorCode5: "#B7E2ED"
  },
  props: {
    MuiInput: {
      disableUnderline: true,
      fullWidth: true
    },
    MuiNativeSelect: {
      variant: "outlined"
    }
  },
  overrides: {
    MuiNativeSelect: {
      outlined: {
        border: "1px solid #000000",
        backgroundColor: "#FFFFFF",
        borderRadius: "0px",
        padding: "0px 7px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        boxSizing: "border-box",
        height: "29px",
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: 1.4,

        textTransform: "initial"
      }
    },
    MuiDivider: {
      root: {
        marginBlockEnd: "16px",
        backgroundColor: "#edeceb"
      },
      middle: {
        marginRight: "auto",
        marginLeft: "auto",
        width: "70%"
      }
    },
    MuiButton: {
      root: {
        borderRadius: "0px",
        padding: "11px 15px"
      }
    },
    MuiGrid: {
      "spacing-xs-1": {
        padding: "0px"
      }
    }
  }
});

export default landingMuiTheme;
