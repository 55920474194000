import { createMuiTheme } from '@material-ui/core/styles';

let bijMuiTheme = createMuiTheme({
  shadows: ["none"],
  spacing: 4,
  typography: {
    fontFamily: 'Helvetica Neue LT W05_47 Lt Cn', // 'Helvetica Neue LT W05_47 Lt Cn', 'Helvetica Neue LT W05_57 Cond', 'Helvetica Neue LT W05_77 Bd Cn',
    htmlFontSize: 14,
    fontSize: 12,
    //Document Title
    h1: {
      fontFamily: 'Trade Gothic LT W01 Bold',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '56px',
      lineHeight: '96%',
      letterSpacing: '-0.04em',
    },
    h2: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '28px',
      lineHeight: '34px',
      letterSpacing: '0%',
    },
    h3: {
      fontFamily: 'Helvetica Neue LT W05_77 Bd Cn',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '120%',
      letterSpacing: '-0.02em',
    },
    h4: {
      fontFamily: 'Helvetica Neue LT W05_77 Bd Cn',
      fontStyle: 'normal',
      fontSize: '16px',
      letterSpacing: '2%',
      lineHeight: '16px',
    },
    h5: {
      fontSize: '12px',
      fontFamily: 'Helvetica Neue LT W05_77 Bd Cn',
      fontStyle: 'normal',
      lineHeight: '12px',
      letterSpacing: '2%',
    },
    h6: {
      fontSize: '28px',
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontStyle: 'normal',
      lineHeight: 1.2,
    },
    subtitle1: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '116%',
      letterSpacing: '0.05em',
    },
    subtitle2: {
      fontFamily: 'Helvetica Neue LT W05_57 Cond',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '20px',
      lineHeight: '125%',
    },
    body1: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '12px',
      lineHeight: '140%',
      letterSpacing: '0.02em',
    },
    body2: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '8px',
      lineHeight: '120%',
      letterSpacing: '0.05em',
    },
    caption: {
      fontFamily: 'Helvetica Neue LT W05_77 Bd Cn',
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '.02em',
    },
    button: {
      fontFamily: 'Helvetica Neue LT W05_77 Bd Cn',
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: 1.25,
      letterSpacing: '0.03em',
      textTransform: 'capitalize',
    },
    disclaimer: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      lineHeight: '120%',
      fontSize: '7px',
      letterSpacing: '6%',
    },
    overline: {
      fontFamily: 'Trade Gothic LT W01 Bold',
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: 0.96,
      letterSpacing: '0.14em',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    bullets: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '140%',
      letterSpacing: '2%',
      '& li strong': {
        fontFamily: 'Helvetica Neue LT W05_77 Bd Cn',
        fontWeight: 'normal'
      }
    },
    tableMedium: {
      fontFamily: 'Trade Gothic LT W01 Bold',
      fontSize: '16px',
      letterSpacing: '-2%',
    },
    tableSmall: {
      fontFamily: 'Helvetica Neue LT W05_47 Lt Cn',
      fontSize: '10px',
      lineHeight: '120%',
      letterSpacing: '2%',
    },
    tableLarge: {
      fontFamily: 'Trade Gothic LT W01 Bold',
      fontSize: '20px',
      lineHeight: '120%',
      letterSpacing: '-2%',
    }
  },
  palette: {
    primary: {
      light: '#86939A',
      main: '#2E3853',
      dark: '#122448',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#B7E2ED',
      main: '#4BB7D3',
      dark: '#1E4693',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#000000',
      secondary: '#86939A',
    },
    disclaimer: {
      light: '#eeeeee',
      dark: '#111111'
    },
    divider: '#CBCBCB',
    background: {
      paper: '#FFFFFF',
      default: '#EDEDED',
      grey: '#E3E3E3',
    },
    CompareHighlight: '#2D5BAA',
    CompareHighlightSecondary: '#CBCBCB',
    CompareColorCode1: '#2D5BAA',
    CompareColorCode2: '#FFCC66',
    CompareColorCode3: '#CC557C',
    CompareColorCode4: '#785599',
    CompareColorCode5: '#CBCBCB'
  },
  props: {
    MuiInput: {
      disableUnderline: true
    },
    MuiButton: {
      fullWidth: true,
      disableRipple: true,
      disableElevation: true,
      disableFocusRipple: true,
      variant: 'contained',
      component: 'div',
      color: 'primary'
    },
    MuiNativeSelect: {
      variant: 'outlined'
    },

  },
  overrides: {
    MuiNativeSelect: {
      outlined: {
        border: '1px solid #000000',
        borderRadius: '0px',
        padding: '0px 7px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        letterSpacing: 'normal',
        boxSizing: 'border-box',
        height: '29px'
      }
    },
    MuiDivider: {
      root: {
        marginBlockEnd: '16px'
      },
    },
    MuiButton: {
      root: {
        borderRadius: '0px',
        padding: '11px 15px',
      },
    },
    MuiGrid: {
      'spacing-xs-1': {
        padding: '0px'
      }
    }
  },
  dimensions: {
    highlight: {
      height: 6
    }
  }
});

export default bijMuiTheme;
