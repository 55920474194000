const scripts = `
try {
  // Carousel
  var carousel_slide_fields = ['tab-inactive', 'tab-active', 'cta-1', 'cta-2', 'image-cta'];

  function carousel_goToSlide(carousel, slide_id) {
    goToSlide(carousel, slide_id, carousel_slide_fields);
  }

  function carousel_goToPrevSlide(carousel_name) {
    goToPrevSlide(carousel_name, carousel_slide_fields);
  }

  function carousel_goToNextSlide(carousel_name) {
    goToNextSlide(carousel_name, carousel_slide_fields);
  }
} catch (e) {
  app.alert(e);
}
`;

export default scripts;
