import { createMuiTheme } from "@material-ui/core/styles";

const createTheme = (config) => {
  // const {
  //   comparison_page_table_header_colors = [],
  //   comparison_page_heading_color = "",
  //   comparison_page_overline_color = "",
  //   comparison_page_variant_color = "",
  //   comparison_page_subhead_color = "",

  //   quick_compare_page_heading_color = "",
  //   find_by_features_page_heading_color = "",
  //   comparison_page_win_logic_highlight_color = ""
  // } = config;

  // const compareColorCodes = new Array(4)
  //   // Fill dummy array (to be able to iterate through it)
  //   .fill(0)
  //   // Create names for all four valid color key names
  //   .map((_, idx) => `CompareColorCode${idx + 1}`)
  //   // Create an object containing table color codes:
  //   // { CompareColorCode1: '#2D5BAA', CompareColorCode2: '#FFCC66' }
  //   .reduce(
  //     (acc, next, idx) => ({
  //       ...acc,
  //       // Use provided color or known default
  //       [next]: comparison_page_table_header_colors[idx] || ""
  //     }),
  //     {}
  //   );

  // const palette_additions = {
  //   ...compareColorCodes,
  //   productNameColor: comparison_page_heading_color,
  //   productOverlineColor: comparison_page_overline_color,
  //   productVariantColor: comparison_page_variant_color,
  //   productSubheadColor: comparison_page_subhead_color,

  //   quickComparePageHeadingColor: quick_compare_page_heading_color,

  //   findByFeaturesPageHeadingColor: find_by_features_page_heading_color,

  //   CompareHighlight: comparison_page_win_logic_highlight_color
  // };

  const { module_sizes = [], other = {}, project = "" } = config;
  const isLatinESAM = project === "latin-es-wf-enterprise-am";

  const module_sizes_config = module_sizes.reduce((acc, next) => {
    const {
      key,
      width,
      height,
      margin_top,
      margin_bottom,
      margin_left,
      margin_right
    } = next;

    return {
      ...acc,
      [key]: {
        minWidth: width, // - margin_left - margin_right,
        minHeight: height, // - margin_top - margin_bottom,
        paddingTop: `${margin_top}px`,
        paddingBottom: `${margin_bottom}px`,
        paddingLeft: `${margin_left}px`,
        paddingRight: `${margin_right}px`
      }
    };
  }, {});

  const settings_additions = {
    module_sizes_config,
    ...other
  };

  let muiTheme = createMuiTheme({
    shadows: ["none"],
    spacing: 4,
    typography: {
      fontFamily: "Helvetica Neue LT W05_47 Lt Cn", // 'Helvetica Neue LT W05_47 Lt Cn', 'Helvetica Neue LT W05_57 Cond', 'Helvetica Neue LT W05_77 Bd Cn',
      htmlFontSize: 14,
      fontSize: 12,
      //Document Title
      h1: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        // fontWeight: "700",
        fontWeight: "normal",
        fontSize: isLatinESAM ? "45px" : "50px",
        lineHeight: isLatinESAM ? "45px" : "50px"
      },
      h2: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontWeight: "700",
        fontSize: "30px",
        lineHeight: "30px"
      },
      h3: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontWeight: "700",
        fontSize: "20px",
        lineHeight: "18px"
      },
      h4: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "18px",
        letterSpacing: "-0.02em",
        lineHeight: "18px"
      },
      h5: {
        fontSize: "12px",
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: "12px"
      },
      h6: {
        fontSize: "28px",
        fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
        fontWeight: "normal",
        fontStyle: "normal",
        lineHeight: 1.2
      },
      subtitle1: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "125%"
      },
      subtitle2: {
        fontFamily: "Helvetica Neue LT W05_57 Cond",
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "20px",
        lineHeight: "125%"
      },
      body1: {
        fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "11px",
        lineHeight: "11px"
      },
      body2: {
        fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px"
      },
      caption: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "12px",
        letterSpacing: ".02em"
      },
      button: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontStyle: "normal",
        fontWeight: "normal",
        // fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "12px",
        letterSpacing: ".03em",
        textTransform: "none",
        textDecoration: "none"
      },
      disclaimer: {
        fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
        lineHeight: "120%",
        fontSize: "7px",
        letterSpacing: "6%"
      },
      overline: {
        fontFamily: "Trade Gothic LT W01 Bold",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "18px",
        lineHeight: 0.96,
        letterSpacing: "0.14em",
        textTransform: "uppercase",
        whiteSpace: "nowrap"
      },
      bullets: {
        fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "140%",
        letterSpacing: "2%",
        "& li strong": {
          fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
          fontWeight: "normal"
        }
      },
      tableMedium: {
        fontFamily: "Trade Gothic LT W01 Bold",
        fontSize: "16px",
        letterSpacing: "-2%"
      },
      tableSmall: {
        fontFamily: "Helvetica Neue LT W05_47 Lt Cn",
        fontSize: "10px",
        lineHeight: "120%",
        letterSpacing: "2%"
      },
      tableLarge: {
        fontFamily: "Trade Gothic LT W01 Bold",
        fontSize: "20px",
        lineHeight: "120%",
        letterSpacing: "-2%"
      }
    },
    settings: {
      navigation: {
        fontFamily: "Helvetica Neue LT W05_77 Bd Cn",
        fontSize: "20px",
        fontWeight: "normal"
      },

      ...settings_additions
    },
    palette: {
      primary: {
        light: "#86939A",
        main: isLatinESAM ? "#00215E" : "#00195E",
        dark: "#122448",
        contrastText: "#FFFFFF",
        precisionCoreBlue: "#57B0E3"
      },
      secondary: {
        light: "#B7E2ED",
        main: "#86939A",
        dark: "#1E4693",
        contrastText: "#FFFFFF"
      },
      biscay: {
        main: "#2e3853"
      },
      white: {
        main: "#FFFFFF"
      },
      text: {
        primary: "#000000",
        secondary: "#86939A"
      },
      disclaimer: {
        light: "#eeeeee",
        dark: "#111111"
      },
      divider: "#CBCBCB",
      divider2: "#edeceb",

      background: {
        paper: "#FFFFFF",
        default: "#EDEDED",
        grey: "#E3E3E3"
      }
    },
    props: {
      MuiInput: {
        disableUnderline: true
      },
      MuiButton: {
        fullWidth: true,
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
        variant: "contained",
        component: "div",
        color: "primary"
      },
      MuiNativeSelect: {
        variant: "outlined"
      }
    },
    overrides: {
      MuiNativeSelect: {
        outlined: {
          border: "1px solid #000000",
          borderRadius: "0px",
          padding: "0px 7px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          letterSpacing: "normal",
          boxSizing: "border-box",
          height: "29px"
        }
      },
      MuiDivider: {
        root: {
          marginBlockEnd: "16px"
        }
      },
      MuiButton: {
        root: {
          borderRadius: "0px",
          padding: "11px 15px"
        }
      },
      MuiGrid: {
        "spacing-xs-1": {
          padding: "0px"
        }
      }
    },
    dimensions: {
      highlight: {
        height: 6
      }
    }
  });
  return muiTheme;
};

export default createTheme;
